import Sidebar from "../../sidebar";
import Header from "../../header"
import {useState, useEffect} from 'react'
import Timeout from "../../timeout";
import { useHistory } from 'react-router-dom';
import { toast } from "react-toastify";
import apis from "../../../../utils/apis";
import { Link } from "react-router-dom";

toast.configure()

function Point() {
  let amount, point
  const re = /^[0-9\b]+$/;
  const history = useHistory()
  const loginData = JSON.parse(localStorage.getItem("loginData"))
  let accessToken, screen_name, profilePic
  if(loginData){
    accessToken = loginData.accessToken
    screen_name = loginData.screenName
    profilePic = loginData.profileImage
  }

  const [tokenExpire, setTokenExpire] = useState(false)
  const [updateCheck, setUpdateCheck] = useState(false)
  const [initialAmount, setInitialAmount] = useState("")
  const [initialPoint, setInitialPoint] = useState("")
  const [pointData, setPointData] = useState("")

  const fetchPointSetting = () => {
    const config = {
      headers : {
        authorization : `${accessToken}`
      }
    }

    apis.get('/admin/get-point', config)
    .then((response) => {
      if(response.data.success === true){
        setPointData(response.data.data[0])
        setInitialAmount(response.data.data[0].amount)
        setInitialPoint(response.data.data[0].point)
      }
      else{
        toast.error("Could not fetch Point Setting.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
      }
    })
    .catch((error) => {
      if(error.response.data.success ===false && error.response.data.message === 'jwt expired'){
        localStorage.removeItem("loginData")
        setTokenExpire(true)
      }
      else{
        toast.error("Could not fetch Point Setting.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
      }
    })
  }

  const dataChangeCheck = (e) => {
    if((pointData.point !== initialPoint || pointData.amount !== initialAmount) && initialPoint !== "" && initialAmount !== ""){
      setUpdateCheck(false)
    }
    else{
      setUpdateCheck(true)
    }    
  }

  const handleAmountChange = () => {
    if(re.test(amount.value) || amount.value === ""){
      setInitialAmount(amount.value)
    }
    
  }

  const handlePointChange = () => {
    if(re.test(point.value) || point.value === ""){
      setInitialPoint(point.value)
    }
  }

  const handleChangeSave = (e) => {
    e.preventDefault()
    setUpdateCheck(true)
    const config = {
      headers : {
        authorization : `${accessToken}`
      }
    }

    const requestBody = {
      amount : initialAmount,
      point : initialPoint
    }

    apis.post('/admin/add-point', requestBody, config)
    .then((response) => {
      if(response.data.success === true){
        toast.success("Points Conversion Setting Updated", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
        fetchPointSetting()
      }
      else{
        toast.error("Could not Update Point Conversion Setting.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
        fetchPointSetting()
      }
    })
    .catch((error) => {
      if(error.response.data.success ===false && error.response.data.message === 'jwt expired'){
        localStorage.removeItem("loginData")
        setTokenExpire(true)
      }
      else{
        toast.error("Could not Update Point Conversion Setting.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
      }

    })

  }

  useEffect(() => {
    if(!accessToken){
      history.push({
        pathname : "/",
        state : {
          url : '/point'
        }
       })
       toast.error("Please login to continue.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 1})
    }
    else if(accessToken){
      fetchPointSetting()
    }
  }, [])

  useEffect(() => {
    dataChangeCheck()
  }, [initialAmount, initialPoint])



  return (
    tokenExpire ? 
    <Timeout>
    </Timeout>
    :
    <>
      <main className="page_wrapper">
        <section className="dashboard_section d-flex">
          <Sidebar />

          <section className="dashboard_main_section">
            <Header title="Point Management" screen_name = {screen_name} profilePic = {profilePic} accessToken = {accessToken}/>
            <section className="new-question-section dasboard_main_area points-area points-manage-section">
          <div className="dashboard_container_points">
            <div className="points-available">
              <label>Point conversion rate</label>
              <div className="pointHistory">
                  <p>Point Balance : {pointData.balance_point} </p>
                  <Link to="/point/point-history">Point History</Link>
              </div>
            </div>
            <div className="new-question-from-wrapper">
              <form className="new-question-from" action="">
                <div className="row align-items-center">
                  <div className="col-md-3 col-7 columns">
                    <div className="field-wrapper position-relative">
                      <input type="text" value={initialAmount} ref = {(amountinput) => amount = amountinput} onChange = {() => handleAmountChange()}/>
                    </div>
                  </div>
                  <div className="col-md-2 col-3 columns">
                    <span className="currency">Dollars</span>
                  </div>
                  <div className="col-md-1 col-2 columns">
                    <span className="currency">=</span>
                  </div>
                  <div className="col-md-5 col-9 columns">
                    <div className="field-wrapper position-relative">
                      <input type="text" value= {initialPoint} ref = {(pointinput) => point = pointinput} onChange = {() => handlePointChange()}/>
                    </div>
                  </div>
                  <div className="col-md-1 col-3 columns">
                    <span className="currency">Points</span>
                  </div>
                </div>

                <div className="submit-wrapper submit-main row mt-5">
                  <div className="text-left ">
                    <button className="btn btn-outline" onClick={(e) =>{e.preventDefault() 
                      history.goBack()} }>Cancel</button>
                    <button className="btn btn-xl text-center p-3" disabled = {updateCheck} onClick = {(e) => handleChangeSave(e)}>Save</button>
                    {/* <button className="btn btn-xl text-center" disabled = {updateCheck} onClick = {() => handleChangeSave()}>Save</button> */}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
          </section>
        </section>
      </main>
    </>
  );
}
export default Point;
