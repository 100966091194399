import React, { Component } from "react";
import newlogo2 from "../../assets/img/newlogo2.jpg";
import hamburger from "../../assets/img/mob-btn-icon.svg";
import { NavLink } from "react-router-dom";
export default class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      close: true,
    };
  }
  menuToggle = () => {
    this.setState({
      close: !this.state.close,
    });
  };
  render() {
    const { close } = this.state;

    return (
      <div>
        <main className="page_wrapper">
          <section className="dashboard_section d-flex">
            <div className={close ? "side_bar_wrapper" : "side-bar-active"}>
              <div className="logo-main" onClick={() => this.menuToggle()}>
                <span className="close-btn">⤫</span>
                <img className="img-fluid" src={newlogo2} alt="" width={"150px"} height = {"60px"}/>
              </div>
              <nav className="side_navigation">
                <ul className="side_nav">
                  <li class="side_nav_item dasboard_item ">
                    <NavLink to={"/dashboard"} exact activeClassName="selected">
                      Dashboard
                    </NavLink>
                  </li>
                  <li class="side_nav_item user-manage_list_item">
                    <NavLink to={"/user-management"} exact activeClassName="selected">
                      User Management
                    </NavLink>
                  </li>
                  <li class="side_nav_item question_item">
                    <NavLink to={"/question"} exact activeClassName="selected">
                      Question Management
                    </NavLink>
                  </li>
                  <li class="side_nav_item points_item">
                    <NavLink to={"/point"} exact activeClassName="selected">
                      Point Management
                    </NavLink>
                  </li>
                  <li class="side_nav_item faq_item">
                    <NavLink to={"/faq"} exact activeClassName="selected">
                      FAQ Management
                    </NavLink>
                  </li>
                  <li class="side_nav_item account-settings_item">
                    <NavLink to={"/account"} exact activeClassName="selected">
                      Account Settings
                    </NavLink>
                  </li>
                  <li class="side_nav_item pay-manage_item">
                    <NavLink to={"/payment"} exact activeClassName="selected">
                      Payment Management
                    </NavLink>
                  </li>
                  <li class="side_nav_item badge-manage_item">
                    <NavLink to={"/badge"} exact activeClassName="selected">
                      Badge Management
                    </NavLink>
                  </li>
                  <li class="side_nav_item reports_item">
                    <NavLink to={"/report"} exact activeClassName="selected">
                      Reports
                    </NavLink>
                  </li>
                  <li class="side_nav_item legal_setting">
                    <NavLink to={"/legalsetting"} exact activeClassName="selected">
                      Legal settings
                    </NavLink>
                  </li>
                </ul>
              </nav>
            </div>
            <span className="mob-btn" onClick={() => this.menuToggle()}>
              <img className="img-fluid" src={hamburger} alt="img" />
            </span>
          </section>
        </main>
      </div>
    );
  }
}
