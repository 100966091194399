import Modal from "react-bootstrap/Modal";
import block from "../../assets/img/block.png";
import { Link } from "react-router-dom";
function Timeout(){
    return(
        <Modal centered show = {true}>
        <Modal.Body>
            <div className="text-center p-4">
            <img src={block} alt="img" />
            <p className="mt-3">
                <strong>Session Timed Out!<br />Please login again</strong>
            </p>
            <Link to={"/"} className="btn btn-xl w-100 mt-5">OK</Link>
            </div>
        </Modal.Body> 
        </Modal>
    )
}

export default Timeout