import Sidebar from "../../sidebar";
import Header from "../../header";
import Dropdown from "react-bootstrap/Dropdown";
import option from "../../../../assets/img/opt-icon.svg";
import AddIcon from "@mui/icons-material/Add";
import Modal from "react-bootstrap/Modal";
import { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useHistory } from 'react-router-dom';
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Timeout from "../../timeout";
import apis from "../../../../utils/apis";
import FaqView from "./FaqView"
import { toast } from "react-toastify";

toast.configure()

export default function FAQ() {

  let newQuestion, newResponse
  const loginData = JSON.parse(localStorage.getItem("loginData"))
  let accessToken, screen_name, profilePic, adminId
  if(loginData){
    accessToken = loginData.accessToken
    screen_name = loginData.screenName
    profilePic = loginData.profileImage
    adminId = loginData.adminId
  }
  const history = useHistory()
  const emptyLineRegEX = /\S/
  const [tokenExpire, setTokenExpire] = useState(false)
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false)
  const [targetId, setTargetId] = useState(0)
  const [page, setPage] = useState(1)
  const [rows, setRows] = useState(5)
  const [faqList, setFaqList] = useState("")
  const [questionError, setQuestionError] = useState(false)
  const [responseError, setResponseError] = useState(false)
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleShow2 = (faqid) => {
    setShow2(true)
    setTargetId(faqid)
  }
  const handleClose2 = () => setShow2(false)

  const addNewFaq = () => {
    if((newResponse.value === "" || !emptyLineRegEX.test(newResponse.value)) || (newQuestion.value === "" || !emptyLineRegEX.test(newQuestion.value))){

      if(newResponse.value === "" || !emptyLineRegEX.test(newResponse.value)){
        setResponseError(true)
      }
      
      if(newQuestion.value === "" || !emptyLineRegEX.test(newQuestion.value)){
        setQuestionError(true)
      }

    }
    else{
      const config = {
        headers : {
          authorization : `${accessToken}`
        }
      }

      const bodyData = {
        faq_question : newQuestion.value,
        faq_answer : newResponse.value
      }

      setShow(false)
      apis.post('/admin/add-faq', bodyData, config)
      .then((response) => {
        if(response.data.success ===  true){
          toast.success("FAQ Added Successfully", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
          setPage(1)
          fetchFaqList(1)
        }
        else{
          toast.error("Could not add FAQ, Please try again later.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
        }
      })
      .catch((error) => {
        if(error.response.data.success ===false && error.response.data.message === 'jwt expired'){
          localStorage.removeItem("loginData")
          setTokenExpire(true)
        }
        else{
          toast.error("Could not add FAQ, Please try again later.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
        }
      })
      
    }

  }

  const handleDeleteFaq = () => {
    const config = {
      headers : {
        authorization : `${accessToken}`
      }
    }

    setShow2(false)

    apis.delete(`/admin/delete-faq/${targetId}`, config)
    .then((response) => {
      if(response.data.success === true){
        fetchFaqList(page)
        toast.success("Successfully deleted FAQ.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
      }
      else{
        toast.error("Could not delete FAQ. Please try again later.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
      }
    })
    .catch((error) => {
      if(error.response.data.success ===false && error.response.data.message === 'jwt expired'){
        localStorage.removeItem("loginData")
        setTokenExpire(true)
      }
      else{
        toast.error("Could not delete FAQ. Please try again later.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
      }
    })
  }

  const handleEditFaq = (faq_id) => {
    history.push(`/faq/view-faq/${faq_id}`)
  }

  const handleAnswerChange = () => {
    setResponseError(false)
  }

  const handleQuestionChange = () => {
    setQuestionError(false)
  }

  const handlePgIncrease = () => {
    if(page !== faqList.page_count){
      let nextpg = page + 1
      setPage(page + 1)
      fetchFaqList(nextpg)
    }  
  }

  const handlePgDecrease = () => {
    if(page !== 1){
      let nextpg = page - 1
      setPage(page-1)
      fetchFaqList(nextpg)
    }
  }

  const handleItemCountChange = (e) => {
    setRows(e.target.value)
    setPage(1)
  }

  const fetchFaqList = (page) => {
    const config = {
      headers : {
        authorization : `${accessToken}`
      },
      params : {
        page : page,
        size : rows
      }
    }

    apis.get('/admin/get-faq', config)
    .then((response) => {
      if(response.data.success === true){
        setFaqList(response.data)
      }
      else{
        toast.error("Could not get FAQ List, Please try again later.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
      }
    })
    .catch((error)=> {
      if(error.response.data.success ===false && error.response.data.message === 'jwt expired'){
        localStorage.removeItem("loginData")
        setTokenExpire(true)
      }
      else{
        toast.error("Could not get FAQ List, Please try again later.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
      }
    })
  }

  useEffect(() => {
    if(!accessToken){
      history.push({
        pathname : "/",
        state : {
          url : '/faq'
        }
      })
      toast.error("Please login to continue.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 18}) 
    }
    else if(accessToken){
      fetchFaqList(page)
    }
  }, [rows])

  return (
    tokenExpire ?
    <Timeout>
    </Timeout>
    :
    <>
      <main className="page_wrapper">
        <section className="dashboard_section d-flex">
          <Sidebar />

          <section className="dashboard_main_section">
            <Header title="FAQ Management" screen_name = {screen_name} profilePic = {profilePic} accessToken = {accessToken}/>
            <section className="new-question-section questions-main-section dasboard_main_area faq-manage-area">
              <div className="add-new-question text-end">
                <button className="btn btn-xl plus-btn" onClick={handleShow}>
                  <AddIcon /> Add New
                </button>
              </div>
              <div className="faq-manage-section">
                <div className="table-head">
                  <h4>Question</h4>
                </div>
                <div className="tb-body">
                  {
                    faqList && faqList.data.length > 0 ?
                    faqList.data.map((ele) => {
                      return(
                        <div className="tb-row d-flex">
                          <div className="faq-main faq-col">
                            <p>
                              {ele.faq_question}
                            </p>
                          </div>

                          <div className="option-cell position-relative faq-col">
                            <div className="dropdown">
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="default"
                                  id="dropdown-basic"
                                  size="sm"
                                >
                                  <img src={option} alt="" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item onClick={() => handleEditFaq(ele.id)}>
                                    View/Edit
                                  </Dropdown.Item>
                                  <Dropdown.Item onClick={() => handleShow2(ele.id)}>
                                    Delete
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                      )
                    }) :
                    <>No Data To Show.</>
                  }
                </div>
              </div>
              <div className="table-pagination-wrapper">
                <div className="table-pagination-con d-flex align-items-center justify-content-lg-end justify-content-center">
                  <span className="pagination-label">Items per page:</span>
                  <select className="select-page-number" name="" id="" onChange={(e) => handleItemCountChange(e)}>
                    <option value= {5} selected>5</option>
                    <option value= {10}>10</option>
                    <option value= {15}>15</option>
                  </select>
                  <span className="pagination-label page-counter">{faqList.page_count === 0 ? 0 : page} of {faqList.page_count}</span>
                  <span className="pagination-controls">
                    <a className="prev" style={{cursor:'pointer'}} onClick={() => handlePgDecrease()}>
                      ‹
                    </a>
                    <a className="next" style={{cursor:'pointer'}} onClick={() => handlePgIncrease()}>
                      ›
                    </a>
                  </span>
                </div>
              </div>
            </section>
          </section>
          <Modal centered show={show} onHide={handleClose}>
            <Modal.Body>
              <div className="px-4 mb-3 admin">
                <Modal.Header closeButton></Modal.Header>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label className="label-input100 ">Question</Form.Label>
                  <FloatingLabel controlId="floatingTextarea2">
                    <Form.Control
                      as="textarea"
                      placeholder="Leave a Question here"
                      style={{ height: "100px" }}
                      ref = {(questionInput) => newQuestion = questionInput}
                      onChange = {() => handleQuestionChange()}
                    />
                  </FloatingLabel>
                  {!questionError ? <></> : <Form.Label className="error-label">Question can't be empty</Form.Label>}
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label className="label-input100 mt-3 ">
                    Answer
                  </Form.Label>
                  <FloatingLabel controlId="floatingTextarea2">
                    <Form.Control
                      as="textarea"
                      placeholder="Leave a Response here"
                      style={{ height: "100px" }}
                      ref = {(responseInput) => newResponse = responseInput}
                      onChange = {() => handleAnswerChange()}
                    />
                  </FloatingLabel>
                  {!responseError ? <></> : <Form.Label className="error-label">Response can't be empty</Form.Label>}
                </Form.Group>
                <div className="text-left mt-3">
                  <button className="btn btn-outline me-3" onClick={handleClose}>Cancel</button>
                  <button className="btn btn-xl text-center h-53 " onClick={() => addNewFaq()}>Add</button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <Modal centered show={show2} onHide={handleClose2}>
            <Modal.Body>
              <div className="px-4 mb-3 admin">
                <Modal.Header closeButton></Modal.Header>
                <div className="text-center p-5">
                  <p className="delete mb-4">Are you sure you want to delete this FAQ?</p>
                  <div className="text-left mt-3" id="delete-modal">
                    <button className="btn btn-outline me-3" onClick={() => setShow2(false)}>No</button>
                    <button className="btn btn-xl text-center h-53" onClick={() => handleDeleteFaq()}>Delete</button>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </section>
      </main>
    </>
  );
}
