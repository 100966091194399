import React from 'react';
import { useState, useEffect } from 'react';
import apis from './apis';
import profile from '../assets/img/user.png'



const ImageHelper = ({imageURL, width, height, token}) => {
    const [img, setImg] = useState();
  const fetchImage = async () => {
    console.log(imageURL)
    if(imageURL){
    console.log(token)
    apis
    .get(`${imageURL}`, { headers: { 'authorization': token }, responseType: 'arraybuffer' }).then((res) => {

      let data = `data:${res.headers["content-type"]
        };base64,${new Buffer(res.data, "binary").toString("base64")}`;
        setImg(data)
    });
    }
    else{
      setImg(profile)
    }
}
  useEffect(() => {
    fetchImage();
  }, []);

  
  return (
    <img src={img} alt="" width={width} height={height}/>
  )
}

export default ImageHelper