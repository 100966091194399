import Sidebar from "../../sidebar";
import Header from "../../header";
import Form from "react-bootstrap/Form";
import user from "../../../../assets/img/total-user-icon.svg";
import report from "../../../../assets/img/report-graph.svg";
import open from "../../../../assets/img/my-open-icon.svg";
import que from "../../../../assets/img/new-question.svg";

function Graph() {
  return (
    <>
      <main className="page_wrapper">
        <section className="dashboard_section d-flex">
          <Sidebar />

          <section className="dashboard_main_section">
            <Header title="Reports" />
            <section class="dasboard_main_area admin-dashboard reports-dashboard ">
              <div class="report-dashboard-ctrls d-flex justify-content-between align-items-center">
                <div class="radio-btn-col">
                  <div class="points-available card-type-section d-flex flex-wrap">
                    <Form>
                      {[ "radio"].map((type) => (
                        <div key={`default-${type}`} className="mb-3 me-5 mx-3">
                          <Form.Check
                            type={type}
                            id={`default-${type}`}
                            label={`User Report `}
                          />
                        </div>
                      ))}
                    </Form>
                    <Form>
                      {[ "radio"].map((type) => (
                        <div key={``} className="mb-3">
                          <Form.Check
                            type={type}
                            id={`default-${type}`}
                            label={`Question Report `}
                          />
                        </div>
                      ))}
                    </Form>
                  </div>
                </div>
                <div class="btn-col">
                  <input
                    class="btn btn-xl export-btn"
                    type="button"
                    value="Export"
                  />
                </div>
              </div>
              <div class="">
                <div class="dasboard_counter_main reports-main">
                  <div class="row counter-card-row">
                    <div class="col-lg-4  counter-cards">
                      <div class="inner-column">
                        <div class="counter-card-img card-inner-cols">
                        <img src={user} alt="" />
                        </div>
                        <div class="counter-card-content card-inner-cols">
                          <div class="card-head-main d-flex justify-content-between ">
                            <div class="counter-label">
                              <h4>Total Users</h4>
                            </div>
                          </div>
                          <div class="counter-val-main">
                            <p>100</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4  counter-cards">
                      <div class="inner-column">
                        <div class="counter-card-img card-inner-cols">
                        <img src={open} alt="" />
                        </div>
                        <div class="counter-card-content card-inner-cols">
                          <div class="card-head-main d-flex justify-content-between ">
                            <div class="counter-label">
                              <h4>No. of New Users</h4>
                            </div>
                            <div class="counter-filter text-end">
                              <select name="" id="">
                                <option value="">Last 3 days</option>
                              </select>
                            </div>
                          </div>
                          <div class="counter-val-main">
                            <p>100</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4  counter-cards">
                      <div class="inner-column">
                        <div class="counter-card-img card-inner-cols">
                        <img src={que} alt="" />
                        </div>
                        <div class="counter-card-content card-inner-cols">
                          <div class="card-head-main d-flex justify-content-between ">
                            <div class="counter-label">
                              <h4>No. of New Users</h4>
                            </div>
                            <div class="counter-filter text-end">
                              <select name="" id="">
                                <option value="">Last 3 days</option>
                              </select>
                            </div>
                          </div>
                          <div class="counter-val-main">
                            <p>100</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="report-graph-area">
                  <div class="report-graph-container">
                    <img class="img-fluid w-100" src={report} alt=""/>
                  </div>
                </div>
              </div>
            </section>
          </section>
        </section>
      </main>
    </>
  );
}
export default Graph;
