import Sidebar from "../../sidebar";
import Header from "../../header";
import option from "../../../../assets/img/opt-icon.svg";
import filter from "../../../../assets/img/filter.png";
import { useState, useEffect } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Dropdown from "react-bootstrap/Dropdown";
import apis from "../../../../utils/apis"
import { toast } from "react-toastify";
import Timeout from "../../timeout"

toast.configure()

function Question() {
  let searchTerm
  const history = useHistory()
  console.log("HELLOOO", history)
  const location = useLocation()
  const loginData = JSON.parse(localStorage.getItem("loginData"))
  let accessToken, screen_name, profilePic
  if(loginData){
    accessToken = loginData.accessToken
    screen_name = loginData.screenName
    profilePic = loginData.profileImage
  }

  let searchSelected = "", badgeSelected = "", statusSelected = "", initialPage = 1, initialRows = 50, countrySelected = "", stateSelected = "", citySelected = ""
  if(location.state !== null && location.state !== undefined){
    if(location.state.hasOwnProperty("searchKey")){
      searchSelected = location.state.searchKey
    }
    
    if(location.state.hasOwnProperty("badge_id")){
      badgeSelected = location.state.badge_id
    }
    
    if(location.state.hasOwnProperty("status_id")){
      statusSelected = location.state.status_id
    }

    if(location.state.hasOwnProperty("page")){
      initialPage = location.state.page
    }

    if(location.state.hasOwnProperty("rows")){
      initialRows = location.state.rows
    }

    if(location.state.hasOwnProperty("country_id")){
      countrySelected = location.state.country_id
    }

    if(location.state.hasOwnProperty("state_id")){
      stateSelected = location.state.state_id
    }

    if(location.state.hasOwnProperty("city_id")){
      citySelected = location.state.city_id
    }
    
    history.replace({
      pathname : '/question',
      state : null
    })
  }
  const [tokenExpire, setTokenExpire] = useState(false)
  const [rows, setRows] = useState(initialRows)
  const [page, setPage] = useState(initialPage)
  const [questionList, setQuestionList] = useState("")
  const [isFilters, setActive] = useState(false);
  const [reset, setReset] = useState(false)
  const [badgeFilter, setBadgeFilter] = useState("")
  const [countryFilter, setCountryFilter] = useState("")
  const [stateFilter, setStateFilter] = useState("")
  const [cityFilter, setCityFilter] = useState("")
  const [selectedBadge, setSelectedBadge] = useState(badgeSelected)
  const [selectedStatus, setSelectedStatus] = useState(statusSelected)
  const [search, setSearch] = useState(searchSelected)
  const [selectedCountry, setSelectedCountry] = useState(countrySelected)
  const [selectedState, setSelectedState] = useState(stateSelected)
  const [selectedCity, setSelectedCity] = useState(citySelected)
  const filters = () => {
    setActive(!isFilters);
  };

  window.addEventListener('mouseup',function(e){
    if(history.location.pathname === "/question"){
      var container = document.getElementById('question-filter');
      if (!container.contains(e.target) && isFilters === true) {
        setActive(false)
      }
    }
  }); 

  const handleItemCountChange = (e) => {
    setRows(e.target.value)
    setPage(1)
  }

  const handlePgIncrease = () => {
    if(page !== questionList.page_count){
      let nextpg = page + 1
      setPage(page + 1)
      fetchQuestionList(nextpg)
    }  
  }

  const handlePgDecrease = () => {
    if(page !== 1){
      let nextpg = page - 1
      setPage(page-1)
      fetchQuestionList(nextpg)
    }
  }

  const fetchQuestionList = (page) => {
    const config = {
      headers : {
        authorization : `${accessToken}`
      }
    }

    const requestBody = {
      page : page,
      size : rows
    }

    if(selectedBadge !== ""){
      requestBody["badge_id"] = selectedBadge 
    }

    if(selectedStatus !== ""){
      requestBody["question_status"] = selectedStatus 
    }

    if(search !== ""){
      requestBody["question_text"] = search
    }

    if(selectedCountry != ""){
      requestBody["country_id"] = selectedCountry
    }

    if(selectedState != ""){
      requestBody["state_id"] = selectedState
    }

    if(selectedCity != ""){
      requestBody["city_id"] = selectedCity
    }

    apis.post('/admin/question-list', requestBody, config)
    .then((response) => {
      if(response.data.success === true){
        setQuestionList(response.data)
      }
      else{
        toast.error("Could not get Question List. Please try again later", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
      }
    })
    .catch((error) => {
      if(error.response.data.success ===false && error.response.data.message === 'jwt expired'){
        localStorage.removeItem("loginData")
        setTokenExpire(true)
      }
      else{
        toast.error("Could not get Question List. Please try again later", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
      }
    })
  }

  const fetchBadgeFilter = async () => {
    console.log("Filter API Called")
    apis.get('/badge-list')
    .then((response)=> {
      const data = response.data
      if(data.success === true){
        setBadgeFilter(data.badgeList)
      }
      else{
        toast.error("Could not fetch Badge Filters.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
      }
    })
    .catch((error)=> {
      toast.error("Could not fetch Badge Filters.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 2})
    })
  }

  const fetchCountryFilter = () => {
    apis.get('/country')
    .then((response) => {
      if(response.data.success === true){
        setCountryFilter(response.data.countryList)
      }
      else{
        toast.error("Could not fetch Country Filters.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
      }

    })
    .catch((error) => {
      toast.error("Could not fetch Country Filters.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
    })
  }

  const fetchStateFilter = (selectedCountry) => {
    apis.get(`/state/${selectedCountry}`)
    .then((response) => {
      if(response.data.success === true){
        setStateFilter(response.data.stateList)
      }
      else{
        toast.error("Could not fetch State List.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 11})
      }
    })
    .catch((error)=> {
      toast.error("Could not fetch State List", {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 11})
    })
  }

  const fetchCityFilter = (selectedState) => {
    apis.get(`/city/${selectedState}`)
    .then((response) => {
      if(response.data.success === true){
        setCityFilter(response.data.cityList)
      }
      else{
        toast.error("Could not fetch City List.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 12})
      }
    })
    .catch((error)=> {
      toast.error("Could not fetch City List", {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 12})
    })
  }

  const handleBadgeFilterChange = (e) => {
    setSelectedBadge(e.target.value)
  }

  const handleCountryFilterChange = (e) => {
    setSelectedCountry(e.target.value)
    setSelectedCity("")
    setSelectedState("")
    if(e.target.value != ""){
      setCityFilter([])
      apis.get(`/state/${e.target.value}`)
      .then((response) => {
        if(response.data.success === true){
          setStateFilter(response.data.stateList)
        }
        else{
          toast.error("Could not fetch State List.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 11})
        }
      })
      .catch((error)=> {
        toast.error("Could not fetch State List", {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 11})
      })
    }

    if(e.target.value === ""){
      setStateFilter([])
      setCityFilter([])
    }
  }

  const clearFilters = () => {
    setSelectedBadge("")
    setSelectedStatus("")
    setSelectedCountry("")
    setSelectedState("")
    setSelectedCity("")
    setStateFilter([])
    setCityFilter([])
    setPage(1)
    setReset(true)
  }

  const handleStateFilterChange = (e) => {
    setSelectedState(e.target.value)
    setSelectedCity("")
    if(e.target.value != ""){
      apis.get(`/city/${e.target.value}`)
      .then((response) => {
        if(response.data.success === true){
          setCityFilter(response.data.cityList)
        }
        else{
          toast.error("Could not fetch City List.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
        }
      })
      .catch((error)=> {
        toast.error("Could not fetch City List", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
      })
    }

    if(e.target.value === ""){
      setCityFilter([])
    }
  }

  const handleCityFilterChange = (e) => {
    setSelectedCity(e.target.value)
  }

  const handleStatusFilterChange = (e) => {
    setSelectedStatus(e.target.value)
  }

  const applyFilter = () => {
    setPage(1)
    fetchQuestionList(1)
    setActive(false)
  }

  const handleSearchChange = () => {
    setSearch(searchTerm.value)
    setPage(1)
  }

  const handleViewQuestion = (targetId) => {
    
    let filterState = {}

    if(search != ""){
      filterState["searchKey"] = search
    }

    if(selectedStatus != ""){
      filterState["status_id"] = selectedStatus
    }

    if(selectedBadge != ""){
      filterState["badge_id"] = selectedBadge 
    }

    if(page !== 1){
      filterState["page"] = page
    }

    if(rows !== 50){
      filterState["rows"] = rows
    }

    if(selectedCountry !== ""){
      filterState["country_id"] = selectedCountry
    }

    if(selectedState !== ""){
      filterState["state_id"] = selectedState
    }

    if(selectedCity !== ""){
      filterState["city_id"] = selectedCity
    }

    history.push({
      pathname : `/question/view-question/${targetId}`,
      state : filterState
    })
  }

  useEffect(() => {
    if(!accessToken){
      history.push({
        pathname : "/",
        state : {
          url : '/question'
        }
       })
       toast.error("Please login to continue.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
    }
    else if(accessToken){
      fetchQuestionList(page)
    }
  }, [rows, search])

  useEffect(() => {
    fetchBadgeFilter()
    fetchCountryFilter()
    if(countrySelected !== ""){
      fetchStateFilter(countrySelected)
    }

    if(stateSelected !== ""){
      fetchCityFilter(stateSelected)
    }
  }, [])

  useEffect(() => {
    if(reset === true){
      fetchQuestionList(1)
      setReset(false)
    }
  }, [reset])

  return (
    tokenExpire ? 
    <Timeout>
    </Timeout> :
    <>
      <main className="page_wrapper">
        <section className="dashboard_section d-flex">
          <Sidebar />

          <section className="dashboard_main_section">
            <Header title="Question  Management" screen_name = {screen_name} profilePic = {profilePic} accessToken = {accessToken}/>
            <section className="dasboard_main_area list-experts-area">
              <div className="table-exp-main">
                <div className="experts-table-filter d-flex justify-content-end align-items-center admin-user-filter-row manage-question w-100">
                  <div className="column">
                    <span className="search-filter position-relative ">
                      <span className="search-icon"></span>
                      <input
                        type="text"
                        name=""
                        id=""
                        placeholder="Search... "
                        value = {search}
                        ref={(searchInput) => searchTerm = searchInput}
                        onChange = {() => handleSearchChange()}
                      />
                    </span>
                  </div>
                  <div className="column">
                    <div className="experts-table-filter px-0">
                      <span className="filter-logo exp-btn" onClick={filters}>
                        <img
                          src={filter}
                          alt=""
                        />
                        &nbsp;Filters
                      </span>
                      <div className={isFilters ? "d-block" : "d-none"} id = "question-filter">
                        <div className="filter-box">
                        <div className="inner-column mb-3">
                          <Form.Label className="label-input100 ">
                          Select Badge
                          </Form.Label>
                          <Form.Select 
                          aria-label="Default select example"
                          onChange = {(e) => handleBadgeFilterChange(e)}
                          value = {selectedBadge}>
                            <option value = "">Badge Type</option>
                            {badgeFilter && badgeFilter.map((ele) => {
                              return(
                                <option key={ele.id} value={ele.id}>{ele.badge_name}</option>
                              )
                            })}
                          </Form.Select>
                        </div>
                        <div className="inner-column mb-3">
                          <Form.Label className="label-input100 ">
                          Select Country
                          </Form.Label>
                          <Form.Select 
                          aria-label="Default select example"
                          onChange = {(e) => handleCountryFilterChange(e)}
                          value = {selectedCountry}>
                            <option value = "">Country</option>
                            {countryFilter && countryFilter.map((ele) => {
                              return(
                                <option key={ele.id} value={ele.id}>{ele.country_name}</option>
                              )
                            })}
                          </Form.Select>
                        </div>
                        <div className="inner-column mb-3">
                          <Form.Label className="label-input100 ">
                          Select State
                          </Form.Label>
                          <Form.Select 
                          aria-label="Default select example"
                          onChange = {(e) => handleStateFilterChange(e)}
                          value = {selectedState}
                          disabled = {stateFilter.length > 0 ? false : true}>
                            <option value = "">State</option>
                            {stateFilter && stateFilter.map((ele) => {
                              return(
                                <option key={ele.id} value={ele.id}>{ele.state_name}</option>
                              )
                            })}
                          </Form.Select>
                        </div>
                        <div className="inner-column mb-3">
                          <Form.Label className="label-input100 ">
                          Select City
                          </Form.Label>
                          <Form.Select 
                          aria-label="Default select example"
                          onChange = {(e) => handleCityFilterChange(e)}
                          disabled = {cityFilter.length > 0 ? false : true}
                          value = {selectedCity}>
                            <option value = "">City</option>
                            {cityFilter && cityFilter.map((ele) => {
                              return(
                                <option key={ele.id} value={ele.id}>{ele.city_name}</option>
                              )
                            })}
                          </Form.Select>
                        </div>
                        <div className="inner-column">
                          <Form.Label className="label-input100 ">
                          Select Question Status
                          </Form.Label>
                          <Form.Select 
                          aria-label="Default select example"
                          onChange = {(e) => handleStatusFilterChange(e)}
                          value = {selectedStatus}>
                            <option  value = "">Question Status</option>
                            <option value="open">Open</option>
                            <option value="closed">Closed</option>
                          </Form.Select>
                          <div className="filter-btn-row d-flex justify-content-between">
                            <button className="btn-outline bg-white" onClick={() => setActive(false)}>Cancel</button>
                            <button className="btn-outline bg-white" onClick={() => clearFilters()}>Clear</button>
                            <button className="btn-xl p-0" onClick={() => applyFilter()}>Apply</button>
                          </div>
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="exp-table-container admin-user-table-section admin-question-table">
                  <div className="manage-user-main">
                    <table className="table list_experts_table">
                      <thead>
                        {
                          questionList && questionList.data.length > 0 ? 
                          <tr>
                            <th>
                              <div className="input-box">
                                <input type="checkbox" name="" id="" />
                              </div>
                            </th>
                            <th>Question</th>
                            <th>Username</th>
                            <th>Location</th>
                            <th>Added</th>
                            <th>Badge</th>
                            <th></th>
                          </tr> : <tr><th></th></tr>
                        }
                        
                      </thead>
                      <tbody>
                        {
                          questionList && questionList.data.length > 0 ? questionList.data.map((ele) => {
                            let badge_name = ele.badge_name
                            let createdAtdt = new Date(ele.createdAt)
                            let createdAtDateBreak = createdAtdt.toString().split(" ")
                            let createdAt = `${createdAtDateBreak[2]}-${createdAtDateBreak[1]}-${createdAtDateBreak[3]} @ ${createdAtDateBreak[4]}`

                            if(badge_name === null){
                              badge_name = 'N/A'
                            }
                            return(
                              <tr key={ele.id}>
                          <td>
                            <div className="input-box">
                              <input type="checkbox" name="" id="" />
                            </div>
                          </td>
                          <td>
                            <div className="question-text-val">
                              {ele.question_text}
                            </div>
                          </td>
                          <td>{ele.username}</td>
                          <td><div className="location-text-val">{ele.location_name}</div></td>
                          <td>{createdAt}</td>
                          <td>{badge_name}</td>
                          <td className="option-cell position-relative">
                          <Dropdown>
                              <Dropdown.Toggle
                                variant="default"
                                id="dropdown-basic"
                                size="sm"
                              >
                                <img src={option} alt="" />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item onClick={() => handleViewQuestion(ele.id)}>
                                  View/Edit
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                            )
                          }) : <tr><td>No Data To Show</td></tr>
                        }
                        
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="table-pagination-wrapper">
                <div class="table-pagination-con d-flex align-items-center justify-content-lg-end justify-content-center">
                  <span class="pagination-label">Items per page:</span>
                  <select className="select-page-number" name="" id="" value={rows} onChange={(e) => handleItemCountChange(e)}>
                    <option value= {50}>50</option>
                    <option value= {100}>100</option>
                    <option value= {150}>150</option>
                    <option value= {200}>200</option>
                    <option value= {250}>250</option>
                    <option value= {300}>300</option>
                  </select>
                  <span className="pagination-label page-counter">{questionList.page_count === 0 ? 0 : page} of {questionList.page_count}</span>
                  <span className="pagination-controls">
                    <a className="prev" style={{cursor:'pointer'}} onClick={() => handlePgDecrease()}>
                      ‹
                    </a>
                    <a className="next" style={{cursor:'pointer'}} onClick={() => handlePgIncrease()}>
                      ›
                    </a>
                  </span>
                </div>
              </div>
            </section>
          </section>
        </section>
      </main>
    </>
  );
}
export default Question;
