import Sidebar from "../../sidebar";
import Header from "../../header";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Form from "react-bootstrap/Form";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import apis from '../../../../utils/apis'
import Timeout from "../../timeout";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Link, useHistory, useLocation} from 'react-router-dom';
import { toast } from "react-toastify";

toast.configure()

export default function Userprofile() {
  const {activeTab} = useParams()
  const {user_id} = useParams()
  const history = useHistory()
  const location = useLocation()
  let appliedFiltersUsers
  if(Object.keys(location.state).length === 0){
    appliedFiltersUsers = null
  }
  else{
    appliedFiltersUsers = location.state
  }
  const loginData = JSON.parse(localStorage.getItem("loginData"))
  let accessToken, screen_name, profilePic
  if(loginData){
    accessToken = loginData.accessToken
    screen_name = loginData.screenName
    profilePic = loginData.profileImage
    
  }
  const [tokenExpire, setTokenExpire] = useState(false)
  
  // User Edit Data
  let first_name, last_name, password, email_id
  const pass_format = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
  const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const [updateCheck, setUpdateCheck] = useState(true)
  const [userData, setUserData] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [newPass, setNewPass] = useState("")
  const [emailError, setEmailError] = useState("")
  const [passError, setPassError] = useState("")
  const [fetchedImg, setFetchedImg] = useState("")
  const [removePic, setRemovePic] = useState(false)
  const [newPic, setNewPic] = useState(false)
  const [profileImage, setProfileImage] = useState("")
  const [formPic, setFormPic] = useState("")
  const [sendLink, setSendLink]= useState(true)

  const dataChangeCheck = (e) => {
    if((userData.first_name !== firstName || userData.last_name !== lastName || userData.email_id !== email || newPass !== "" || newPic === true || removePic === true) && emailError === "" && passError === ""){
      setUpdateCheck(false)
    }
    else{
      setUpdateCheck(true)
    }    
  }

  function fetchWithAuth(imgurl, token){
    const headers = new Headers();
    headers.set('authorization', `${token}`);
    return fetch(imgurl, {headers})
  }

  async function displayProtectedImage(imgurl, token){
    const response = await fetchWithAuth(imgurl, token)
    const blob = await response.blob();
    const objectUrl = URL.createObjectURL(blob);
    return objectUrl  
  }

  const handleBack = () => {
    history.push({
      pathname : '/user-management',
      state : appliedFiltersUsers
    })
  }

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setProfileImage(URL.createObjectURL(e.target.files[0]));
      setFormPic(e.target.files[0])
      setNewPic(true)
      setRemovePic(false)
    }
  }

  const handleDeletePic = () => {
    setProfileImage("");
    setFormPic("")
    setRemovePic(true)
    setNewPic(false)
  }

  const handleFirstNameChange = () => {
    setFirstName(first_name.value)
  }

  const handleLastNameChange = () => {
    setLastName(last_name.value)
  }

  const handleEmailChange = () => {
    setEmail(email_id.value)
    if(!email_id.value.match(mailFormat)){
      if(email_id.value === null || email_id.value === ""){
        setEmailError("E-Mail is Required.")
        
      }
      else if(email_id.value.indexOf(" ") >= 0){
        setEmailError("Please remove blankspace.")
        
      }
      else{
        setEmailError("Not a Valid E-Mail.")
      }     
    }
    else{
      setEmailError("")
    }
  }

  const handlePassChange = () => {
    setNewPass(password.value)
    if(!password.value.match(pass_format)){
      if(password.value === ""){
        setPassError("")
      }
      else{
        setPassError("Password must be atleast 8 characters containing one uppercase, lowercase, number and special character")
      }
    }
    else{
      setPassError("")
    }
  }

  const sendRestLink = () => {
    setSendLink(false)
    const config = {
      headers : {
        authorization : `${accessToken}`
      }
    }
    const bodyData = {
      user_id : user_id
    }

    apis.post('/admin/reset-password', bodyData, config)
    .then((response)=>{
      setSendLink(true)
      if(response.data.success === true){
        toast.success(response.data.message, {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 21})
      }
      else{
        toast.error("Could not send reset link. Please try again later", {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 20})
      }
    })
    .catch((error)=>{
      if(error.response.data.success ===false && error.response.data.message === 'jwt expired'){
        localStorage.removeItem("loginData")
        setTokenExpire(true)
      }
      else{
        setSendLink(true)
        toast.error("Could not send reset link. Please try again later", {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 20})
      }
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const config = {
      headers : {
        authorization : `${accessToken}`
      }
    }
    let formData = new FormData()
    formData.append("user_id", user_id)
    
    if(userData.first_name !== firstName){
      formData.append("first_name", firstName)
    }

    if(userData.last_name !== lastName){
      formData.append("last_name", lastName)
    }

    if(userData.email_id !== email){
      formData.append("email_id", email)
    }

    if(newPass !== ""){
      formData.append("password", newPass)
    }

    if(removePic === true && newPic === false){
      formData.append("remove_profile_pic", true)
    }

    if(newPic === true && removePic === false){
      formData.append("profile_pic", formPic)
    }
    apis.patch('/admin/update-user', formData, config)
    .then((response)=> {
      if(response.data.success === true){
        toast.success("User Edited Successfully.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 17})
        fetchUserData()
        setUpdateCheck(true)
        setNewPass("")
      }
      else{
        toast.error("Could not Edit User Data.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 5})
      }
    })
    .catch((error)=> {
      if(error.response.data.success ===false && error.response.data.message === 'jwt expired'){
        localStorage.removeItem("loginData")
        setTokenExpire(true)
      }
      else{
        toast.error("Could not Edit User Data.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 5})
        history.push('/user-management')
      }
      
    })
  }

  const handleDeleteUser = () => {
    let targetStatus
    if(userData.statusId === 1){
      targetStatus = 2
    }
    else{
      targetStatus = 1
    }
    const bodyData = {
      user_id : user_id,
      status_id : targetStatus
    }

    const config = {
      headers : {
        authorization : `${accessToken}`
      } 
    }
    apis.patch('/admin/change-user-status', bodyData, config)
    .then((response) => {
      if(response.data.success === true){
        toast.success(targetStatus === 1 ? "User Activated Successfully." : "User Deactivated Successfully.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
        fetchUserData()
      }
      else{
        toast.error("Could not change user status. Please try again later.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 9})
      }
    })
    .catch((error) => {
      toast.error("Could not change user status. Please try again later.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 9})
    })
  }

  const fetchUserData = () => {
    console.log("User Data Called")
    const config = {
      headers : {
        authorization : `${accessToken}`
      }
    }
    apis.get(`/admin/user-details/${user_id}`, config)
    .then((response) => {
      if(response.data.data.username === null){
        history.push('/user-management')
      }
      else if(response.data.success === false){
        toast.error("Could not Fetch User Data.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
      }
      else{
        console.log(response)
        setUserData(response.data.data)
        setFirstName(response.data.data.first_name)
        setLastName(response.data.data.last_name)
        setEmail(response.data.data.email_id)
        
        if(response.data.data.profile_image !== null){
          const fetchedImage = displayProtectedImage(response.data.data.profile_image, accessToken)
          fetchedImage.then((response) => {
          setFetchedImg(response)
          })
          .catch((error) =>{
            setFetchedImg(null)
          })
        }
        else{
          setFetchedImg(null)
        }
      }
    })
    .catch((error)=>{
      if(error.response.data.success ===false && error.response.data.message === 'jwt expired'){
        localStorage.removeItem("loginData")
        setTokenExpire(true)
      }
      else{
        toast.error("Could not fetch User Data.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 5})
        history.push('/user-management')
      }
    })
  }
  useEffect(() => {
    if(!accessToken && tokenExpire === false){
      history.push({
        pathname : "/",
        state : {
          url : '/user-management'
        }
      })
      toast.error("Please login to continue.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 1})
    }
    else if(accessToken){
      fetchUserData()
    }
  }, [])
  useEffect(() => {
    dataChangeCheck()
  }, [firstName, lastName, email, newPass, newPic, removePic])

  const finalImage = () => {
    if(fetchedImg === null && removePic === false && newPic === false){
      return ""
    }
    else if(removePic === true){
      return ""
    }
    else if(newPic === true){
      return profileImage
    }
    else{
      return fetchedImg
    }
  }
  console.log("FINAL IMAGE", finalImage())

  let overlay_display = finalImage()

  if(overlay_display === ""){
    overlay_display = "none"
  }
  else{
    overlay_display = "block"
  }

  // Point History Data
  const [pointsPage, setPointsPage] = useState(1)
  const [pointsRows, setPointsRows] = useState(5)
  const [pointsHistoryResult, setPointsHistoryResult] = useState("")
  const [pointType, setPointType] = useState("gained")

  const handlePointPageIncrease = () => {
    if(pointsPage !== pointsHistoryResult.page_count){
      let page = pointsPage + 1
      setPointsPage(pointsPage + 1)
      fetchPointsData(page)
    }
  }

  const handlePointPageDecrease = () => {
    if(pointsPage !== 1){
      let page = pointsPage - 1
      setPointsPage(pointsPage - 1)
      fetchPointsData(page)
    }
  }

  const handlePointsRowsChange = (e) => {
    setPointsRows(e.target.value)
    setPointsPage(1)

  }
  const fetchPointsData = (page) => {
    const config = {
      headers : {
        authorization : `${accessToken}`
      },
      params : {
        user_id : user_id,
        page : page,
        size : pointsRows,
        type : pointType
      }
    }

    apis.get('/admin/gained-spend-point-list/', config)
    .then((response)=> {
      if(response.data.success === true){
        setPointsHistoryResult(response.data)
        console.log(response.data)
      }
      else{
        toast.error("Could not fetch Points History.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 18})
      }
      
    })
    .catch((error)=> {
      if(error.response.data.success ===false && error.response.data.message === 'jwt expired'){
        localStorage.removeItem("loginData")
        setTokenExpire(true)
      }
      else{
        toast.error("Could not fetch Points History.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 18})
      }
      
    })
  }

  const handleViewPoint = (question_id) => {
    localStorage.setItem("activeTab", activeTab)
    history.push(`/user-management/view-point/${question_id}`)
  }

  const handlePointTypeChange = (e) => {
    setPointType(e.target.value)
    setPointsPage(1)
    setPointsRows(5)
  }

  useEffect(() => {
    if(!accessToken){
      history.push({
        pathname : "/",
        state : {
          url : '/user-management'
        }
       })
       toast.error("Please login to continue.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 18})
    }
    else if(accessToken){
      fetchPointsData(pointsPage)
    }
  }, [pointsRows, pointType])


  // Spam History Data

  const [spamPage, setSpamPage] = useState(1)
  const [spamRows, setSpamRows] = useState(5)
  const [spamData, setSpamData] = useState("")

  const fetchSpamData = (page) => {
    const config = {
      headers : {
        authorization : `${accessToken}`
      }
    }

    const bodyData = {
      id : user_id,
      page : page,
      size : spamRows
    }

    apis.post('/admin/spam-history', bodyData, config)
    .then((response) => {
      if(response.data.success === true){
        setSpamData(response.data)
      }
      else{
        toast.error("Could not fetch Spam History.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 19})
      }
    })
    .catch((error)=>{
      if(error.response.data.success ===false && error.response.data.message === 'jwt expired'){
        localStorage.removeItem("loginData")
        setTokenExpire(true)
      }
      else{
        toast.error("Could not fetch Spam History.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 19})
      }
    })
  }

  const handleSpamRowsChange = (e) => {
    setSpamRows(e.target.value)
    setSpamPage(1)
  }

  const handleSpamPageDecrease = () => {
    if(spamPage !== 1){
      let page = spamPage - 1
      setSpamPage(spamPage - 1)
      fetchSpamData(page)
    }
  }

  const handleSpamPageIncrease = () => {
    if(spamPage !== spamData.page_count){
      let page = spamPage + 1
      setSpamPage(spamPage + 1)
      fetchSpamData(page)
    }
  }

  const handleViewSpam = (response_id) => {
    history.push(`/user-management/view-spam/${response_id}`)

  }

  useEffect(() => {
    if(!accessToken){
      history.push({
        pathname : "/",
        state : {
          url : '/user-management'
        }
       })
       toast.error("Please login to continue.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 18})
    }
    else if(accessToken){
      fetchSpamData(spamPage)
    }
  }, [spamRows])

  return (
    tokenExpire ? 
    <Timeout>
    </Timeout>:
    <>
      <main className="page_wrapper">
        <section className="dashboard_section d-flex">
          <Sidebar />

          <section className="dashboard_main_section">
            <Header title="View/Edit User" screen_name = {screen_name} profilePic = {profilePic} accessToken = {accessToken}/>
            <section className="dasboard_main_area list-experts-area admin">
              <div className="dashboard_container">
                <div className="back-btn-main mb-3">
                  <div onClick={() => handleBack()} style={{cursor:'pointer'}}>
                    <KeyboardBackspaceIcon /> User : {userData.username}
                  </div>
                </div>
              </div>
              <Tabs
                id="controlled-tab-example"
                className="mb-3"
                activeKey={activeTab}
                onSelect={(key) => history.replace({pathname : `/user-management/view-edit/${user_id}/${key}`, state : location.state})}

              >
                <Tab eventKey="edit-user" title="Edit User Details">
                  <div className="tabs_pannel">
                    <div className="user-details-form-main pt-25">
                      <form className="user-details-form">
                        <div className="row outer-row">
                          <div className="col-lg-9 order-lg-1 order-md-2">
                            <div className="row inner-row">
                              <div className="col-lg-6 column">
                                <div className="inner-column">
                                  <Form.Group controlId="exampleForm.ControlInput1">
                                    <Form.Label className="label-input100 ">
                                      First Name
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      value={firstName}
                                      ref={(firstNameInput) => first_name = firstNameInput}
                                      onChange = {() => handleFirstNameChange()}
                                    />
                                  </Form.Group>
                                </div>
                              </div>
                              <div className="col-lg-6 column">
                                <div className="inner-column">
                                  <Form.Group controlId="exampleForm.ControlInput1">
                                    <Form.Label className="label-input100 ">
                                      Last Name
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      value={lastName}
                                      ref={(lastNameInput) => last_name = lastNameInput}
                                      onChange = {() => handleLastNameChange()}
                                    />
                                  </Form.Group>
                                </div>
                              </div>
                              <div className="col-lg-6 column">
                                <div className="inner-column">
                                  <Form.Group controlId="exampleForm.ControlInput1">
                                    <Form.Label className="label-input100 ">
                                      Email ID
                                    </Form.Label>
                                    <Form.Control
                                      type="email"
                                      value={email}
                                      ref={(emailInput) => email_id = emailInput}
                                      onChange = {() => handleEmailChange()}
                                    />
                                    {emailError === "" ? <></> : <Form.Label className="error-label">{emailError}</Form.Label>}
                                  </Form.Group>
                                </div>
                              </div>
                              <div className="col-lg-6 column">
                                <div className="inner-column">
                                  <Form.Group controlId="exampleForm.ControlInput1">
                                    <Form.Label className="label-input100 ">
                                      Password
                                    </Form.Label>
                                    <Form.Control
                                      type="password"
                                      placeholder="********"
                                      value={newPass}
                                      ref = {(passInput) => password = passInput}
                                      onChange = {() => handlePassChange()}
                                    />
                                    {passError === "" ? <></> : <Form.Label className="error-label">{passError}</Form.Label>}
                                    {sendLink === true ? <Form.Text onClick={() => sendRestLink()}>Password Reset Link</Form.Text> : <></>}
                                  </Form.Group>
                                </div>
                              </div>
                              <div className="col-lg-12 column">
                                <Form.Group
                                  className="inner-column"
                                  controlId="exampleForm.ControlInput1"
                                >
                                  <Form.Label className="label-input100 ">
                                    Screen Name
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    value={userData.screen_name}
                                    disabled = {true}
                                  />
                                </Form.Group>
                              </div>
                              <div className="col-lg-12 column">
                                <Form.Group
                                  className="inner-column"
                                  controlId="exampleForm.ControlInput1"
                                >
                                  <Form.Label className="label-input100 ">
                                    User Type
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    value={userData.badge_name===null ? 'N/A' : userData.badge_name}
                                    disabled = {true}
                                  />
                                </Form.Group>
                              </div>
                              <div className="col-lg-6 column">
                                <Form.Group
                                  className="inner-column"
                                  controlId="exampleForm.ControlInput1"
                                >
                                  <Form.Label className="label-input100 ">
                                    Points Gained
                                  </Form.Label>
                                  <Form.Control
                                    type="number"
                                    disabled = {true}
                                    value = {userData.point_gained}
                                  />
                                </Form.Group>
                              </div>
                              <div className="col-lg-6 column">
                                <Form.Group
                                  className="inner-column"
                                  controlId="exampleForm.ControlInput1"
                                >
                                  <Form.Label className="label-input100 ">
                                    Upvotes
                                  </Form.Label>
                                  <Form.Control
                                    type="number"
                                    value = {userData.upvote_count}
                                    disabled = {true}
                                  />
                                </Form.Group>
                              </div>
                              <div className="col-lg-12 column">
                                <Form.Group
                                  className="inner-column"
                                  controlId="exampleForm.ControlInput1"
                                >
                                  <Form.Label className="label-input100 ">
                                    Balance Points
                                  </Form.Label>
                                  <Form.Control
                                    type="number"
                                    value = {userData.balance_point}
                                    disabled = {true}
                                  />
                                </Form.Group>
                              </div>
                              <div className="col-lg-12">
                                <div className="submit-wrapper user-form-submit submit-main row mt-4">
                                  <div className="text-end ">
                                    <input
                                      className="btn btn-outline"
                                      type=" button"
                                      value="Cancel"
                                      onClick={() => handleBack()}
                                    />
                                    <input
                                      className="btn btn-xl text-center"
                                      type="submit"
                                      value="Update"
                                      disabled = {updateCheck}
                                      onClick={(e) => handleSubmit(e)}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 text-center profile-col order-lg-2 order-md-1">
                            <div className="inner-column ">
                              <div class="container-test">
                                <img src={finalImage()} className="image-test" style={{width : "100%"}}></img>
                                <div class="overlay" style={{display : overlay_display}} >
                                  <div className="icon-test" title="User Profile">
                                    <i className="fa-solid fa-trash-can" style={{cursor:'pointer'}} onClick={() => handleDeletePic()}></i>
                                  </div>
                                </div>
                              </div>
                              {/* <img className="user-profile-img" src={finalImage()}></img> */}
                              <div className="img-ctrls-main">
                                <label htmlFor="profile_pic" className="btn btn-outline d-block" style={{display:"block"}}>Upload</label>
                                <input type="file" name="profile_pic" id="profile_pic" style={{display:'none'}} onChange={handleImageChange}>  
                                </input>
                                <input
                                  className={userData.statusId === 1 ? "btn btn-outline d-block rejected-badge" : "btn btn-outline d-block complteted-badge" }
                                  type="button"
                                  value= {userData.statusId === 1 ? "Deactivate Account" : "Activate Account" }
                                  onClick = {() => handleDeleteUser()}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="points-history" title="Points History">
                  <div className="tabs_pannel" id="tab2">
                    <div className="points-history-section pt-25">
                      <div className="radio">
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={pointType}
                            name="radio-buttons-group"
                            onChange={(e) => handlePointTypeChange(e)}
                          >
                            <FormControlLabel
                              value="gained"
                              control={<Radio/>}
                              label="Gained and Spend"
                            />
                            <FormControlLabel
                              value="bought"
                              control={<Radio/>}
                              label="Bought"
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>
                      <div className="points-table-section">
                        <div className="points-table-container">
                          {/* <div className="point-sort-filter">
                            <div className="sort-filter-wrap">
                              <div className="select_container">
                                <div className="dropholder">
                                  <Form.Select
                                    size="sm"
                                    aria-label="Default select example"
                                  >
                                    <option>All</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                  </Form.Select>
                                </div>
                              </div>
                            </div>
                          </div> */}
                          <div className="points-tb-wrapper">
                            <table className="points-table">
                              <thead className="points-tb-header">
                                {pointsHistoryResult && pointsHistoryResult.data.length > 0 ?
                                  <tr>
                                    <th>
                                      <div className="input-box">
                                        <input type="checkbox" name="" id="" />
                                      </div>
                                    </th>
                                    <th>Question</th>
                                    <th>Date & Time</th>
                                    <th>Points</th>
                                    <th>Description</th>
                                    <th>
                                      <div className="action-cell">Action</div>
                                    </th>
                                  </tr> : <tr><th></th></tr>
                                }

                                
                              </thead>
                              <tbody>
                                {pointsHistoryResult && pointsHistoryResult.data.length > 0 ? pointsHistoryResult.data.map((ele)=> {
                                  let createdAtdt = new Date(ele.createdAt)
                                  let createdAtDateBreak = createdAtdt.toString().split(" ")
                                  let createdAt = `${createdAtDateBreak[2]}-${createdAtDateBreak[1]}-${createdAtDateBreak[3]} @ ${createdAtDateBreak[4]}`
                                  let questionText = ""
                                  let description = ""
                                  if(ele.point_sub_type === "send_request"){
                                    questionText = "N/A"
                                    description = "Request sent to expert"
                                  }
                                  else if(ele.point_sub_type === "accept_request"){
                                    questionText = "N/A"
                                    description = "User accepted connection request."
                                  }
                                  else if(ele.point_sub_type === "point_purchased"){
                                    questionText = "N/A"
                                    description = "Purchased points"
                                  }
                                  else{
                                    questionText = ele.question_text
                                  }

                                  if(ele.point_sub_type == "accept_request"){
                                    description = "Connection request accepted"
                                  }
                                  else if(ele.point_sub_type == "send_request"){
                                    description = "Sent request to connection"
                                  }
                                  else if(ele.point_sub_type == "purchase_question_ownership"){
                                    description = "Purchased question ownership"
                                  }
                                  else if(ele.point_sub_type == "gain_response_point"){
                                    description = "Gained points on response"
                                  }
                                  else if(ele.point_sub_type == "asked_question"){
                                    description = "Asked question"
                                  }
                                  else if(ele.point_sub_type == "view_response"){
                                    description = "Viewed responses"
                                  }
                                  else if(ele.point_sub_type == "distribute_extra_point"){
                                    description = "Distributed extra points to response"
                                  }
                                  return(
                                    <tr key={ele.id}>
                                      <td>
                                        <div className="input-box">
                                          <input type="checkbox" name="" id="" />
                                        </div>
                                      </td>
                                      <td>{questionText}</td>
                                      <td>{createdAt}</td>
                                      <td>{ele.point} {ele.point_name}</td>
                                      <td>{description}</td>
                                      <td>  
                                        <div className="action-cell">
                                          { ele.point_sub_type === "send_request" || ele.point_sub_type === "accept_request" || ele.point_sub_type === "point_purchased" ? "N/A" : <p id = "view-button" onClick={() => handleViewPoint(ele.id)}>View</p>}
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                }) : <tr>
                                  <td>No Data To Show.</td>
                                  </tr>}  
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="table-pagination-wrapper">
                        <div className="table-pagination-con d-flex align-items-center justify-content-lg-end justify-content-center">
                          <span className="pagination-label">Items per page:</span>
                          <select className="select-page-number" name="" id="" onChange={(e) => handlePointsRowsChange(e)}>
                            <option value= {5} selected>05</option>
                            <option value= {10}>10</option>
                            <option value= {15}>15</option>
                          </select>
                          <span className="pagination-label page-counter">{pointsHistoryResult.page_count === 0 ? 0 : pointsPage} of {pointsHistoryResult.page_count}</span>
                          <span className="pagination-controls">
                            <a className="prev" style={{cursor:'pointer'}} onClick={() => handlePointPageDecrease()}>
                              ‹
                            </a>
                            <a className="next" style={{cursor:'pointer'}} onClick={() => handlePointPageIncrease()}>
                              ›
                            </a>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="spam-history" title="Spam History">
                  <div className="tabs_pannel" id="tab3">
                    <div className="spam-history-section points-history-section pt-25">
                      <div className="points-table-section banks-table-section select-banks-table">
                        <div className="points-table-container">
                          <div className="points-tb-wrapper table-responsive">
                            <table className="points-table">
                              <thead className="points-tb-header">
                                {spamData && spamData.data.length > 0 ? 
                                <tr>
                                  <th>
                                    <div className="input-box">
                                      <input type="checkbox" name="" id="" />
                                    </div>
                                  </th>
                                  <th>Question</th>
                                  <th>Response</th>
                                  <th>Spam Count</th>
                                  <th>
                                    <div className="action-cell">Action</div>
                                  </th>
                                </tr> : <tr><th></th></tr>}
                              </thead>
                              <tbody>
                                {
                                  spamData && spamData.data.length > 0 ? spamData.data.map((ele)=> {
                                    return(
                                      <tr key={ele.response_id}>
                                        <td>
                                          <div className="input-box">
                                            <input type="checkbox" name="" id="" />
                                          </div>
                                        </td>
                                        <td>{ele.question_text}</td>
                                        <td>{ele.response_text}</td>
                                        <td>{ele.spam_count}</td>
                                        <td>
                                          <div className="action-cell">
                                            <p id = "view-button" onClick={() => handleViewSpam(ele.response_id)}>View</p>
                                          </div>
                                        </td>
                                      </tr>
                                    )
                                  }) : <tr>
                                    <td>No Data to Show.</td>
                                  </tr>
                                }
                                
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="table-pagination-wrapper">
                        <div className="table-pagination-con d-flex align-items-center justify-content-lg-end justify-content-center">
                          <span className="pagination-label">Items per page:</span>
                          <select className="select-page-number" name="" id="" onChange={(e) => handleSpamRowsChange(e)}>
                            <option value= {5} selected>05</option>
                            <option value= {10}>10</option>
                            <option value= {15}>15</option>
                          </select>
                          <span className="pagination-label page-counter">{spamData.page_count === 0 ? 0 : spamPage} of {spamData.page_count}</span>
                          <span className="pagination-controls">
                            <a className="prev" style={{cursor:'pointer'}} onClick={() => handleSpamPageDecrease()}>
                              ‹
                            </a>
                            <a className="next" style={{cursor:'pointer'}} onClick={() => handleSpamPageIncrease()}>
                              ›
                            </a>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </section>
          </section>
        </section>
      </main>
    </>
  );
}
