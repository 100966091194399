import Sidebar from "../../sidebar";
import Header from "../../header";
import Form from "react-bootstrap/Form";
import apis from '../../../../utils/apis'
import {useState, useEffect} from 'react'
import { useHistory } from 'react-router-dom';
import { toast } from "react-toastify";
import Timeout from "../../timeout";
import {Spinner} from "react-bootstrap"

toast.configure()

function Account() {
  let first_name, last_name, email_id, phone_number, new_password, old_password
  const pass_format = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
  const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const phoneFormat = /^\d{10}$/
  const [updateCheck, setUpdateCheck] = useState(false)
  const [userData, setUserData] = useState("")
  const [notificationSetting, setNotificationSetting] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [oldPass, setOldPass] = useState("")
  const [newPass, setNewPass] = useState("")
  const [tokenExpire, setTokenExpire] = useState(false)
  const [emailError, setEmailError] = useState("")
  const [phoneError, setPhoneError] = useState("")
  const [oldPassError, setOldPassError] = useState("")
  const [newPassError, setNewPassError] = useState("")
  const [showLoader, setShowLoader] = useState(false)
  const [fetchedImg, setFetchedImg] = useState("")
  const [removePic, setRemovePic] = useState(false)
  const [newPic, setNewPic] = useState(false)
  const [profileImage, setProfileImage] = useState("")
  const [formPic, setFormPic] = useState("")
  const history = useHistory()
  const loginData = JSON.parse(localStorage.getItem("loginData"))
  let accessToken, screen_name, profilePic
  if(loginData){
    accessToken = loginData.accessToken
    screen_name = loginData.screenName
    profilePic = loginData.profileImage
  }

  const finalImage = () => {
    if(fetchedImg === null && removePic === false && newPic === false){
      return ""
    }
    else if(removePic === true){
      return ""
    }
    else if(newPic === true){
      return profileImage
    }
    else{
      return fetchedImg
    }
  }

  const onSwitchAction = () => {
    let target_setting
    if(notificationSetting.overallNotification===1){
      target_setting = 0
    }
    else{
      target_setting = 1
    }

    const config = {
      headers: { authorization: `${accessToken}` }
    }

    const bodyData = {
      setOverall : target_setting
    }

    apis.patch('/set-notification-setting', bodyData, config)
    .then((response)=>{
      if(response.data.success === true){
        toast.success(`Notification ${response.data.message}`, {autoClose : 1000, position : toast.POSITION.TOP_CENTER})
        fetchData()
      }
    })
  }

  let overlay_display = finalImage()

  if(overlay_display === ""){
    overlay_display = "none"
  }
  else{
    overlay_display = "block"
  }

  const handleDeletePic = () => {
    setProfileImage("");
    setFormPic("")
    setRemovePic(true)
    setNewPic(false)
  }

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setProfileImage(URL.createObjectURL(e.target.files[0]));
      console.log(e.target.files[0])
      setFormPic(e.target.files[0])
      setNewPic(true)
      setRemovePic(false)
    }
  }

  const handleFirstNameChange = () => {
    setFirstName(first_name.value)
  }

  const handleLastNameChange = () => {
    setLastName(last_name.value)
  }

  const handleEmailChange = () => {
    setEmail(email_id.value)
    if(!email_id.value.match(mailFormat)){
      if(email_id.value === null || email_id.value === ""){
        setEmailError("E-Mail is Required.")
        
      }
      else if(email_id.value.indexOf(" ") >= 0){
        setEmailError("Please remove blankspace.")
        
      }
      else{
        setEmailError("Not a Valid E-Mail.")
      }     
    }
    else{
      setEmailError("")
    }
  }

  const handlePhoneNumberChange = () => {
    setPhone(phone_number.value)
    if(!phone_number.value.match(phoneFormat)){
      if(phone_number.value === ""){
        setPhoneError("")
      }
      else{
        setPhoneError("Phone number should have exactly 10 digits.")
      }
    }
    else{
      setPhoneError("")
    }
  }

  const handleOldPasswordChange = () => {
    setOldPass(old_password.value)
    if(!old_password.value.match(pass_format)){
      if(old_password.value === ""){
        setOldPassError("")
      }
      else{
        setOldPassError("Password must be atleast 8 characters containing one uppercase, lowercase, number and special character")
      }
    }
    else{
      setOldPassError("")
    }
  }

  const handleNewPasswordChange = () => {
    setNewPass(new_password.value)
    if(!new_password.value.match(pass_format)){
      if(new_password.value === ""){
        setNewPassError("")
      }
      else{
        setNewPassError("Password must be atleast 8 characters containing one uppercase, lowercase, number and special character")
      }
    }
    else{
      setNewPassError("")
    }
  }

  function fetchWithAuth(imgurl, token){
    const headers = new Headers();
    headers.set('authorization', `${token}`);
    return fetch(imgurl, {headers})
  }

  async function displayProtectedImage(imgurl, token){
    const response = await fetchWithAuth(imgurl, token)
    const blob = await response.blob();
    const objectUrl = URL.createObjectURL(blob);
    return objectUrl  
  }

  const fetchData = () => {
    const config = {
      headers: { authorization: `${accessToken}` }
    }

    apis.get('/user', config)
    .then((response) => {
      if(response.data.success === false){
        toast.error("Could not fetch your data. Please try again later.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
      }
      else{
        setUserData(response.data.data)
        setNotificationSetting(response.data.notificationSetting)
        setFirstName(response.data.data.first_name)
        setLastName(response.data.data.last_name)
        setEmail(response.data.data.email_id)
        setPhone(response.data.data.mobile_no)

        if(response.data.data.profile_image !== null){
          const fetchedImage = displayProtectedImage(response.data.data.profile_image, accessToken)
          fetchedImage.then((response) => {
          setFetchedImg(response)
          })
          .catch((error) =>{
            setFetchedImg(null)
          })
        }
        else{
          setFetchedImg(null)
        }
      }
    })
    .catch((error)=> {
      if(error.response){
        if(error.response.data.success ===false && error.response.data.message === 'jwt expired'){
          setTokenExpire(true)
          localStorage.removeItem("loginData")
        }
      }
      else{
        toast.error("Could not fetch your Data. Please try again later.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
      }
    })
  }

  const dataChangeCheck = (e) => {
    if((userData.first_name !== firstName || userData.last_name !== lastName || userData.email_id !== email || userData.mobile_no !== phone || (oldPass !== "" && newPass !== "") || newPic === true || removePic === true) && oldPassError === "" && newPassError === "" && emailError === "" && phoneError === ""){
      setUpdateCheck(false)
    }
    else{
      setUpdateCheck(true)
    }    
  }

  const handleChangeSubmit = () => {
    setShowLoader(true)
    setUpdateCheck(true)
    const config = {
      headers : {
        authorization : `${accessToken}`
      }
    }
    let formData = new FormData()

    if(userData.first_name !== firstName){
      formData.append("first_name", firstName)
    }

    if(userData.last_name !== lastName){
      formData.append("last_name", lastName)
    }

    if(userData.email_id !== email){
      formData.append("email_id", email)
    }

    if(userData.mobile_no !== phone){
      formData.append("mobile_no", phone)
    }

    if(newPass !== ""){
      formData.append("new_password", newPass)
    }

    if(oldPass !== ""){
      formData.append("password", oldPass)
    }

    if(removePic === true && newPic === false){
      formData.append("remove_profile_pic", true)
    }

    if(newPic === true && removePic === false){
      formData.append("profile_pic", formPic)
    }

    apis.patch('/admin/update-admin', formData, config)
    .then((response)=> {
      setShowLoader(false)
      if(response.data.success === true){
        toast.success("Profile Updated Successfully.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
        console.log(response)
        fetchData()
        setNewPass("")
        setOldPass("")
        if(newPic === true || removePic === true){
          const loginData = {
            accessToken : accessToken,
            screenName : screen_name,
            profileImage : response.data.data.profile_image
          }
          localStorage.removeItem("loginData")
          localStorage.setItem("loginData", JSON.stringify(loginData))
        }
        setNewPic(false)
        setRemovePic(false)
        
      }
      else{
        toast.error(response.data.message, {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
        setUpdateCheck(false)
      }
    })
    .catch((error)=> {
      setShowLoader(false)
      if(error.response.data.success ===false && error.response.data.message === 'jwt expired'){
        localStorage.removeItem("loginData")
        setTokenExpire(true)
      }
      else{
        toast.error("Could not Edit User Data.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
        history.push('/account')
      }
      
    })
  }

  useEffect(() => {
    if(!accessToken){
      history.push({
        pathname : "/",
        state : {
          url : '/account'
        }
       })
       toast.error("Please login to continue.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
    }
    else if(accessToken){
      fetchData()
    }
  }, [])

  useEffect(() => {
    dataChangeCheck()
  }, [firstName, lastName, email, newPass, phone, oldPass, newPic, removePic])
  
  return (
    tokenExpire ? 
    <Timeout>
    </Timeout> : 
    <>
    <main className="page_wrapper">
      <section className="dashboard_section d-flex">
        <Sidebar />
        <section className="dashboard_main_section">
          <Header title="Account Settings" screen_name = {screen_name} profilePic = {profilePic} accessToken = {accessToken}/>
          <section class="dasboard_main_area list-experts-area admin">
            <div className="heading">
              <h3 className="txt-3">Welcome Super Admin</h3>
            </div>
            <div className="row mt-3">
              <div className="col-lg-8 order-lg-1 order-2 ">
                <div className="row">
                  <div className="col-sm-6 mt-2">
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Label className="label-input100 ">
                        First Name
                      </Form.Label>
                      <Form.Control type="text" placeholder="Enter First Name" value={firstName} ref = {(fnameinput) => first_name = fnameinput} onChange = {() => handleFirstNameChange()}/>
                    </Form.Group>
                  </div>
                  <div className="col-sm-6 mt-2">
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Label className="label-input100 ">
                        Last Name
                      </Form.Label>
                      <Form.Control type="text" placeholder="Enter Last Name" value={lastName} ref = {(lnameinput) => last_name = lnameinput} onChange = {() => handleLastNameChange()} />
                    </Form.Group>
                  </div>
                  <div className="col-sm-6 mt-3">
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Label className="label-input100 ">
                        Email ID
                      </Form.Label>
                      <Form.Control type="email-id" placeholder="Enter E-Mail ID" value={email} ref = {(emailinput) => email_id = emailinput} onChange = {() => handleEmailChange()}/>
                      {emailError === "" ? <></> : <Form.Label className="error-label">{emailError}</Form.Label>}
                      {userData.isEmailVerified === 0 ? <Form.Text>Email Not Verified. Click here to resend link.</Form.Text> : <></>}
                    </Form.Group>
                  </div>
                  <div className="col-sm-6 mt-3">
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Label className="label-input100 ">
                        Phone Number
                      </Form.Label>
                      <Form.Control type="text" placeholder="Enter Phone Number" value = {phone} ref = {(phoneinput)=> phone_number = phoneinput} onChange = {() => handlePhoneNumberChange()}/>
                      {phoneError === "" ? <></> : <Form.Label className="error-label">{phoneError}</Form.Label>}
                    </Form.Group>
                  </div>
                  <div className="col-sm-6 mt-2">
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Label className="label-input100 ">
                        Old Password
                      </Form.Label>
                      <Form.Control type="password" placeholder="******" onChange={() => handleOldPasswordChange()} value = {oldPass} ref = {(oldpassinput) => old_password = oldpassinput} name='devicePassword' autoComplete='new-password'/>
                      {oldPassError === "" ? <></> : <Form.Label className="error-label">{oldPassError}</Form.Label>}
                    </Form.Group>
                  </div>
                  <div className="col-sm-6 mt-2">
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Label className="label-input100 ">
                          New Password
                      </Form.Label>
                      <Form.Control type="password" placeholder="******" onChange={() => handleNewPasswordChange()} value = {newPass} ref = {(newpassinput) => new_password = newpassinput} name='devicePassword' autoComplete='new-password'/>
                      {newPassError === "" ? <></> : <Form.Label className="error-label">{newPassError}</Form.Label>}
                    </Form.Group>
                  </div>
                  <div className="col-sm-6 my-4">
                    <div >
                    <p className="d-flex align-items-center justify-content-between txt-2">
                      Notification
                      <span>
                        <Form>
                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            checked = {notificationSetting.overallNotification === 0 ? false : true}
                            onChange={onSwitchAction}
                          />
                        </Form>
                      </span>
                    </p>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="text-end">
                      <button className="btn-outline mx-3" onClick={() => history.goBack()}>Cancel</button>
                      <button className="btn btn-xl text-center p-3" disabled = {updateCheck} onClick = {() => handleChangeSubmit()}>{showLoader ? 
                    <Spinner id = "spinner-btn"
                    as="span"
                    variant="light"
                    size= "lg"
                    role="status"
                    aria-hidden="true"
                    animation="grow">
                      <p id = "spinner-text-2">Loading...</p>
                    </Spinner> : "Save Changes"}</button>     
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 order-lg-2 order-1 account">
                <div className="profile-pic">
                  <div>
                    <div class="container-test">
                      <img src={finalImage()} className="image-test" style={{width : "100%"}}></img>
                      <div class="overlay" style={{display : overlay_display}} >
                        <div className="icon-test" title="User Profile">
                          <i className="fa-solid fa-trash-can" style={{cursor:'pointer'}} onClick={() => handleDeletePic()}></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-2">
                    {/* <button className="btn-outline">Change Picture</button> */}
                    <label htmlFor="profile_pic" className="btn btn-outline d-block" style={{display:"block"}}>Upload</label>
                    <input type="file" name="profile_pic" id="profile_pic" style={{display:'none'}} onChange={handleImageChange}>  
                    </input>
                    
                  </div>
                  <div className="mt-2">
                    {/* <button className="btn-outline">Delete Account</button> */}
                    {/* <input
                      className="btn btn-outline d-block"
                      type="button"
                      value="Delete Account"
                      style={{display : "none"}}
                      
                    /> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </section>
    </main>
  </>
  );
}
export default Account;
