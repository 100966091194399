import {useState, useEffect} from 'react'
import { useHistory } from 'react-router-dom';
import Timeout from '../timeout'
import apis from '../../../utils/apis'
import Sidebar from "../sidebar";
import Header from "../header";
import user from "../../../assets/img/total-user-icon.svg";
import number from "../../../assets/img/number-new-user-icon.svg";
import ques from "../../../assets/img/new-question-icon.svg";
import wallet from "../../../assets/img/wallet-icon.svg";
import { toast } from "react-toastify";

toast.configure()

function Dashboard() {
  const history = useHistory()
  const loginData = JSON.parse(localStorage.getItem("loginData"))
  let accessToken, screen_name, profilePic
  if(loginData){
    accessToken = loginData.accessToken
    screen_name = loginData.screenName
    profilePic = loginData.profileImage
  }
  const [dashboardData, setDashboardData] = useState("")
  const [tokenExpire, setTokenExpire] = useState(false)
  useEffect(() => {
    if(!accessToken){
      history.push({
        pathname : "/",
        state : {
          url : '/dashboard'
        }
       })
       toast.error("Please login to continue.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 10})
    }
    else if(accessToken && dashboardData === ""){
      fetchData()
    }
  })
  const fetchData = async () => {
    const config = {
      headers: { authorization: `${accessToken}` }
    }
    apis.get('/admin/dashboard', config)
    .then((response) => {
      const data = response.data
      if(data.success === false){
        toast.error(data.message, {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 2})
      }
      else{
        setDashboardData(data)
      }
    })
    .catch((error) => {
      if(error.response){
        if(error.response.data.success ===false && error.response.data.message === 'jwt expired'){
          setTokenExpire(true)
          localStorage.removeItem("loginData")
        }
      }
      else{
        toast.error("Could not fetch User List.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 5})
      }
    })
  }
  return (
    tokenExpire ? 
    <Timeout>
    </Timeout>
     : 
    <div>
      <main className="page_wrapper">
        <section className="dashboard_section d-flex">
          <Sidebar />
          <section className="dashboard_main_section">
            <Header title="Dashboard" screen_name = {screen_name} profilePic = {profilePic} accessToken = {accessToken}/>
            <section className="dasboard_main_area admin-dashboard">
              <div className="dashboard_container">
                <div className="dasboard_counter_main">
                  <div className="row counter-card-row">
                    <div className="col-lg-6  counter-cards">
                      <div className="inner-column">
                        <div className="counter-card-img card-inner-cols">
                          <img src={user} alt="" />
                        </div>
                        <div className="counter-card-content card-inner-cols">
                          <div className="card-head-main d-flex justify-content-between ">
                            <div className="counter-label">
                              <h4>Total Users</h4>
                            </div>
                          </div>
                          <div className="counter-val-main">
                            <p>{dashboardData && dashboardData.total_users}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6  counter-cards">
                      <div className="inner-column">
                        <div className="counter-card-img card-inner-cols">
                          <img src={number} alt="" />
                        </div>
                        <div className="counter-card-content card-inner-cols">
                          <div className="card-head-main d-flex justify-content-between ">
                            <div className="counter-label">
                              <h4>
                                New Users
                              </h4>
                            </div>
                            <div className="counter-filter text-end">
                              <p className='day-filter'>Last 3 days</p>
                            </div>
                          </div>
                          <div className="counter-val-main">
                            <p>{dashboardData && dashboardData.new_users}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dasboard_counter_main admin-dashboard-main">
                  <div className="row counter-card-row">
                    <div className="col-lg-6  counter-cards">
                      <div className="inner-column">
                        <div className="counter-card-img card-inner-cols">
                          <img src={ques} alt="" />
                        </div>
                        <div className="counter-card-content card-inner-cols">
                          <div className="card-head-main d-flex justify-content-between ">
                            <div className="counter-label">
                              <h4>New Questions</h4>
                            </div>
                            <div className="counter-filter text-end">
                              <p className='day-filter'>Last 3 days</p>
                            </div>
                          </div>
                          <div className="counter-val-main">
                            <p>{dashboardData && dashboardData.new_questions}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6  counter-cards">
                      <div className="inner-column">
                        <div className="counter-card-img card-inner-cols">
                          <img src={wallet} alt="" />
                        </div>
                        <div className="counter-card-content card-inner-cols">
                          <div className="card-head-main d-flex justify-content-between">
                            <div className="counter-label">
                              <h4>Wallet</h4>
                            </div>
                            <div className="counter-filter text-end">
                            <p className='day-filter'>Last 3 days</p>
                            </div>
                          </div>
                          <div className="counter-val-main">
                            <p>{dashboardData && dashboardData.wallet}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="dasboard_counter_main">
                  <div className="row counter-card-row">
                    <div className="col-lg-6  counter-cards">
                      <div className="inner-column">
                        <div className="counter-card-img card-inner-cols">
                          <img src={user} alt="" />
                        </div>
                        <div className="counter-card-content card-inner-cols">
                          <div className="card-head-main d-flex justify-content-between ">
                            <div className="counter-label">
                              <h4>Total Users</h4>
                            </div>
                          </div>
                          <div className="counter-val-main">
                            <p>{dashboardData && dashboardData.total_users}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6  counter-cards">
                      <div className="inner-column">
                        <div className="counter-card-img card-inner-cols">
                          <img src={number} alt="" />
                        </div>
                        <div className="counter-card-content card-inner-cols">
                          <div className="card-head-main d-flex justify-content-between ">
                            <div className="counter-label">
                              <h4>
                                New Users
                              </h4>
                            </div>
                            <div className="counter-filter text-end">
                              <p className='day-filter'>Last 3 days</p>
                            </div>
                          </div>
                          <div className="counter-val-main">
                            <p>{dashboardData && dashboardData.new_users}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </section>
          </section>
        </section>
      </main>
    </div>
  );
}
export default Dashboard;
