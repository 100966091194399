import Layout from './components/layout/layout.js';
import { BrowserRouter } from 'react-router-dom'
import { useState } from 'react';
import { onMessageListener } from './utils/notification.js';
import {Toast} from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';



function App() {

  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({title: '', body: ''});

  onMessageListener().then(payload => {
    setShow(true);
    setNotification({title: payload.notification.title, body: payload.notification.body})
    console.log(payload);
  }).catch(err => console.log('failed: ', err));
  
  return (
    <div className="App">
      <Toast onClose={() => setShow(false)} show={show} delay={3000} className = "notification-toast" autohide animation style={{
          position: 'absolute',
          top: 20,
          right: 20,
          minWidth: 200
        }}>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">{notification.title}</strong>
            <small>just now</small>
          </Toast.Header>
          <Toast.Body>{notification.body}</Toast.Body>
        </Toast>
      <BrowserRouter>
        
        <Layout />
      </BrowserRouter>
    </div>
    
  );
}

export default App;
