import { useHistory } from 'react-router-dom';
import logo from '../../../../assets/img/logo.png';
import apis from '../../../../utils/apis.js'
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {useState, useEffect} from 'react'
import { Button, Spinner } from 'react-bootstrap'

toast.configure()

function Forgot() {
  let email
  const history = useHistory()
  const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const [emailValid, setEmailValid] = useState({valid : true, message : ""})
  const [showLoader, setShowLoader] = useState(false)
  const[move, setMove] = useState(false)

  const handleSend = async (e) => {
    e.preventDefault()
    setShowLoader(true)
    if(!email.value.match(mailFormat)){
      if(email.value === null || email.value === ""){
        setEmailValid({valid : false, message : "E-Mail is Required."})
        setShowLoader(false)
      }
      else if(email.value.indexOf(" ") >= 0){
        setEmailValid({valid : false, message : "Please remove blankspace."})
        setShowLoader(false)
      }
      else{
        setEmailValid({valid : false, message : "Not a Valid E-Mail."})
        setShowLoader(false)
      }     
    }
    else{
      const body_data = {
        username : email.value
      }
      const response = await apis.post('/reset-password', body_data)
      if(response.data.success === true){
        toast.success(response.data.message, {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
        setMove(true)
        setShowLoader(false)
      }
      else{
        toast.error(response.data.message, {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
        setShowLoader(false)

      }
    }
  }

  useEffect(() => {
    if(move){
      history.push('/')
    }
  })

  const handleEmailChange = () => {
    setEmailValid({valid : true, message : ""})
  }

  return(
    <div>
        <div className="limiter">
          <div className="container-login100">
            <div className="wrap-login100">
              <form className="login100-form validate-form">
                <div >
                  <span className="login100-form-title"> Forgot your password? </span>
                  <p className="mt-3">Please enter your email address below. We will send you the link to reset password on provided  email address.</p>
                </div>
                <div className="wrap-input100 validate-input mt-5" data-validate="Valid email is required: ex@abc.xyz" >
                    <div className="label-input100 ">Email</div>
                    <div className="input">
                        <i className="icon-message"></i>
                        <input className="input100" type="text" name="email" placeholder="Enter your email address" ref = {(input) => email = input} onChange = {() => handleEmailChange()}/>
                    </div>
                    <span className="focus-input100"></span>
                    {!emailValid.valid ? <p id = "email-validation">{emailValid.message}</p> : <></>}
                </div>
                <div className="container-login100-form-btn mt-5">
                  {/* <Link to={'reset'} className="btn btn-xl w-100">Send</Link> */}
                  <Button className = 'btn btn-xl w-100' onClick={(e) => handleSend(e)}>
                    {showLoader ? 
                    <Spinner id = "spinner-btn"
                    as="span"
                    variant="light"
                    size= "lg"
                    role="status"
                    aria-hidden="true"
                    animation="grow">
                      <p id = "spinner-text">Loading...</p>
                    </Spinner> : "Send Link"}
                  </Button>
                </div>
              </form>
              <div className="login100-more" >
                <div className="logo m-4 ">
                    <img src={logo}  className="img-fluid" alt='img'/>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default Forgot;