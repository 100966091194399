import { Link, useHistory, useLocation } from "react-router-dom";
import logo from "../../../../assets/img/logo.png";
import newlogo2 from "../../../../assets/img/newlogo2.jpg";
import apis from '../../../../utils/apis.js'
import Form from 'react-bootstrap/Form'
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {useState, useEffect} from 'react'
import { Button } from "react-bootstrap";
import { getTokenFirebase } from "../../../../utils/notification";
import('../../../../assets/css/style.css')


toast.configure()

function Login() {
  const [auth, setAuth] = useState(false)
  const location = useLocation()
  console.log(location)
  let next_path
  if(location.state){
    next_path = location.state.url
  }
  else{
    next_path = '/dashboard'
  }
  const history = useHistory()
  const loginData = JSON.parse(localStorage.getItem("loginData"))
  let accessToken
  if(loginData){
    accessToken = loginData.accessToken
  }
  if(accessToken){
    history.push('/dashboard')
  }
  let email, pass
  
  const [passwordShown, setPasswordShown] = useState(false)
  const [emailValid, setEmailValid] = useState(true)
  const [emailMessage, setEmailMessage] = useState("")
  const [passValid, setPassValid] = useState(true)
  const [passMessage, setPassMessage] = useState("")
 
  const onLogin = async () => {
    if(email.value === null || email.value === "" || email.value.indexOf(" ") >= 0){
      setEmailMessage("E-Mail is Required.")
      setEmailValid(false)
    }

    if(pass.value === null || pass.value === ""){
      setPassMessage("Password is Required.")
      setPassValid(false)
    }
    else{
      const body_data = {
        username : email.value,
        password : pass.value
      }

    apis.post('/admin/login', body_data)
    .then((response) => {
      const data = response.data
      if(data.success === false){
        toast.error(data.message, {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 2})
      }
      else{
        setAuth(true)
        const loginData = {
          accessToken : response.data.data.token,
          screenName : response.data.data.screen_name,
          profileImage : response.data.data.profile_image,
          adminId : response.data.data.id
        }
        localStorage.setItem("loginData", JSON.stringify(loginData))
        toast.success(response.data.message, {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 1})

        getTokenFirebase()
        .then((token) => {
          if(token){
            console.log("fcm token : ", token)
            const config = {
              headers : {
                authorization : response.data.data.token
              }
            }
            const payload = {
              fcm_token:  token,
              device_os_type : "android"
            }
            apis.post("/update-fcm", payload, config)
            .then((res) => {
              localStorage.removeItem("fcmToken")
              console.log(res)
            })
            .catch((err) => {
              console.log(err)
            })
          }
          else{
            console.log("Could not fetch fcm token", token)
          }
        })
        .catch((error) => console.log("Could not fetch FCM token"))
      }
    })
    .catch((error) => toast.error("Invalid E-Mail.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 2}))
    }  
  }

  const handlePassShow = () => {
    setPasswordShown(!passwordShown)
  }

  const handleEmailChange = () => {
    setEmailValid(true)
  }

  const handlePassChange = () => {
    setPassValid(true)
  }

  useEffect(() => {
    if(auth === true){
      history.push(next_path)
    }
  }, [auth])
    return (
      <div className="limiter">
          <div className="container-login100">
            <div className="wrap-login100">
              <form className="login100-form validate-form">
                <span className="login100-form-title">Welcome to Admin Panel</span>
                <p>Please and enter your details below</p>
                <p>
                  to Login. 
                </p>

                <div
                  className="wrap-input100 validate-input mt-5"
                  data-validate="Valid email is required: ex@abc.xyz"
                >
                  <div className="label-input100 ">Email</div>
                  <div className="input">
                    <i className="icon-message"></i>
                    <Form.Control type="email" placeholder="Enter your email address" ref={(emailInput) => email = emailInput} onChange = {() => handleEmailChange()}/>
                    <p className="email-validation" id="email-validation">{emailValid ? "" : emailMessage}</p>
                  </div> 
                  <span className="focus-input100"></span>
                </div>

                <div
                  className="wrap-input100 validate-input mt-5"
                  data-validate="Password is required"
                >
                  <div className="label-input100 ">Password</div>
                  <div className="input">
                    <i className="icon-lock"></i>
                    <Form.Control
                      className="input100"
                      type= {passwordShown ? "text" : "password"}
                      name="pass"
                      placeholder="Enter your password"
                      ref={(passInput) => pass = passInput}
                      onChange = {() => handlePassChange()}
                    />
                    <p className="pass-validation" id="pass-validation">{passValid ? "" : passMessage}</p>
                    <i className={passwordShown ? "fa fa-eye" : "fa fa-eye-slash"} onClick={() => handlePassShow()}></i>
                  </div>

                  <span className="focus-input100"></span>
                </div>

                <div className="flex-sb-m w-full row">
                  <div className="contact100-form-checkbox col-6 d-flex align-items-center">
                    <input
                      className="input-checkbox100"
                      id="ckb1"
                      type="checkbox"
                      name="remember-me"
                    />
                    <label className="label-checkbox100 mx-1" id="ckb1" >
                      Remember me
                    </label>
                  </div>

                  <div className="col-6 text-end">
                    <Link to={'forgot'}  className="txt1">
                      Forgot Password?
                    </Link>
                  </div>
                </div>

                <div className="container-login100-form-btn mt-5">
                  <Button className="btn btn-xl w-100" onClick={() => onLogin()}>
                    Login
                  </Button>
                </div>
              </form>
              <div className="login100-more">
                <div className="logo m-4 ">
                  <img src={newlogo2} className="img-fluid" width={"150px"} height = {"60px"} alt="img" />
                </div>
              </div>
            </div>
          </div>
      </div>
    );
  }

  export default Login;