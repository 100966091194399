import React from 'react';
import {Bar} from 'react-chartjs-2';
import Chart from 'chart.js/auto';


function BarGraph(props){
    return (
        <div>
          <Bar
            data={props.graphData}
            options={{
              title:{
                display:true,
                text:'Average Rainfall per month',
                fontSize:20
              },
              legend:{
                display:true,
                position:'right'
              }
            }}
          />
        </div>
      );

}

export default BarGraph