import Sidebar from "../../sidebar";
import Header from "../../header";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import {useState, useEffect} from 'react' ;
import { useHistory } from 'react-router-dom';
import { toast } from "react-toastify";
import apis from "../../../../utils/apis";
import Timeout from "../../timeout";

toast.configure()

function Payment() {
  const loginData = JSON.parse(localStorage.getItem("loginData"))
  let accessToken, screen_name, profilePic, adminId
  if(loginData){
    accessToken = loginData.accessToken
    screen_name = loginData.screenName
    profilePic = loginData.profileImage
    adminId = loginData.adminId
  }

  const [tokenExpire, setTokenExpire] = useState(false)
  const [page, setPage] = useState(1)
  const [rows, setRows] = useState(50)
  const [paymentList, setPaymentList] = useState("")
  const [balancePoints, setBalancePoints] = useState("")
  const [status, setStatus] = useState("")

  const history = useHistory()

  const fetchPaymentList = (currentPage) => {

    const config = {
      headers : {
        authorization : `${accessToken}`
      }
    }

    const bodyData = {
      page : currentPage,
      size : rows
    }

    if(status !== ""){
      bodyData["status"] = status
    }

    apis.post('/admin/payment-list', bodyData, config)
    .then((response) => {
      if(response.data.success === true){
        setPaymentList(response.data)
      }
      else{
        toast.error("Could not get Payment List, Please try again later.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
      }
    })
    .catch((error) => {
      if(error.response.data.success ===false && error.response.data.message === 'jwt expired'){
        localStorage.removeItem("loginData")
        setTokenExpire(true)
      }
      else{
        toast.error("Could not get Payment List, Please try again later.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
      }
    })
  }

  const fetchBalancePoints = () => {
    const config = {
      headers : {
        authorization : `${accessToken}`
      }
    }

    apis.get('/admin/get-point', config)
    .then((response) => {
      if(response.data.success === true){
        setBalancePoints(response.data.data[0].balance_point)
      }
      else{
        toast.error("Could not fetch Points Balance.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
      }
    })
    .catch((error) => {
      if(error.response.data.success ===false && error.response.data.message === 'jwt expired'){
        localStorage.removeItem("loginData")
        setTokenExpire(true)
      }
      else{
        toast.error("Could not fetch Points Balance.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
      }
    })
  }

  const handleItemCountChange = (e) => {
    setRows(e.target.value)
    setPage(1)
  }

  const handlePgIncrease = () => {
    if(page !== paymentList.page_count){
      let nextpg = page + 1
      setPage(page + 1)
      fetchPaymentList(nextpg)
    }  
  }

  const handlePgDecrease = () => {
    if(page !== 1){
      let nextpg = page - 1
      setPage(page-1)
      fetchPaymentList(nextpg)
    }
  }

  const handlePaymentView = (targetId) => {
    history.push(`/payment/view-payment/${targetId}`)
  }

  const handleStatusChange = (e) => {
    setStatus(e.target.value)
    setPage(1)
  }

  useEffect(() => {
    if(!accessToken){
      history.push({
        pathname : "/",
        state : {
          url : '/payment'
        }
      })
      toast.error("Please login to continue.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 18}) 
    }
    else if(accessToken){
      fetchPaymentList(page)
      fetchBalancePoints()
    }
  }, [rows, status])



  return (
    tokenExpire ?
    <Timeout>
    </Timeout>
    :
    <>
      <main className="page_wrapper">
        <section className="dashboard_section d-flex">
          <Sidebar />

          <section className="dashboard_main_section">
            <Header title="Payment Management" screen_name = {screen_name} profilePic = {profilePic} accessToken = {accessToken}/>
            <section class="new-question-section questions-main-section dasboard_main_area pay-manage-area">
          <div class="add-new-question wallet-balance text-end">
            <div class="wallet-bal-field d-flex justify-content-end align-items-center">
              <div class="bal-label">
                <label>Points Balance</label>
              </div>
              <div class="wallet-field field-wrapper">
                <input type="text" value={balancePoints} disabled = {true}/>
              </div>
            </div>
            <div class="account-transfer-link">
              <Link to={'./transfer'}><span>Transfer to Account</span></Link>
            </div>
          </div>
          <div class="pay-manage-section">
            <div class="table-exp-main request-area">
              <div class="experts-table-filter select_container">
                <span class="filter-sort ">
                  <div class="dropholder">
                  <Form.Select
                          size="sm"
                          aria-label="Default select example"
                          onChange={(e) => handleStatusChange(e)}
                        >
                          <option value = "">Status</option>
                          <option value="SUCCESS">Successful</option>
                          <option value="FAILED">Failed</option>
                        </Form.Select>
                  </div>
                </span>
              </div>
              <div class="exp-table-container pay-manage-table table-responsive">
                <table class="table list_experts_table">
                  <thead>
                    <tr>
                      <th>
                        <div class="input-box"><input type="checkbox" name="" id=""/></div>
                      </th>
                      <th>
                        <span class="exp-name">USER</span>
                      </th>
                      <th>Mode</th>
                      <th>Points</th>
                      <th>Date and Time</th>
                      <th>Amount</th>
                      <th class="text-center">Status</th>
                      <th class="text-center"><span class="act-title"> Action</span></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      paymentList && paymentList.data.length > 0 ? paymentList.data.map((ele) => {
                        let createdAtdt = new Date(ele.created_at)
                        let createdAtDateBreak = createdAtdt.toString().split(" ")
                        let createdAt = `${createdAtDateBreak[2]}-${createdAtDateBreak[1]}-${createdAtDateBreak[3]} @ ${createdAtDateBreak[4]}`
                        return(
                          <tr key={ele.id}>
                            <td>
                              <div class="input-box"><input type="checkbox" name="" id=""/></div>
                            </td>
                            <td>{ele.User ? ele.User.name : "User Deleted"}</td>
                            <td>
                              <div class="exp-name-val">{ele.wallet === "paypal" ? "Paypal" : ""}</div>
                            </td>
                            <td>{ele.points}</td>
                            <td>{createdAt}</td>
                            <td>{`$ ${ele.amount}`}</td>
                            <td class="text-center">
                              {
                                ele.status === "SUCCESS" ? <span class="complteted-badge">Successful</span> : <span class="rejected-badge">Failed</span>
                              }
                            </td>
                            <td class="action-cell text-center" onClick={() => handlePaymentView(ele.id)}>
                              <p id = "view-button">View</p>
                            </td>
                          </tr>

                        )
                      })
                      :
                      <tr><td>No Data To Show.</td></tr>
                    }
                    
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="table-pagination-wrapper">
            <div className="table-pagination-con d-flex align-items-center justify-content-lg-end justify-content-center">
              <span className="pagination-label">Items per page:</span>
              <select className="select-page-number" name="" id="" onChange={(e) => handleItemCountChange(e)}>
                <option value= {50} selected>50</option>
                <option value= {100}>100</option>
                <option value= {150}>150</option>
              </select>
              <span className="pagination-label page-counter">{paymentList.page_count === 0 ? 0 : page} of {paymentList.page_count}</span>
              <span className="pagination-controls">
                <a className="prev" style={{cursor:'pointer'}} onClick={() => handlePgDecrease()}>
                  ‹
                </a>
                <a className="next" style={{cursor:'pointer'}} onClick={() => handlePgIncrease()}>
                  ›
                </a>
              </span>
            </div>
          </div>

        </section>
          </section>
        </section>
      </main>
    </>
  );
}
export default Payment;
