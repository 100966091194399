// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBBwWCwqtCRMm0vkQQnmb1PHG3oKxnpckk",
  authDomain: "andverturesportsapp.firebaseapp.com",
  projectId: "andverturesportsapp",
  storageBucket: "andverturesportsapp.appspot.com",
  messagingSenderId: "828465177905",
  appId: "1:828465177905:web:3d6b337f3fae0b0351fc10",
  measurementId: "G-MXZNX26CQ2"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp)
const analytics = getAnalytics(firebaseApp);

export const getTokenFirebase = (setTokenFound) => {
  return getToken(messaging, {vapidKey: 'BDjM1oSt3ZoeCDMXqvIgq0V3oVKrht6Xkp1be0AH3jDZC_e4HzKf8JGy_7ysP30IJxQGoAdPIUUuxN8FoC-3XXc'}).then((currentToken) => {
    if (currentToken) {
      return currentToken
    } else {
      return null
    }
  }).catch((err) => {
    throw err
  });
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});