import {useState, useEffect} from 'react'
import Header from '../../header';
import Sidebar from "../../sidebar";
import Arrow from '../../../../assets/img/arrowPng.png'
import apis from '../../../../utils/apis'
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Timeout from '../../timeout';

toast.configure()

const SpamView = () =>{
    const {response_id} = useParams()
    const history = useHistory()
    const loginData = JSON.parse(localStorage.getItem("loginData"))
    let accessToken, screen_name, profilePic
    if(loginData){
        accessToken = loginData.accessToken
        screen_name = loginData.screenName
        profilePic = loginData.profileImage
    }
    const [tokenExpire, setTokenExpire] = useState(false)
    const [responseData, setResponseData] = useState("")
    
    const fetchSpamDetails = () => {
        const config = {
            headers : {
                authorization : `${accessToken}`
            }
        }

        apis.get(`/admin/spam-view/${response_id}`, config)
        .then((response) => {
            console.log(response)
            if(response.data.success ===  true){
                setResponseData(response.data.data[0])
            }
            else{
                toast.error("Could not fetch Spam Details.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
            }
            
        })
        .catch((error) => {
            if(error.response.data.success ===false && error.response.data.message === 'jwt expired'){
                localStorage.removeItem("loginData")
                setTokenExpire(true)
            }
            else{
                toast.error("Could not fetch Spam Details.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
            }
        })
    }

    useEffect(() => {
        if(!accessToken){
            history.push({
                pathname : "/",
                state : {
                url : '/user-management'
                }
            })
            toast.error("Please login to continue.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
        }
        else if(accessToken){
            fetchSpamDetails()
        }
    }, [])


    return(
        tokenExpire ? 
        <Timeout>
        </Timeout>
        :
        <main className="page_wrapper">
           <section className="dashboard_section d-flex">
               <Sidebar />
               <section className="dashboard_main_section">
                     <Header title="Dashboard" screen_name = {screen_name} profilePic = {profilePic} accessToken = {accessToken}/>
                        <section className="dasboard_main_area">
                        <div className='pointViewWrapper'>
                        <div class="heading"><h3 class="txt-3" onClick={() => history.goBack()} style={{cursor:'pointer'}}> <img src={Arrow} alt="img" /> BACK</h3></div>  
                            <div className='row'>
                                <div className='col-md-2'>
                                        <label>question</label>
                                </div>
                                <div className='col-md-10'>
                                    <div className='pv-question'>
                                        <p>
                                        {responseData.question_text}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-2'>
                                        <label>Response</label>
                                </div>
                                <div className='col-md-10'>
                                  {/* {listap(data =>{
                                    return(
                                    <div>
                                        <p>
                                            Response 1                        
                                        </p>
                                        <p className='resAnswer'>
                                        Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Aenean Euismod Bibendum Laoreet. Proin Gravida Dolor Sit Amet Lacus Accumsan Et Viverra Justo Commodo. Proin Sodales Pulvinar Tempor. Cum Sociis Natoque Penatibus Et Magnis Dis Parturient Montes, Nascetur Ridiculus Mus. Nam Fermentum, Nulla Luctus Pharetra Vulputate, Felis Tellus Mollis Orci, Sed Rhoncus Sapien Nunc Eget Odio. Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit.
                                        </p>
                                    </div>
                                    )
                                  })  
                                  } */}
                                  <div><p>{responseData.response_text}</p></div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-2'>
                                        <label>Spam By User</label>
                                </div>
                                <div className='col-md-10'>
                                    <div>
                                        <p>
                                        {responseData.spam_by_full_name}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-2'>
                                    <label>Status</label>
                                </div>
                                <div className='col-md-10'>
                                    <div>
                                        <p>
                                            {responseData.question_status === "closed" ? "Closed" : "Open"}                     
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </section>
                </section>
            </section>
        </main>
    )
}

export default SpamView