import React,{useEffect, useState} from 'react'
import Modal from "react-bootstrap/Modal";
import Header from '../../header';
import Sidebar from "../../sidebar";
import Arrow from '../../../../assets/img/arrowPng.png'
import { useParams } from "react-router-dom";
import apis from '../../../../utils/apis';
import { toast } from "react-toastify";
import { useHistory, useLocation} from 'react-router-dom';
import Timeout from "../../timeout"

toast.configure()


const QuestionView = () =>{
    let question_text
    const [show, setShow] = useState(false);
    const {question_id} = useParams()
    const history = useHistory()
    const location = useLocation()
    let appliedFiltersQuestions
    if(Object.keys(location.state).length === 0){
        appliedFiltersQuestions = null
    }
    else{
        appliedFiltersQuestions = location.state
    }
    const loginData = JSON.parse(localStorage.getItem("loginData"))
    let accessToken, screen_name, profilePic
    if(loginData){
      accessToken = loginData.accessToken
      screen_name = loginData.screenName
      profilePic = loginData.profileImage
    }
    const [updateCheck, setUpdateCheck] = useState(false)
    const [tokenExpire, setTokenExpire] = useState(false)
    const [questionDetails, setQuestionDetails] = useState("")
    const [initialQuestion, setInitialQuestion] = useState("")

    const handleClose = () => setShow(false);

    const fetchQuestionDetails = () => {
        const config = {
            headers : {
                authorization : `${accessToken}`
            }
        }
        apis.get(`/admin/question-details/${question_id}`, config)
        .then((response)=> {
            if(response.data.success === true){
                setQuestionDetails(response.data.data[0])
                setInitialQuestion(response.data.data[0].question_text)
            }
            else{
                toast.error("Could not get Question Details. Please try again later", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
            }
        })
        .catch((error)=> {
            if(error.response.data.success ===false && error.response.data.message === 'jwt expired'){
                localStorage.removeItem("loginData")
                setTokenExpire(true)
            }
            else{
                toast.error("Could not get Question Details. Please try again later", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
            }
        })
    }

    const handleQuestionChange = () => {
        setInitialQuestion(question_text.value)
    }

    const dataChangeCheck = (e) => {
        if(questionDetails.question_text !== initialQuestion){
          setUpdateCheck(false)
        }
        else{
          setUpdateCheck(true)
        }    
    }

    const handleChangeSubmit = () => {
        const config = {
            headers : {
                authorization : `${accessToken}`
            }
        }

        const requestBody = {
            question_text : initialQuestion
        }

        apis.patch(`/admin/update-question/${question_id}`, requestBody, config)
        .then((response) => {
            setUpdateCheck(true)
            if(response.data.success === true){
                toast.success("Question Updated Successfully", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
                fetchQuestionDetails()
            }
            else{
                toast.error("Could not Update Question. Please try again later", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
                fetchQuestionDetails()
            }
        })
        .catch((error) => {
            if(error.response.data.success ===false && error.response.data.message === 'jwt expired'){
                localStorage.removeItem("loginData")
                setTokenExpire(true)
            }
            else{
                toast.error("Could not Update Question. Please try again later", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
            }
        })
    }

    const handleCloseQuestion = () => {
        setShow(false)
        const config = {
            headers : {
                authorization : `${accessToken}`
            }
        }

        const requestBody = {
            question_id : question_id,
            question_status : "closed"
        }

        apis.patch('/admin/update-question-status', requestBody, config)
        .then((response) => {
            if(response.data.success === true){
                toast.success("Question Closed Successfully", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
                fetchQuestionDetails()
            }
            else{
                toast.error("Could not Close Question. Please try again later", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
                fetchQuestionDetails()
            }
        })
        .catch((error) => {
            if(error.response.data.success ===false && error.response.data.message === 'jwt expired'){
                localStorage.removeItem("loginData")
                setTokenExpire(true)
            }
            else{
                toast.error("Could not Update Question. Please try again later", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
            }
        })
    }

    const handleBack = () => {
        history.push({
            pathname : '/question',
            state : appliedFiltersQuestions
        })
    }
    

    useEffect(() => {
        if(!accessToken){
            history.push({
              pathname : "/",
              state : {
                url : '/question'
              }
             })
             toast.error("Please login to continue.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
          }
          else if(accessToken){
            fetchQuestionDetails()
          }
    }, [])

    useEffect(() => {
        dataChangeCheck()
      }, [initialQuestion])

    let createdAt
    if(questionDetails.createdAt){
        let createdAtdt = new Date(questionDetails.createdAt)
        let createdAtDateBreak = createdAtdt.toString().split(" ")
        createdAt = `${createdAtDateBreak[2]}-${createdAtDateBreak[1]}-${createdAtDateBreak[3]}`
    }


    return(
        tokenExpire ?
        <Timeout>
        </Timeout> 
        :
        <main className="page_wrapper">
           <section className="dashboard_section d-flex">
               <Sidebar />
               <section className="dashboard_main_section">
                     <Header title="View/Edit Question" screen_name = {screen_name} profilePic = {profilePic} accessToken = {accessToken}/>
                        <section className="dasboard_main_area">
                        <div className='pointViewWrapper'>
                        <div class="heading" style={{cursor:'pointer'}} onClick={() => handleBack()}><h3 class="txt-3"> <img src={Arrow} alt="img" /> BACK </h3></div>  
                            <div className='row'>
                                <div className='col-md-2'>
                                        <label>Question</label>
                                </div>
                                <div className='col-md-10'>
                                    <div className='pv-question'>
                                       <textarea 
                                       rows="5" 
                                       value={initialQuestion} 
                                       className='editableArea' 
                                       disabled = {questionDetails.response_count > 0 || questionDetails.question_status === "closed" ? true : false}
                                       ref = {(questionInput) => question_text = questionInput}
                                       onChange = {() => handleQuestionChange()}>
                                       </textarea>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-2'>
                                        <label>Username</label>
                                </div>
                                <div className='col-md-10'>
                                    <div>
                                        <p>{questionDetails.username}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-2'>
                                        <label>Location</label>
                                </div>
                                <div className='col-md-10'>
                                    <div>
                                        <p>{questionDetails.location_name}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-2'>
                                        <label>Total Response</label>
                                </div>
                                <div className='col-md-10'>
                                    <div>
                                        <p>{questionDetails.response_count}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-2'>
                                    <label>Date</label>
                                </div>
                                <div className='col-md-10'>
                                    <div>
                                        <p>{createdAt}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-2'>
                                    <label>Status</label>
                                </div>
                                <div className='col-md-10'>
                                    <div>
                                        <p>{questionDetails.question_status === "open" ? "Open" : "Close"}</p>
                                    </div>
                                </div>
                            </div>
                            {questionDetails.question_status === "open" ? 
                            <div className='row closeControl'>
                                <div className='col-md-2'>
                                    <label></label>
                                </div>
                                <div className='col-md-10'>
                                    <div>
                                       <button onClick={() => setShow(true)}>Do you want to close the question ?</button>
                                    </div>
                                </div>
                            </div> : <></>}
                            <div className="submit-wrapper user-form-submit submit-main row mt-4">
                                <button className="btn-outline bg-white" onClick={() => handleBack()}>Cancel</button>
                                <button className="btn btn-xl" disabled = {updateCheck} onClick = {() => handleChangeSubmit()}>Apply</button>
                            </div>
                        </div>
                        </section>
                        <Modal centered show={show} onHide={handleClose}>
                            <Modal.Body>
                            <div className="px-4 mb-3 admin">
                                <Modal.Header closeButton></Modal.Header>
                                <div className="text-center p-5">
                                <p className="delete mb-4">Do you want to close the question?</p>
                                <div className="" id="delete-modal">
                                    <button className="btn btn-outline me-3" onClick={() => setShow(false)}>No</button>
                                    <button className="btn btn-xl text-center h-53" onClick={() => handleCloseQuestion()}>Yes</button>
                                </div>
                                </div>
                            </div>
                            </Modal.Body>
                        </Modal>
                </section>
            </section>
        </main>
    )
}

export default QuestionView