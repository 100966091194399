import React, { useState, useEffect } from "react";
import Sidebar from "../sidebar";
import Header from "../header";
import { time_ago } from "../../../utils/timeFunc";
import apis from "../../../utils/apis";
import ImageHelper from "../../../utils/imagehelper";
import { toast } from "react-toastify";

toast.configure()

export default function NotificationsList () {
    const loginData = JSON.parse(localStorage.getItem("loginData"))
    let accessToken, screen_name, profilePic
    if(loginData){
        accessToken = loginData.accessToken
        screen_name = loginData.screenName
        profilePic = loginData.profileImage
    }
    const [notificationsList, setNotificationsList] = useState()
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(5);
    const [pagination, setPagination] = useState("");

    useEffect(() => {
        const payload = {
            page : page,
            size : size
        }

        const config = {
            headers : {
                authorization : accessToken
            }
        }
        apis.post("notification-list", payload, config)
        .then((res) => {
            if(res.data.success === true){
                setNotificationsList(res.data.data)
                setPagination(res.data.page_count)
            }
            else{
                toast.error("Could not fetch notification list. Please try again later.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 1})
            }
        })
        .catch((error)=>{
            toast.error("Could not fetch notification list. Please try again later.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 1})
        })
        
      }, [page,size]);

      useEffect(() => {
        if (page > pagination) {
          setPage(1);
        }
      }, [pagination]);

      const handlePgIncrease = () => {
        if(page !== pagination){
          let nextpg = page + 1
          setPage(page + 1)
        }  
      }
    
      const handlePgDecrease = () => {
        if(page !== 1){
          setPage(page-1)
        }
      }

      const handleItemCountChange = (e) => {
        setSize(e.target.value)
        setPage(1)
      }

    return (
      <div>
        <main className="page_wrapper">
          <section className="dashboard_section d-flex">
            <Sidebar/>

            <section className="dashboard_main_section">
              <Header title='Notifications' screen_name = {screen_name} profilePic = {profilePic} accessToken = {accessToken}/>
              <section className="dasboard_main_area list-experts-area questions-main-section points">
              <div className="points-counter d-flex justify-content-between align-items-center">
              <div style={{width:"100%" }} className="notification-box">
                     
                      <div className="box">
                      {notificationsList && notificationsList.map((item, index) => {
                      return (
                        <div className="user">
                            <div className={item.read_flag === true ? "" : "active"}></div>
                            <div className="img">
                            <ImageHelper imageURL={item.user_profile_image} width="40px" height="40px" token = {accessToken}/>
                            </div>
                            <div className="content">
                            
                            <div class="hover-item">
                                    <p>
                                        <b>{item.notification_title} </b>
                                    </p>
                                    <p>{item.notification_body} </p>
                            </div>

                            <div className="day">
                                <span>{time_ago(new Date(item?.createdAt))} at at{" "} {new Date(item?.createdAt)
                                    .toLocaleTimeString('en-US', {
                                    hour:"2-digit" , minute: "2-digit"
                                    }).replace(/ /g, ' ')}</span>
                            </div>
                            </div>
                      </div>
                    )
                      })}
                      </div>
                </div>
                </div>
                <div className="table-pagination-wrapper">
                <div className="table-pagination-con d-flex align-items-center justify-content-lg-end justify-content-center">
                  <span className="pagination-label">Items per page:</span>
                  <select className="select-page-number" name="" id="" value={size} onChange={(e) => handleItemCountChange(e)}>
                    <option value= {5}>5</option>
                    <option value= {10}>10</option>
                    <option value= {15}>15</option>
                  </select>
                  <span className="pagination-label page-counter">{pagination === 0 ? 0 : page} of {pagination}</span>
                  <span className="pagination-controls">
                    <a className="prev" style={{cursor:'pointer'}} onClick={() => handlePgDecrease()}>
                      ‹
                    </a>
                    <a className="next" style={{cursor:'pointer'}} onClick={() => handlePgIncrease()}>
                      ›
                    </a>
                  </span>
                </div>
              </div>
                    
              </section>
            </section>
          </section>
        </main>
      </div>
    );
  }
