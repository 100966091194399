import Sidebar from "../../sidebar";
import Header from "../../header";
import Form from "react-bootstrap/Form";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

function Spam() {
  return (
    <>
      <main className="page_wrapper">
        <section className="dashboard_section d-flex">
          <Sidebar />

          <section className="dashboard_main_section">
            <Header title="Badge Management" />
            <section class="dasboard_main_area admin badge-management ">
              <div className="dashboard_container">
                <div className="back-btn-main mb-3">
                  <KeyboardBackspaceIcon /> Spam effect on Upvotes
                </div>
              </div>
              <div class="d-flex align-items-center tab-container mt-5">
                <div class="input-box">
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Control type="text" placeholder="100" />
                  </Form.Group>
                </div>
                <div class="label-wrapper plus-symbol">
                  <label className="px-3">Spam</label>
                  <span className="px-3"><b>=</b></span>
                </div>
                <div class="input-box w-110">
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Control type="text" placeholder="-5" />
                  </Form.Group>
                </div>
                <div class="label-wrapper plus-symbol">
                  <label className="px-3">Upvotes</label>
                </div>
                
              </div>
              <div className="effect">
                  <button className="btn btn-outline me-4">Save</button>
                  <button className="btn btn-xl p-0">Cancel</button>
              </div>
            </section>
          </section>
        </section>
      </main>
    </>
  );
}
export default Spam;
