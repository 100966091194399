import { Link, useParams } from "react-router-dom";
import logo from "../../../../assets/img/logo.png";
import apis from '../../../../utils/apis.js'
import green from "../../../../assets/img/green.png";
import { Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Modal from "react-bootstrap/Modal";
import { useState } from "react";

toast.configure()

function Reset() {
  const {token} = useParams()
  let pass1, pass2
  const pass_format = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
  const [show, setShow] = useState(false)
  const [passwordShown1, setPasswordShown1] = useState(false)
  const [passwordShown2, setPasswordShown2] = useState(false)
  const [passValid1, setPassValid1] = useState({valid : true, message : ""})
  const [passValid2, setPassValid2] = useState({valid : true, message : ""})

  const handleSubmit = async () => {
    
    if(!pass1.value.match(pass_format) || !pass2.value.match(pass_format)){
      if(!pass1.value.match(pass_format)){
        setPassValid1({valid : false, message : "Password must be atleast 8 characters containing one uppercase, lowercase, number and special character"})
      }
      if(!pass2.value.match(pass_format)){
        setPassValid2({valid : false, message : "Password must be atleast 8 characters containing one uppercase, lowercase, number and special character"})
      } 
    }
    else if(pass1.value != pass2.value){
      setPassValid2({valid : false, message :"Password does not match."})
    }
    else{
      const body_data = {
        new_password : pass2.value
      }

      try{
        const response = await apis.patch('/change-password', body_data, {
          headers : {
            authorization : `${token}`
          }
        })
  
        if(response.data.success === true){
          setShow(true)
        }
        else{
          toast.error(response.data.message, {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
        }

      }
      catch(e){
        toast.error("Something Went Wrong. Please Try Again.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
      }
    }
  }

  const handleClose = () => {
    setShow(false)
  }

  const handlePassShow1 = () => {
    setPasswordShown1(!passwordShown1)
  }

  const handlePassShow2 = () => {
    setPasswordShown2(!passwordShown2)
  }

  const handlePassChange1 = () => {{
    setPassValid1({valid : true, message : ""})
  }}

  const handlePassChange2 = () => {{
    setPassValid2({valid : true, message : ""})
  }}

  return (
    <div>
      <div className="limiter">
        <div className="container-login100">
          <div className="wrap-login100">
            <form className="login100-form validate-form">
              <div>
                <span className="login100-form-title">Reset your password</span>
                <p className="mt-3">Please reset your password below</p>
              </div>

              <div
                className="wrap-input100 validate-input mt-5"
                data-validate="Valid email is required: ex@abc.xyz"
              >
                
                <div className="input" id="reset-pass-2">
                  <div className="label-input100 ">Password</div>
                  <i className="icon-lock"></i>
                  <Form.Control
                    className="input100"
                    type= {passwordShown1 ? "text" : "password"}
                    name="pass"
                    placeholder="Enter your password"
                    ref={(passInput1) => pass1 = passInput1}
                    onChange = {() => handlePassChange1()}
                  />
                  <i className= {passwordShown1 ? "fa fa-eye" : "fa fa-eye-slash"} onClick = {() => handlePassShow1()}></i>
                </div>
                {!passValid1.valid ? <p id="pass-validation">{passValid1.message}</p> : <></>}
                
                <div className="input" id="reset-pass-2">
                  <div className="label-input100 mt-4 ">Re-enter Password</div>
                  <i className="icon-lock"></i>
                  <Form.Control
                    className="input100"
                    type= {passwordShown2 ? "text" : "password"}
                    name="pass"
                    placeholder="Re-enter your password"
                    ref={(passInput2) => pass2 = passInput2}
                    onChange = {() => handlePassChange2()}
                  />
                  <i className= {passwordShown2 ? "fa fa-eye" : "fa fa-eye-slash"} onClick = {() => handlePassShow2()}></i>
                </div>
                {!passValid2.valid ? <p id="pass-validation">{passValid2.message}</p> : <></>}
              </div>
              <div className="container-login100-form-btn mt-5">
                <Button className="btn btn-xl w-100" onClick={() => handleSubmit()}>
                  Confirm
                </Button>
              </div>
              <div className="text-center mt-4">
                <Link to={"/"} className="back">
                  Back to Login
                </Link>
              </div>
            </form>

            <div className="login100-more">
              <div className="logo m-4 ">
                <img src={logo} className="img-fluid" alt="img" />
              </div>
            </div>
          </div>
          <Modal centered show={show} onHide={() => handleClose()}>
            <Modal.Body>
              <div className="text-center p-4">
                <img src={green} alt="img" />
                <p className="mt-3">
                  <strong>Password updated successfully!<br />Please login again</strong>
                </p>
                <Link to={"/"} className="btn btn-xl w-100 mt-5">OK</Link>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default Reset;
