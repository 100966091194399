import React, { Component } from "react";
import profile from "../../assets/img/profile-img.png";
import bell from "../../assets/img/bell-icon.svg";
import userdp from "../../assets/img/user.png"
import { Link } from "react-router-dom";
import Sidebar from "../layout/sidebar";
import apis from '../../utils/apis'
import { time_ago } from "../../utils/timeFunc";
import ImageHelper from "../../utils/imagehelper";
export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      close:true,
      notify:true,
      admin:true,
      decrypt : false,
      imgSrc : "",
      notificationList : [],
      unreadCount : 0,
      markRead : false
    };
  }

  fetchInitial(){
    this.setState({
      markRead : !this.state.markRead
    })
    const accessToken = this.props.accessToken
    const config = {
      headers : {
        authorization : accessToken
      }
    }

    const payload = {
      page : 1,
      size : 5
    }

    apis.get("/count-unread-notifications", config)
    .then((res) => {
      if(res.data.success === true){
        this.setState({
          unreadCount : res.data.data
        })
      }
      else{
        console.log("Could not fetch unread count")
      }
    })
    .catch((error) => {
      console.log("Could not fetch unread count", error)
    })

    apis.post("/notification-list", payload, config)
    .then((res) => {
      if(res.data.success === true){
        this.setState({
          notificationList : res.data.data
        })
      }
      else{
        console.log("Could not fetch notification list.")
      }
    })
    .catch((error) => {
      console.log(error)
    })
  }

  componentDidMount(){
    const accessToken = this.props.accessToken
    const config = {
      headers : {
        authorization : accessToken
      }
    }

    const payload = {
      page : 1,
      size : 5
    }

    apis.get("/count-unread-notifications", config)
    .then((res) => {
      if(res.data.success === true){
        this.setState({
          unreadCount : res.data.data
        })
      }
      else{
        console.log("Could not fetch unread count")
      }
    })
    .catch((error) => {
      console.log("Could not fetch unread count", error)
    })

    apis.post("/notification-list", payload, config)
    .then((res) => {
      if(res.data.success === true){
        this.setState({
          notificationList : res.data.data
        })
      }
      else{
        console.log("Could not fetch notification list.")
      }
    })
    .catch((error) => {
      console.log(error)
    })
  }
  menuToggle = () => {
    this.setState ({
      close : !this.state.close,
    });
  }
  openDiv = () => {
    this.setState ({
      notify: !this.state.notify,
    });
  };
  admin = () => {
    this.setState({
      admin: !this.state.admin,
    });
  };

  handleLogout = () => {
    localStorage.removeItem("loginData")
  }

  fetchWithAuth = (imgurl, token) => {
    const headers = new Headers();
    headers.set('authorization', `${token}`);
    return fetch(imgurl, {headers})
  }

  displayProtectedImage = async (imgurl, token) => {
    const response = await this.fetchWithAuth(imgurl, token)
    const blob = await response.blob();
    const objectUrl = URL.createObjectURL(blob);
    return objectUrl  
  }

  handleMarkRead = () => {
    const accessToken = this.props.accessToken

    const config = {
      headers : {
        authorization : accessToken
      }
    }

    const payload = {
      read_flag : true,
      update_all : true
    }

    apis.post("/notification-read-update", payload, config)
    .then((res)=> {
      if(res.data.success === true){
        this.setState({
          markRead: !this.state.markRead,
        });
      }
      else{
        console.log("Could not mark notification as read.")
      }
    })
    .catch((error) => {
      console.log("Could not mark notification as read.")
    })
  }

  render() {
    const { close } = this.state;
    const { notify } = this.state;
    const { admin } = this.state;
    const {notificationList} = this.state
    const {unreadCount} = this.state
  
    if(this.state.decrypt === false){
      if(this.props.profilePic === null){
        this.setState({
          decrypt : true,
          imgSrc: userdp
        })
      }
      else{
        const fetchedImage = this.displayProtectedImage(this.props.profilePic, this.props.accessToken)
        fetchedImage.then((response) => {
          this.setState({
            decrypt : true,
            imgSrc: response
          })
          
        })
        .catch((error) =>{
          this.setState({
            decrypt : true,
            imgSrc: userdp
          })
        })
      }
    }

    if(this.state.markRead === true){
      this.fetchInitial()
    }
    

    return (
      <div>
   
          <div className={close ? "side_bar_wrapper" : "side-bar-active"}>
            <Sidebar/>
            </div>
              <header className="dashboard_header">
                <nav className="header_nav d-flex justify-content-between align-items-center">
                  <div className="dashboard_title d-flex align-items-center">
                    <h1>{this.props.title}</h1>
                  </div>
                  <div className="header_menu_main">
                    <ul className="header_menu d-flex align-items-center">
                      <li className="header_menu_item">
                        <div className="bell-icon">
                          <p className={unreadCount === 0 ? "" : "red-notification"}>{unreadCount === 0 ? "" : unreadCount}</p>
                          <span className="new-notification" onClick={() => this.openDiv()}>
                          <img src={bell} alt="" />
                        </span>
                        <div className={notify ? "notification-close" : "notification-open"}>
                          <div className="notification-box">
                            <div className="d-flex justify-content-between top">
                              <div className="heading">
                              <h5><a href="/notification-list">See all</a></h5>
                            </div>
                            <div className="read">
                              <h5 onClick={unreadCount === 0 ? () => console.log("No notification") : () => this.handleMarkRead()}>Mark all as read <i class="far fa-check-circle" ></i></h5>
                            </div>
                            </div>
                            <div className="box">

                              {
                                notificationList && notificationList.length > 0 ?
                                notificationList.map((ele) => {
                                  return(
                                    <div className="user">
                                      <div className= {ele.read_flag === true ? "" : "active"}></div>
                                        <div className="img">
                                        <ImageHelper
                                          imageURL={ele.user_profile_image}
                                          width="40px"
                                          height="40px"
                                          token = {this.props.accessToken}
                                        />
                                        </div>
                                        <div className="content">
                                          <p>{ele.notification_body}</p>
                                          <div className="day">
                                            <span>
                                            {time_ago(new Date(ele.createdAt))} at{" "}
                                              {new Date(ele.createdAt)
                                                .toLocaleTimeString("en-US", {
                                                  hour: "2-digit",
                                                  minute: "2-digit",
                                                })
                                                .replace(/ /g, " ")}
                                            </span>
                                          </div>
                                        </div>
                                    </div>
                                  )
                                }) : <>No notification to show</>
                              }
                            </div>

                            
                          </div>
                        </div>
                        </div>
                        
                      </li>
                      <li className="header_menu_item">
                        <span onClick={() => this.admin()}>
                          <span className="profile-area-row d-flex align-items-center">
                            <span className="user-img">
                              <img src={this.state.imgSrc} alt="" />
                            </span>
                            <span className="user-name">
                              <p className="name">{this.props.screen_name} <span><i className="fa fa-caret-down"></i></span></p>
                            </span>
                          </span>
                        </span>
                        <span className={admin ? "d-none" : "d-block"}>
                            <div className="admin">
                                <ul>
                                    <li><Link to={'/account'}>My Profile </Link></li>
                                    <li><Link to={'/'} onClick = {() => this.handleLogout()}>Logout</Link></li>
                                </ul>
                            </div>
                        </span>
                      </li>
                    </ul>
                  </div>
                </nav>
              </header>

      </div>
    );
  }
}
