import Sidebar from "../../sidebar";
import Header from "../../header";
import Form from "react-bootstrap/Form";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Link, useHistory } from "react-router-dom";
import {useState, useEffect} from 'react' ; 
import { toast } from "react-toastify";
import apis from "../../../../utils/apis";
import Timeout from "../../timeout";
import {Spinner} from "react-bootstrap"

toast.configure()

function Transfer() {
  const history = useHistory()
  const inputRegEx = /^[0-9\b]+$/
  const loginData = JSON.parse(localStorage.getItem("loginData"))
  let accessToken, screen_name, profilePic
  if(loginData){
    accessToken = loginData.accessToken
    screen_name = loginData.screenName
    profilePic = loginData.profileImage
  }


  const [tokenExpire, setTokenExpire] = useState(false)
  const [errMessage, setErrMessage] = useState("")
  const [balancePoints, setBalancePoints] = useState("")
  const [transferAmount, setTransferAmount] = useState(null)
  const [redeemPoints, setRedeemPoints] = useState("")
  const [updateCheck, setUpdateCheck] = useState(true)
  const [showLoader, setShowLoader] = useState(false)
  const [mode, setMode] = useState("")

  const fetchBalancePoints = () => {
    const config = {
      headers : {
        authorization : `${accessToken}`
      }
    }

    apis.get('/admin/get-point', config)
    .then((response) => {
      if(response.data.success === true){
        setBalancePoints(response.data.data[0])
      }
      else{
        toast.error("Could not fetch Points Balance.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
      }
    })
    .catch((error) => {
      if(error.response.data.success ===false && error.response.data.message === 'jwt expired'){
        localStorage.removeItem("loginData")
        setTokenExpire(true)
      }
      else{
        toast.error("Could not fetch Points Balance.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
      }
    })
  }

  const handleAmtChange = (e) => {
    if(inputRegEx.test(e.target.value) || e.target.value === ""){
      setRedeemPoints(e.target.value)
      const amt = parseInt(balancePoints.amount)
      const pts = parseInt(balancePoints.point)
      const transfer = (amt/pts) * parseInt(e.target.value)
      if(e.target.value > balancePoints.balance_point){
        setErrMessage("Points to be redeemed can't be greater than balance points.")
        setTransferAmount(null)
      }
      else{
        if(e.target.value === ""){
          setTransferAmount(null)
        }
        else{
          setTransferAmount(transfer)
        }
        setErrMessage("")
      }
    }
  }

  const dataChangeCheck = () => {
    if(redeemPoints !== "" && errMessage === "" && mode !== ""){
      setUpdateCheck(false)
    }
    else{
      setUpdateCheck(true)
    }
  }

  const handleModeSelect = (e) => {
    setMode(e.target.value)
  }
  const handleTransfer = () => {
    const finalPts = parseInt(redeemPoints)
    setShowLoader(true)
    setUpdateCheck(true)
    const config = {
      headers : {
        authorization : `${accessToken}`
      }
    }
    const bodyData = {
      points : finalPts,
    }

  
    apis.post('/admin/redeem-points', bodyData, config)
    .then((response) => {
      setShowLoader(false)
      fetchBalancePoints()
      setRedeemPoints("")
      setTransferAmount(null)
      setMode("")
      if(response.data.success === true){
        toast.success("Points Redeemed Successfully", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
      }
      else{
        toast.error(response.data.message, {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
      }
    })
    .catch((error) => {
      setShowLoader(false)
      fetchBalancePoints()
      setRedeemPoints("")
      setTransferAmount(null)
      setMode("")
      if(error.response.data.success ===false && error.response.data.message === 'jwt expired'){
        localStorage.removeItem("loginData")
        setTokenExpire(true)
      }
      else{
        toast.error(error.response.data.message, {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
      }
    })
  }

  useEffect(() => {
    if(!accessToken){
      history.push({
        pathname : "/",
        state : {
          url : '/payment'
        }
      })
      toast.error("Please login to continue.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 18}) 
    }
    else if(accessToken){
      fetchBalancePoints()
    }
  }, [])

  useEffect(() => {
    dataChangeCheck()
  }, [redeemPoints, mode])

  return (
    tokenExpire === true ?
    <Timeout>
    </Timeout>
    :
    <>
      <main className="page_wrapper">
        <section className="dashboard_section d-flex">
          <Sidebar />

          <section className="dashboard_main_section">
            <Header title="Transfer Amount" screen_name = {screen_name} profilePic = {profilePic} accessToken = {accessToken}/>

            <section class=" dasboard_main_area pay-manage-area points-area acc-trnsfer-section">
              <div class="dashboard_container">
                <div class="back-btn-main">
                  <Link to={'./payment'}><KeyboardBackspaceIcon /> BACK</Link>
                </div>
                <div className="admin mt-4">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="inner-column">
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label className="label-input100 ">
                            Point Balance
                            </Form.Label>
                            <Form.Control type="text" placeholder="Admin Points Balance" disabled = {true} value = {balancePoints.balance_point} />
                          </Form.Group>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="inner-column">
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label className="label-input100 ">
                            {`Points to Redeem`}
                            </Form.Label>
                            <Form.Control type="text" placeholder="Enter Points" value = {redeemPoints} onChange = {(e) => handleAmtChange(e)}/>
                            {errMessage === "" ? <></> : <Form.Label className="error-label">{errMessage}</Form.Label>}
                            <Form.Text>{`Amount to be transferred : ${transferAmount === null ? "N/A" : "$ " + transferAmount} (Based On Points conversion rate)`}</Form.Text>

                          </Form.Group>
                        </div>
                      </div>
                      <div className="col-12 mt-3">
                        <Form.Label className="label-input100">Mode of Payment</Form.Label>
                        <Form.Select
                          size="sm"
                          aria-label="Default select example"
                          value = {mode}
                          onChange = {(e) => handleModeSelect(e)}
                        >
                          <option value = "">Mode of Payment</option>
                          <option value="1">Paypal</option>
                        </Form.Select>
                      </div>
                      {/* <div className="col-12 my-3">
                       <p className="txt-4">Account Details</p>
                      </div>
                      <div className="col-12">
                        <div className="inner-column">
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label className="label-input100 ">
                            Acc Number
                            </Form.Label>
                            <Form.Control type="text" placeholder="" />
                          </Form.Group>
                        </div>
                      </div>
                      <div className="col-md-6 mt-3">
                        <div className="inner-column">
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label className="label-input100 ">
                              Name
                            </Form.Label>
                            <Form.Control type="text" placeholder="" />
                          </Form.Group>
                        </div>
                      </div>
                      <div className="col-md-6 mt-3">
                        <div className="inner-column">
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label className="label-input100 ">
                              IFSC
                            </Form.Label>
                            <Form.Control type="text" placeholder="" />
                          </Form.Group>
                        </div>
                      </div> */}
                      <div className="submit-wrapper user-form-submit submit-main row mt-4">
                        <button className="btn btn-xl" disabled = {updateCheck} onClick = {() => handleTransfer()}>{showLoader ? 
                    <Spinner id = "spinner-btn-2"
                    as="span"
                    variant="light"
                    size= "lg"
                    role="status"
                    aria-hidden="true"
                    animation="grow">
                      <p id = "spinner-text-3">Wait...</p>
                    </Spinner> : "Transfer"}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </section>
        </section>
      </main>
    </>
  );
}
export default Transfer;
