import { useHistory, useParams } from "react-router-dom";
import logo from "../../../../assets/img/logo.png";
import apis from '../../../../utils/apis'
import {useState, useEffect} from 'react'
import { toast } from "react-toastify";

toast.configure()

function PaymentSuccess() {
    const history = useHistory()
    const {user_id, amount, points} = useParams()
    const id = window.location.search.split("?")[1];
    const dataArray = id.split("&")
    const paymentId = dataArray[0].split("=")[1]
    const PayerID = dataArray[2].split("=")[1]
    const loginData = JSON.parse(localStorage.getItem("loginData"))    
    let accessToken
    if(loginData){
        accessToken = loginData.accessToken
    }
    const [second, setSecond] = useState(3)
    const [showContent, setShowContent] = useState(false)
    const [payment, setPayment] = useState(true)
    if(showContent === true){
        setInterval(() => {
            setSecond(second - 1)
        }, 1000)
        if(second === 0){
            history.push("/dashboard")
        }
    }
    

    const makePaymentDb = () => {

        const config = {
            headers: { authorization: `${accessToken}` },
            params : {
                paymentId : paymentId,
                PayerID : PayerID
            }
        };

        apis.get(`/payment-successful/${user_id}/${amount}/${points}/web`, config)
        .then((response) => {
            if(response.data.success === true){
                setShowContent(true)
                setPayment(true)
            }
            else{
                history.push("/dashboard")
                toast.error(response.data.message, {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
            }
        })
        .catch((error) => {
            history.push("/dashboard")
            toast.error("Could not transfer amount. Please try again later.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
        })
    }

    useEffect(() => {
        makePaymentDb()
    }, [])
    return (
        showContent ? 
      <div className="limiter">
          <div className="container-login100">
            <div className="wrap-login100">
              <form className="login100-form validate-form">
                <span className="login100-form-title">Transcation Successful!</span>
                <p>Amount Transfer was successful. Redirecting to dashboard...</p>
                <p>Please do not close or refresh this window.</p>
              </form>
              <div className="login100-more">
                <div className="logo m-4 ">
                  <img src={logo} className="img-fluid" alt="img" />
                </div>
              </div>
            </div>
          </div>
      </div> : <>Wait....</>
    );
  }

  export default PaymentSuccess;