import { useHistory, useParams } from "react-router-dom";
import logo from "../../../assets/img/logo.png";
import apis from '../../../utils/apis.js'
import {useState, useEffect} from 'react'
import { toast } from "react-toastify";

toast.configure()

function VerifyEmail() {
    const history = useHistory()
    const {token} = useParams()
    console.log(token)
    const [second, setSecond] = useState(3)
    const [showContent, setShowContent] = useState(false)
    if(showContent === true){
        setInterval(() => {
            setSecond(second - 1)
        }, 1000)
        if(second === 0){
            localStorage.removeItem("loginData")
            history.push("/")
        }
    }
    

    const verification = () => {
        const bodyData = {}

        const config = {
            headers: { authorization: `${token}` }
          };
        apis.patch('/verify-email', bodyData, config)
        .then((response) => {
            if(response.data.success === true){
                setShowContent(true)
            }
            else{
                history.push("/")
                toast.error(response.data.message, {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
            }
        })
        .catch((error) => {
            history.push("/")
            toast.error("Could not verify E-Mail. Please try again later.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
        })
    }

    useEffect(() => {
        verification()
    }, [])
    return (
        showContent ? 
      <div className="limiter">
          <div className="container-login100">
            <div className="wrap-login100">
              <form className="login100-form validate-form">
                <span className="login100-form-title">Email Authetication</span>
                <p>Email verified successfully. Redirecting to</p>
                <p>
                  Login Page in {second} seconds. 
                </p>
              </form>
              <div className="login100-more">
                <div className="logo m-4 ">
                  <img src={logo} className="img-fluid" alt="img" />
                </div>
              </div>
            </div>
          </div>
      </div> : <></>
    );
  }

  export default VerifyEmail;