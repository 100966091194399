import Sidebar from "../../sidebar";
import Header from "../../header";
import Form from "react-bootstrap/Form";
import option from "../../../../assets/img/opt-icon.svg";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import apis from '../../../../utils/apis'
import { useState, useEffect } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import Timeout from "../../timeout";
import { toast } from "react-toastify";

toast.configure()

export default function User() {
  let searchTerm
  const history = useHistory()
  const location = useLocation()
  let searchSelected = "", badgeSelected = "", statusSelected = "", countrySelected = "", stateSelected = "", citySelected = "", initialRows = 50, initialPage = 1
  if(location.state !== null && location.state !== undefined){
    if(location.state.hasOwnProperty("searchKey")){
      searchSelected = location.state.searchKey
    }
    
    if(location.state.hasOwnProperty("badge_id")){
      badgeSelected = location.state.badge_id
    }
    
    if(location.state.hasOwnProperty("status_id")){
      statusSelected = location.state.status_id
    }
    
    if(location.state.hasOwnProperty("country_id")){
      countrySelected = location.state.country_id
    }
    
    if(location.state.hasOwnProperty("state_id")){
      stateSelected = location.state.state_id
    }
    
    if(location.state.hasOwnProperty("city_id")){
      citySelected = location.state.city_id
    }
    
    if(location.state.hasOwnProperty("page")){
      initialPage = location.state.page
    }

    if(location.state.hasOwnProperty("rows")){
      initialRows  = location.state.rows
    }


    history.replace({
      pathname : '/user-management',
      state : null
    })
  }

  const loginData = JSON.parse(localStorage.getItem("loginData"))
  let accessToken, screen_name, profilePic
  if(loginData){
    accessToken = loginData.accessToken
    screen_name = loginData.screenName
    profilePic = loginData.profileImage
  }
  const [result, setResult] = useState("")
  const [badgeFilter, setBadgeFilter] = useState("")
  const [show, setShow] = useState(false);
  const [page, setPage] = useState(initialPage)
  const [tokenExpire, setTokenExpire] = useState(false)
  const [rows, setRows] = useState(initialRows)
  const [search, setSearch] = useState(searchSelected)
  const [statusFilter, setStatusFilter] = useState(statusSelected)
  const [activeData, setActiveData] = useState({})
  const [countryFilter, setCountryFilter] = useState("")
  const [stateFilter, setStateFilter] = useState([])
  const [cityFilter, setCityFilter] = useState([])
  const [selectedBadge, setSelectedBadge] = useState(badgeSelected)
  const [selectedCountry, setSelectedCountry] = useState(countrySelected)
  const [selectedState, setSelectedState] = useState(stateSelected)
  const [selectedCity, setSelectedCity] = useState(citySelected)

  const handleClose = () => setShow(false);
  const handleShow = (targetId, targetStatus) => {
    setShow(true);
    let status
    if(targetStatus == 2){
      status = 1
    }
    else{
      status = 2
    }
    setActiveData({
      targetId : targetId,
      targetStatus : status
    })
  }

  const fetchBadgeFilter = async () => {
    console.log("Filter API Called")
    apis.get('/badge-list')
    .then((response)=> {
      const data = response.data
      if(data.success === true){
        setBadgeFilter(data.badgeList)
      }
      else{
        toast.error("Could not fetch Badge Filters.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 3})
      }
    })
    .catch((error)=> {
      toast.error("Could not fetch Badge Filters.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 2})
    })
  }

  const fetchCountryFilter = () => {
    apis.get('/country')
    .then((response) => {
      if(response.data.success === true){
        setCountryFilter(response.data.countryList)
      }
      else{
        toast.error("Could not fetch Country Filters.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 10})
      }

    })
    .catch((error) => {
      toast.error("Could not fetch Country Filters.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 10})
    })
  }

  const fetchStateFilter = (selectedCountry) => {
    apis.get(`/state/${selectedCountry}`)
    .then((response) => {
      if(response.data.success === true){
        setStateFilter(response.data.stateList)
      }
      else{
        toast.error("Could not fetch State List.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 11})
      }
    })
    .catch((error)=> {
      toast.error("Could not fetch State List", {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 11})
    })
  }

  const fetchCityFilter = (selectedState) => {
    apis.get(`/city/${selectedState}`)
    .then((response) => {
      if(response.data.success === true){
        setCityFilter(response.data.cityList)
      }
      else{
        toast.error("Could not fetch City List.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 12})
      }
    })
    .catch((error)=> {
      toast.error("Could not fetch City List", {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 12})
    })
  }

  const handlePgIncrease = () => {
    if(page !== result.total_pages){
      let nextpg = page + 1
      setPage(page + 1)
      fetchData(nextpg)
    }  
  }

  const handlePgDecrease = () => {
    if(page !== 1){
      let nextpg = page - 1
      setPage(page-1)
      fetchData(nextpg)
    }
  }

  const fetchData = async (page) => {
    console.log(page)
    const config = {
      headers: { authorization: `${accessToken}` }
    };
    const requestBody = {
      page : page,
      numberOfItems : rows
    }
    if(search != ""){
      requestBody["searchKey"] = search
    }

    if(statusFilter != ""){
      requestBody["statusId"] = statusFilter
    }

    if(selectedBadge != ""){
      requestBody["badge_id"] = selectedBadge 
    }

    if(selectedCountry != ""){
      requestBody["country_id"] = selectedCountry
    }

    if(selectedState != ""){
      requestBody["state_id"] = selectedState
    }

    if(selectedCity != ""){
      requestBody["city_id"] = selectedCity
    }
    apis.post('/admin/userlist',requestBody, config)
    .then((response) => {
      console.log("List API Called")
      const data = response.data
      console.log(data)
      if(data.success === true){
        setResult(data)
      }
      else{
        toast.error("Could not fetch User List.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 4})
      }
    })
    .catch((error) => {
      if(error.response.data.success ===false && error.response.data.message === 'jwt expired'){
        localStorage.removeItem("loginData")
        setTokenExpire(true)
      }
      else{
        toast.error("Could not fetch User List.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 5})
      }
    })
  }

  const handleItemCountChange = (e) => {
    setRows(e.target.value)
    setPage(1)
  }

  const handleSearchChange = () => {
    setSearch(searchTerm.value)
    setPage(1)
  }

  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value)
    setPage(1)
  }

  const handleBadgeFilterChange = (e) => {
    setSelectedBadge(e.target.value)
    setPage(1)
  }

  const handleExportChange = (e) => {
    let fileType = e.target.value
    let fileAttribute 
    if(fileType === "csv"){
      fileAttribute = "userlist.csv"
    }
    else{
      fileAttribute = "userlist.xlsx"
    }
    if(accessToken){
      console.log(accessToken)
      const requestBody = {
        format : e.target.value
      }

      if(search != ""){
        requestBody["searchKey"] = search
      }
  
      if(statusFilter != ""){
        requestBody["status_id"] = statusFilter
      }
  
      if(selectedBadge != ""){
        requestBody["badge_id"] = selectedBadge 
      }
  
      if(selectedCountry != ""){
        requestBody["country_id"] = selectedCountry
      }
  
      if(selectedState != ""){
        requestBody["state_id"] = selectedState
      }
  
      if(selectedCity != ""){
        requestBody["city_id"] = selectedCity
      }


      const config = {
        headers: { 
          authorization: `${accessToken}`
        },
        responseType: 'arraybuffer'
      }
      apis.post('/admin/user-export',requestBody, config)
      .then((response)=> {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileAttribute);
        document.body.appendChild(link);
        link.click();
        toast.success("Data Downloaded Successfully.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 7})
      })
      .catch((error)=> {
        toast.error("Could not download file. Please try again later.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 6})
      })
    }
  }

  const handleDeleteUser = () => {
    const bodyData = {
      user_id : activeData.targetId,
      status_id : activeData.targetStatus
    }

    const config = {
      headers : {
        authorization : `${accessToken}`
      } 
    }
    apis.patch('/admin/change-user-status', bodyData, config)
    .then((response) => {
      setShow(false)
      if(response.data.success === true){
        toast.success(activeData.targetStatus === 1 ? "User Activated Successfully." : "User Deactivated Successfully.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 8})
        fetchData(page)
      }
      else{
        toast.error("Could not change user status. Please try again later.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 9})
      }
    })
    .catch((error) => {
      setShow(false)
      toast.error("Could not change user status. Please try again later.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 9})
    })
  }

  const handleCountryFilterChange = (e) => {
    setSelectedCountry(e.target.value)
    setSelectedCity("")
    setSelectedState("")
    setPage(1)
    if(e.target.value != ""){
      setCityFilter([])
      apis.get(`/state/${e.target.value}`)
      .then((response) => {
        if(response.data.success === true){
          setStateFilter(response.data.stateList)
        }
        else{
          toast.error("Could not fetch State List.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 11})
        }
      })
      .catch((error)=> {
        toast.error("Could not fetch State List", {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 11})
      })
    }

    if(e.target.value === ""){
      setStateFilter([])
      setCityFilter([])
    }
  }

  const handleStateFilterChange = (e) => {
    setSelectedState(e.target.value)
    setSelectedCity("")
    setPage(1)
    if(e.target.value != ""){
      apis.get(`/city/${e.target.value}`)
      .then((response) => {
        if(response.data.success === true){
          setCityFilter(response.data.cityList)
        }
        else{
          toast.error("Could not fetch City List.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 12})
        }
      })
      .catch((error)=> {
        toast.error("Could not fetch City List", {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 12})
      })
    }

    if(e.target.value === ""){
      setCityFilter([])
    }
  }

  const handleCityFilterChange = (e) => {
    setSelectedCity(e.target.value)
    setPage(1)
  }

  const handleViewUser = (userId) => {

    let filterState = {}

    if(search != ""){
      filterState["searchKey"] = search
    }

    if(statusFilter != ""){
      filterState["status_id"] = statusFilter
    }

    if(selectedBadge != ""){
      filterState["badge_id"] = selectedBadge 
    }

    if(selectedCountry != ""){
      filterState["country_id"] = selectedCountry
    }

    if(selectedState != ""){
      filterState["state_id"] = selectedState
    }

    if(selectedCity != ""){
      filterState["city_id"] = selectedCity
    }

    if(page !== 1){
      filterState["page"] = page
    }

    if(rows !== 50){
      filterState["rows"] = rows
    }


    history.push({
      pathname : `/user-management/view-edit/${userId}/edit-user`,
      state : filterState
    })
  }

  useEffect(() => {
    if(!accessToken && tokenExpire === false){
      history.push({
        pathname : "/",
        state : {
          url : '/user-management'
        }
       })
       toast.error("Please login to continue.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 1})
    }
    else if(accessToken){
      fetchData(page)
    }
  }, [rows, search, statusFilter, selectedBadge, selectedCountry, selectedState, selectedCity])

  useEffect(() => {
    if(accessToken){
      fetchBadgeFilter()
      fetchCountryFilter()
      if(countrySelected !== ""){
        fetchStateFilter(countrySelected)
      }

      if(stateSelected !== ""){
        fetchCityFilter(stateSelected)
      }
    }
  }, [])

  return (
    tokenExpire ? 
    <Timeout>
    </Timeout> : <>
      <main className="page_wrapper">
        <section className="dashboard_section d-flex">
          <Sidebar />
          <section className="dashboard_main_section">
            <Header title="User Management" screen_name = {screen_name} profilePic = {profilePic} accessToken = {accessToken}/>
            <section className="dasboard_main_area list-experts-area">
              <div className="table-exp-main">
                <div className="experts-table-filter row admin-user-filter-row ms-auto">
                  <div className="col-md-2 col-sm-12">
                    <span className="search-filter position-relative ">
                      <span className="search-icon"></span>
                      <input
                        type="text"
                        name=""
                        id=""
                        placeholder="Search... "
                        value={search}
                        ref={(searchInput) => searchTerm = searchInput}
                        onChange = {() => handleSearchChange()}
                      />
                    </span>
                  </div>
                  <div className="col-md-2 col-sm-12 filter-col" id="user-mgmt-dropdown">
                    <div className="select_container">
                      <div className="dropholder">
                        <Form.Select
                          size="sm"
                          aria-label="Default select example"
                          value={selectedBadge}
                          onChange = {(e) => handleBadgeFilterChange(e)}
                        >
                          <option value = "">Badge Type</option>
                          {badgeFilter && badgeFilter.map((ele) => {
                            return(
                              <option key={ele.id} value={ele.id}>{ele.badge_name}</option>
                            )
                          })}
                        </Form.Select>
                      </div>
                    </div>
                  </div>
                  
                  <div className="col-md-2 col-sm-12 filter-col" id="user-mgmt-dropdown">
                    <div className="select_container">
                      <div className="dropholder">
                        <Form.Select
                          size="sm"
                          aria-label="Default select example"
                          value = {statusFilter}
                          onChange = {(e) => handleStatusFilterChange(e)}
                        >
                          <option value = "">Status</option>
                          <option value= "1">Activated</option>
                          <option value= "2">Deactivated</option>
                        </Form.Select>
                      </div>
                    </div>
                  </div>
                  
                  <div className="col-md-2 col-sm-12 filter-col">
                    <div className="select_container">
                      <div className="dropholder">
                        <Form.Select
                          size="sm"
                          aria-label="Default select example"
                          value = {selectedCountry}
                          onChange={(e) => handleCountryFilterChange(e)}
                        >
                          <option value = "">Country</option>
                          {countryFilter && countryFilter.map((ele) => {
                            return(
                              <option key={ele.id} value={ele.id}>{ele.country_name}</option>
                            )
                          })}
                        </Form.Select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2 col-sm-12 filter-col">
                    <div className="select_container">
                      <div className="dropholder">
                        <Form.Select
                          size="sm"
                          aria-label="Default select example"
                          value = {selectedState}
                          disabled = {stateFilter.length > 0 ? false : true}
                          onChange={(e) => handleStateFilterChange(e)}
                        >
                          <option value = "">State</option>
                          {stateFilter && stateFilter.map((ele) => {
                            return(
                              <option key={ele.id} value={ele.id}>{ele.state_name}</option>
                            )
                          })}
                        </Form.Select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2 col-sm-12 filter-col">
                    <div className="select_container">
                      <div className="dropholder">
                        <Form.Select
                          size="sm"
                          aria-label="Default select example"
                          disabled = {cityFilter.length > 0 ? false : true}
                          value = {selectedCity}
                          onChange={(e) => handleCityFilterChange(e)}
                        >
                          <option value = "">City</option>
                          {cityFilter && cityFilter.map((ele) => {
                            return(
                              <option key={ele.id} value={ele.id}>{ele.city_name}</option>
                            )
                          })}
                        </Form.Select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2 col-sm-12 filter-col" id="user-mgmt-dropdown">
                    <div className="select_container">
                      <div className="dropholder">
                        <Form.Select
                          size="sm"
                          aria-label="Default select example"
                          value= ""
                          onChange = {(e) => handleExportChange(e)}
                        >
                          <option value="">Export </option>
                          <option value="csv">CSV</option>
                          <option value="excel">Excel</option>
                        </Form.Select>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className="exp-table-container admin-user-table-section ">
                  <div className="manage-user-main">
                    <table className="table list_experts_table">
                      <thead>
                        {result && result.data.length > 0 ?
                        <tr>
                          <th>
                            <div className="input-box">
                              <input type="checkbox" name="" id="" />
                            </div>
                          </th>
                          <th>
                            <span className="exp-name">
                              Name
                              <img
                                src="../assets/img/table-up-arrow.png"
                                alt=""
                              />
                            </span>
                          </th>
                          <th>E-MAIL</th>
                          <th>Screen Name</th>
                          <th>Last Login Time</th>
                          <th>Added Date</th>
                          <th>Locations</th>
                          <th>Type</th>
                          <th></th>
                        </tr> : <tr><th></th></tr>}
                      </thead>
                      <tbody>
                        {
                          result && result.data.length > 0 ? result.data.map((ele) => {
                            let loginTime = ele.loginTime
                            let badge_name = ele.badge_name
                            let locations = ele.locations
                            let createdAtdt = new Date(ele.createdAt)
                            let createdAtDateBreak = createdAtdt.toString().split(" ")
                            let createdAt = `${createdAtDateBreak[2]}-${createdAtDateBreak[1]}-${createdAtDateBreak[3]}`
                            
                            if(loginTime === null){
                              loginTime = "Not Logged in yet"
                            }
                            else if(loginTime != null){
                              let dt = new Date(loginTime)
                              let dateBreak = dt.toString().split(" ")
                              loginTime = `${dateBreak[2]}-${dateBreak[1]}-${dateBreak[3]} @ ${dateBreak[4]}`
                            }
                            if(badge_name === null){
                              badge_name = 'N/A'
                            }
                            if(locations === null){
                              locations = 'N/A'
                            }

                            return(
                              <tr key={ele.id}>
                                <td>
                                  <div className="input-box">
                                    <input type="checkbox" name="" id="" />
                                  </div>
                                </td>
                                <td>
                                  <div className="exp-name-val">{ele.full_name}</div>
                                </td>
                                <td>{ele.username}</td>
                                <td>{ele.screen_name}</td>
                                <td>{loginTime}</td>
                                <td>{createdAt}</td>
                                <td><div className="loc-name-val">{locations}</div></td>
                                <td>{badge_name}</td>
                                <td className="option-cell">
                                  <Dropdown>
                                    <Dropdown.Toggle
                                      variant="default"
                                      id="dropdown-basic"
                                      size="sm"
                                    >
                                      <img src={option} alt="" />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <Dropdown.Item onClick={() => handleViewUser(ele.id)}>
                                        View/Edit
                                      </Dropdown.Item>
                                      <Dropdown.Item onClick={() => handleShow(ele.id, ele.statusId)}>
                                        {ele.statusId === 1 ? "Deactivate" : "Activate"}
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </td>
                              </tr>
                            )
                          }) :<tr>
                            <td>No Data to show.</td>
                          </tr>
                           
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="table-pagination-wrapper">
                <div className="table-pagination-con d-flex align-items-center justify-content-lg-end justify-content-center">
                  <span className="pagination-label">Items per page:</span>
                  <select className="select-page-number" name="" id="" value={rows} onChange={(e) => handleItemCountChange(e)}>
                    <option value= {50}>50</option>
                    <option value= {100}>100</option>
                    <option value= {150}>150</option>
                    <option value= {200}>200</option>
                    <option value= {250}>250</option>
                    <option value= {300}>300</option>
                  </select>
                  <span className="pagination-label page-counter">{result.total_pages === 0 ? 0 : page} of {result.total_pages}</span>
                  <span className="pagination-controls">
                    <a className="prev" style={{cursor:'pointer'}} onClick={() => handlePgDecrease()}>
                      ‹
                    </a>
                    <a className="next" style={{cursor:'pointer'}} onClick={() => handlePgIncrease()}>
                      ›
                    </a>
                  </span>
                </div>
              </div>
            </section>
            <Modal centered show={show} onHide={handleClose}>
            <Modal.Body>
              <div className="px-4 mb-3 admin">
                <Modal.Header closeButton></Modal.Header>
                <div className="text-center p-5">
                  <p className="delete mb-4">Are you sure you want to {activeData.targetStatus === 1 ? "Activate" : "Deactivate"} this User?</p>
                  <div className="text-left mt-3" id="delete-modal">
                    <button className="btn btn-outline me-3" onClick={() => setShow(false)}>No</button>
                    <button className="btn btn-xl text-center h-53" onClick={() => handleDeleteUser()}>{activeData.targetStatus === 1 ? "Activate" : "Deactivate"}</button>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          </section>
        </section>
      </main>
    </>
    
  );
}
