import React,{useState, useEffect} from 'react'
import { Form } from "react-bootstrap";
import Header from '../../header';
import Sidebar from "../../sidebar";
import apis from "../../../../utils/apis";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Timeout from '../../timeout';

toast.configure()

const FaqView = () =>{
    let question_text, response_text
    const history = useHistory()
    const {faq_id} = useParams()
    const emptyLineRegEX = /\S/
    const loginData = JSON.parse(localStorage.getItem("loginData"))
    let accessToken, screen_name, profilePic
    if(loginData){
      accessToken = loginData.accessToken
      screen_name = loginData.screenName
      profilePic = loginData.profileImage
    }
    const [tokenExpire, setTokenExpire] = useState(false)
    const [faqDetails, setFaqDetails] = useState("")
    const [initialQuestion, setInitialQuestion] = useState("")
    const [initialResponse, setInitialResponse] = useState("")
    const [updateCheck, setUpdateCheck] = useState(false)
    const [questionError, setQuestionError] = useState(false)
    const [responseError, setResponseError] = useState(false)

    const fetchFaqDetails = () => {
        const config = {
            headers : {
                authorization : `${accessToken}`
            }
        }

        apis.get(`/admin/get-faq/${faq_id}`, config)
        .then((response) => {
            if(response.data.success === true){
                setFaqDetails(response.data.data)
                setInitialQuestion(response.data.data.faq_question)
                setInitialResponse(response.data.data.faq_answer)
            }
            else{
                history.goBack()
                toast.error("Could not fetch FAQ Details. Please try again later.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
            }
        })
        .catch((error) => {
            if(error.response.data.success ===false && error.response.data.message === 'jwt expired'){
                localStorage.removeItem("loginData")
                setTokenExpire(true)
            }
            else{
                history.goBack()
                toast.error("Could not fetch FAQ Details. Please try again later.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
            }
        })
    }

    const handleQuestionChange = () => {
        setInitialQuestion(question_text.value)
        if(question_text.value === "" || !emptyLineRegEX.test(question_text.value)){
            setQuestionError(true)
        }
        else{
            setQuestionError(false)
        }
    }

    const handleResponseChange = () => {
        setInitialResponse(response_text.value)
        if(response_text.value === "" || !emptyLineRegEX.test(response_text.value)){
            setResponseError(true)
        }
        else{
            setResponseError(false)
        }
    }

    const dataChangeCheck = () => {
        if((faqDetails.faq_question !== initialQuestion || faqDetails.faq_answer !== initialResponse) && questionError === false && responseError === false){
            setUpdateCheck(false)
        }
        else{
            setUpdateCheck(true)
        }
    }

    const handleEdit = () => {
        const config = {
            headers : {
                authorization : `${accessToken}`
            }
        }

        const bodyData = {
            faq_question : question_text.value,
            faq_answer : response_text.value
        }

        setUpdateCheck(true)

        apis.patch(`/admin/update-faq/${faq_id}`, bodyData, config)
        .then((response) => {
            if(response.data.success === true){
                toast.success("FAQ Updated Successfully.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
                fetchFaqDetails()
            }
            else{
                toast.error("Could not update FAQ. Please try again later.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
                fetchFaqDetails()
            }
        })
        .catch((error) => {
            if(error.response.data.success ===false && error.response.data.message === 'jwt expired'){
                localStorage.removeItem("loginData")
                setTokenExpire(true)
            }
            else{
                toast.error("Could not update FAQ. Please try again later.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
            }
        })
    }

    useEffect(() => {
        if(!accessToken){
            history.push({
                pathname : "/",
                state : {
                  url : '/faq'
                }
            })  
            toast.error("Please login to continue.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER}) 
        }
        else if(accessToken){
            fetchFaqDetails()
        }
    }, [])

    useEffect(() => {
        dataChangeCheck()
    }, [initialQuestion, initialResponse])
      
    return(
        tokenExpire ?
        <Timeout>
        </Timeout>
        :
        <main className="page_wrapper">
           <section className="dashboard_section d-flex">
               <Sidebar />
               <section className="dashboard_main_section">
                     <Header title="View/Edit FAQ" screen_name = {screen_name} profilePic = {profilePic} accessToken = {accessToken}/>
                        <section className="dasboard_main_area">
                        <div className='FaqViewPage'> 
                            <div className='row'>
                                <div className='col-md-12'>
                                    <label>Question</label>
                                    <div className='pointViewWrapper'>
                                        <div className='pv-question'>
                                            <textarea 
                                            rows="5" 
                                            value= {initialQuestion}
                                            className='editableArea'
                                            ref = {(questionInput) => question_text = questionInput}
                                            onChange = {() => handleQuestionChange()}
                                            >
                                            </textarea>
                                        </div>
                                        
                                    </div>
                                    {!questionError ? <></> : <p className="error-label">Question can't be empty</p>}
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <label>Answer</label>
                                    <div className='pointViewWrapper'>
                                        <div className='pv-question'>
                                            <textarea 
                                            rows="5" 
                                            value= {initialResponse}
                                            className='editableArea'
                                            ref = {(responseInput) => response_text = responseInput}
                                            onChange = {() => handleResponseChange()}
                                            >
                                            </textarea>
                                        </div>
                                    </div>
                                    {!responseError ? <></> : <p className="error-label">Response can't be empty</p>}
                                </div>
                            </div>
                        </div>
                        <div className="submit-wrapper user-form-submit submit-main row mt-4">
                            <button className="btn-outline bg-white" onClick={() => history.goBack()}>Cancel</button>
                            <button className="btn btn-xl" disabled = {updateCheck} onClick = {() => handleEdit()}>Save</button>
                        </div>
                        </section>
                </section>
            </section>
        </main>
    )
}

export default FaqView