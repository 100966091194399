import React from 'react'
import Header from '../../header';
import Sidebar from "../../sidebar";
import Arrow from '../../../../assets/img/arrowPng.png'
const PointCard = () =>{
    const loginData = JSON.parse(localStorage.getItem("loginData"))
    let accessToken, screen_name, profilePic
    if(loginData){
      accessToken = loginData.accessToken
      screen_name = loginData.screenName
      profilePic = loginData.profileImage
    }

    return(
        <main className="page_wrapper">
           <section className="dashboard_section d-flex">
               <Sidebar />
               <section className="dashboard_main_section">
                     <Header title="Dashboard" screen_name = {screen_name} profilePic = {profilePic} accessToken = {accessToken}/>
                        <section className="dasboard_main_area">
                        <div className='pointViewWrapper'>
                        <div class="heading"><h3 class="txt-3"> <img src={Arrow} alt="img" /> <b>User  </b>: Jane Cooper</h3></div>  
                            <div className='row'>
                                <div className='col-md-2'>
                                        <label>card</label>
                                </div>
                                <div className='col-md-10'>
                                   
                                    <div>
                                        <p>
                                        **** **** 5001                   
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-2'>
                                        <label>Card Type</label>
                                </div>
                                <div className='col-md-10'>
                                    <div>
                                        <p>
                                        Debit                   
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-2'>
                                        <label>Date & Time</label>
                                </div>
                                <div className='col-md-10'>
                                    <div>
                                        <p>
                                        9-10-2021 04:34am
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-2'>
                                    <label>Points</label>
                                </div>
                                <div className='col-md-10'>
                                    <div>
                                        <p>
                                        15 Bought                     
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-2'>
                                    <label>Status</label>
                                </div>
                                <div className='col-md-10'>
                                    <div>
                                        <p>
                                        Approved                 
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </section>
                </section>
            </section>
        </main>
    )
}

export default PointCard