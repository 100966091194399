import React, {useState, useEffect} from 'react'
import Header from '../../header';
import Sidebar from "../../sidebar";
import Arrow from '../../../../assets/img/arrowPng.png'
import { useHistory, useParams } from 'react-router-dom';
import apis from '../../../../utils/apis';
import Timeout from '../../timeout';
import { toast } from "react-toastify";

toast.configure()

const PaymentView = () =>{
    const history = useHistory()
    const {paymentId} = useParams()
    const loginData = JSON.parse(localStorage.getItem("loginData"))
    let accessToken, screen_name, profilePic
    if(loginData){
      accessToken = loginData.accessToken
      screen_name = loginData.screenName
      profilePic = loginData.profileImage
    }
    const [tokenExpire, setTokenExpire] = useState(false)
    const [paymentDetails, setPaymentDetails] = useState("")
    const [paymentDate, setPaymentDate] = useState("")



    const fetchPaymentDetails = () => {
        const config = {
            headers : {
                authorization : `${accessToken}`
            }
        }

        apis.get(`/admin/payment-info/${paymentId}`, config)
        .then((response) => {
            if(response.data.success === true){
                setPaymentDetails(response.data.data)
                let createdAtdt = new Date(response.data.data.createdAt)
                let createdAtDateBreak = createdAtdt.toString().split(" ")
                let createdAt = `${createdAtDateBreak[2]}-${createdAtDateBreak[1]}-${createdAtDateBreak[3]} @ ${createdAtDateBreak[4]}`
                setPaymentDate(createdAt)
            }
            else{
                history.goBack()
                toast.error("Could not get Payment Details, Please try again later.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
            }
        })
        .catch((error) => {
            if(error.response.data.success ===false && error.response.data.message === 'jwt expired'){
                localStorage.removeItem("loginData")
                setTokenExpire(true)
              }
            else{
                history.goBack()
                toast.error("Could not get Payment Details, Please try again later.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
            }
        })
    }

    useEffect(() => {
        if(!accessToken){
            history.push({
                pathname : "/",
                state : {
                    url : '/payment'
                }
            })
        }
        else if(accessToken){
            fetchPaymentDetails()
        }
    }, [])

    return(
        tokenExpire === true
        ?
        <Timeout>
        </Timeout>
        :
        <main className="page_wrapper">
           <section className="dashboard_section d-flex">
               <Sidebar />
               <section className="dashboard_main_section">
                     <Header title="Payment Details" screen_name = {screen_name} profilePic = {profilePic} accessToken = {accessToken}/>
                        <section className="dasboard_main_area">
                        <div className='pointViewWrapper'>
                        <div class="heading" onClick={() => history.goBack()} style={{cursor:'pointer'}}><h3 class="txt-3"> <img src={Arrow} alt="img" /> BACK</h3></div>  
                           
                        <div className='row'>
                                <div className='col-md-2'>
                                        <label>User</label>
                                </div>
                                <div className='col-md-10'>
                                    <div>
                                        <p>
                                        {paymentDetails.username}                   
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-2'>
                                        <label>Mode</label>
                                </div>
                                <div className='col-md-10'>
                                    <div>
                                        <p>
                                        {paymentDetails.wallet === "paypal" ? "Paypal" : "N/A"}                    
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-2'>
                                        <label>Points</label>
                                </div>
                                <div className='col-md-10'>
                                    <div>
                                        <p>
                                            {paymentDetails.points}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-2'>
                                    <label>Date & Time</label>
                                </div>
                                <div className='col-md-10'>
                                    <div>
                                        <p>
                                        {
                                         paymentDate
                                        }           
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-2'>
                                    <label>Amount</label>
                                </div>
                                <div className='col-md-10'>
                                    <div>
                                        <p>
                                        {`$ ${paymentDetails.amount}`}               
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-2'>
                                    <label>Status</label>
                                </div>
                                <div className='col-md-10'>
                                    <div>
                                        <p>
                                        {paymentDetails.status === "SUCCESS" ? paymentDetails.status : "FAILED"}          
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </section>
                </section>
            </section>
        </main>
    )
}

export default PaymentView