import Sidebar from "../../sidebar";
import Header from "../../header";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Form from "react-bootstrap/Form";
import { Link, useHistory } from 'react-router-dom';
import {useState, useEffect} from 'react';
import apis from "../../../../utils/apis";
import Timeout from "../../timeout";
import { toast } from "react-toastify";

toast.configure()

function Badge() {
  const pointsRegEx = /^[0-9\b]+$/;
  const history = useHistory()
  const loginData = JSON.parse(localStorage.getItem("loginData"))
  let accessToken, screen_name, profilePic
  if(loginData){
    accessToken = loginData.accessToken
    screen_name = loginData.screenName
    profilePic = loginData.profileImage
  }

  const [silverData, setSilverData] = useState("")
  const [goldData, setGoldData] = useState("")
  const [platinumData, setPlatinumData] = useState("")
  const [tokenExpire, setTokenExpire] = useState(false)
  const [silverMin, setSilverMin] = useState(null)
  const [silverMax, setSilverMax] = useState(null)
  const [goldMin, setGoldMin] = useState(null)
  const [goldMax, setGoldMax] = useState(null)
  const [platinumMin, setPlatinumMin] = useState(null)
  const [platinumMax, setPlatinumMax] = useState(null)
  const [activeTab, setActiveTab] = useState("Silver")
  const [updateCheck, setUpdateCheck] = useState(true)
  const [silverMinErr, setSilverMinErr] = useState("")
  const [silverMaxErr, setSilverMaxErr] = useState("")
  const [goldMinErr, setGoldMinErr] = useState("")
  const [goldMaxErr, setGoldMaxErr] = useState("")
  const [platinumMinErr, setPlatinumMinErr] = useState("")
  const [silverId, setSilverId] = useState(null)
  const [goldId, setGoldId] = useState(null)
  const [platinumId, setPlatinumId] = useState(null)

  const fetchBadgeRange = () => {
    const config = {
      headers : {
        authorization : `${accessToken}`
      }
    }

    apis.get('/badge-list', config)
    .then((response) => {
      console.log(response)
      if(response.data.success === true){
        setSilverData(response.data.badgeList[2].Badge_Range)
        setSilverId(response.data.badgeList[2].id)
        setGoldData(response.data.badgeList[1].Badge_Range)
        setGoldId(response.data.badgeList[1].id)
        setPlatinumData(response.data.badgeList[0].Badge_Range)
        setPlatinumId(response.data.badgeList[0].id)
        setSilverMin(response.data.badgeList[2].Badge_Range.min_point)
        setSilverMax(response.data.badgeList[2].Badge_Range.max_point)
        setGoldMin(response.data.badgeList[1].Badge_Range.min_point)
        setGoldMax(response.data.badgeList[1].Badge_Range.max_point)
        setPlatinumMin(response.data.badgeList[0].Badge_Range.min_point)
        setPlatinumMax(response.data.badgeList[0].Badge_Range.max_point)
      }
      else{
        toast.error("Could not fetch Badge Range.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
      }
    })
    .catch((error)=>{
      if(error.response.data.success ===false && error.response.data.message === 'jwt expired'){
        localStorage.removeItem("loginData")
        setTokenExpire(true)
      }
      else{
        toast.error("Could not fetch Badge Range.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
      }
    })
  }

  const handleMinChange = (e, targetBadge) => {
    if(targetBadge === "silver"){
      if(pointsRegEx.test(e.target.value) || e.target.value === ""){
        setSilverMin(e.target.value)
      }

      if(parseInt(e.target.value) > parseInt(silverMax)){
        setSilverMinErr("Silver minimum pts should be less than it's maximum pts")
      }
      else{
        setSilverMinErr("")
      }
    }

    if(targetBadge === "gold"){
      if(pointsRegEx.test(e.target.value) || e.target.value === ""){
        setGoldMin(e.target.value)
      }

      if(parseInt(e.target.value) < parseInt(silverMax) || parseInt(e.target.value) > parseInt(goldMax)){
        setGoldMinErr("Gold minimum pts should be greater than silver max pts and less than gold max pts")
      }
      else{
        setGoldMinErr("")
      }
    }

    if(targetBadge === "platinum"){
      if(pointsRegEx.test(e.target.value) || e.target.value === ""){
        setPlatinumMin(e.target.value)
      }

      if(parseInt(e.target.value) < parseInt(goldMax)){
        setPlatinumMinErr("Platinum minimum pts should be greater than gold max pts")
      }
      else{
        setPlatinumMinErr("")
      }

    }
  }

  const handleMaxChange = (e, targetBadge) => {
    if(targetBadge === "silver"){
      if(pointsRegEx.test(e.target.value) || e.target.value === ""){
        setSilverMax(e.target.value)
      }
      
      if(parseInt(e.target.value) < parseInt(silverMin) || parseInt(e.target.value) > parseInt(goldMin)){
        setSilverMaxErr("Silver maximum pts should be greater than it's minimum pts and less than Gold's minimum pts")
      }
      else{
        setSilverMaxErr("")
      }

    }

    if(targetBadge === "gold"){
      if(pointsRegEx.test(e.target.value) || e.target.value === ""){
        setGoldMax(e.target.value)
      }

      if(parseInt(e.target.value) < parseInt(goldMin) || parseInt(e.target.value) > parseInt(platinumMin)){
        setGoldMaxErr("Gold maximum pts should be greater than it's minimum pts and less than platinum's minimum pts")
      }
      else{
        setGoldMaxErr("")
      }
    }

    if(targetBadge === "platinum"){
      if(pointsRegEx.test(e.target.value) || e.target.value === ""){
        setPlatinumMax(e.target.value)
      }
    }

  }

  const dataChangeCheck = () => {
    if(activeTab === "Silver"){
      if((silverMin != silverData.min_point || silverMax != silverData.max_point) && silverMin !== "" && silverMax !== "" && silverMinErr === "" && silverMaxErr === ""){
        setUpdateCheck(false)
      }
      else{
        setUpdateCheck(true)
      }
    }
    if(activeTab === "Gold"){
      if((goldMin != goldData.min_point || goldMax != goldData.max_point) && goldMin !== "" && goldMax !== "" && goldMinErr === "" && goldMaxErr === ""){
        setUpdateCheck(false)
      }
      else{
        setUpdateCheck(true)
      }

    }
    if(activeTab === "Platinum"){
      if((platinumMin != platinumData.min_point || platinumMax != platinumData.max_point) && platinumMin !== "" && platinumMax !== "" && platinumMinErr === ""){
        setUpdateCheck(false)
      }
      else{
        setUpdateCheck(true)
      }

    }
  }

  const handleTabChange = (k) => {
    setActiveTab(k)
    fetchBadgeRange()
    setSilverMinErr("")
    setSilverMaxErr("")
    setGoldMinErr("")
    setGoldMaxErr("")
    setPlatinumMinErr("")
  }

  const handleSave = () => {
    let successMessage, errorMessage, minPts, maxPts, badge_id
    if(activeTab === "Silver"){
      successMessage = "Silver Badge range updated successfully"
      errorMessage = "Silver badge range could not be updated. Please try again later"
      minPts = silverMin
      maxPts = silverMax
      badge_id = silverId
    }
    if(activeTab=== "Gold"){
      successMessage = "Gold Badge range updated successfully"
      errorMessage = "Gold badge range could not be updated. Please try again later"
      minPts = goldMin
      maxPts = goldMax
      badge_id = goldId
    }
    if(activeTab === "Platinum"){
      successMessage = "Platinum Badge range updated successfully"
      errorMessage = "Platinum badge range could not be updated. Please try again later"
      minPts = platinumMin
      maxPts = "999999999999"
      badge_id = platinumId
    }

    const payload = {
      min_point : minPts,
      max_point : maxPts,
      badge_id : badge_id.toString()
    }

    const config = {
      headers : {
        authorization : `${accessToken}`
      }
    }

    apis.post('/admin/add-badge-range', payload, config)
    .then((response) => {
      if(response.data.success === true){
        setUpdateCheck(true)
        toast.success(successMessage, {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 1})
        fetchBadgeRange()
      }
      else{
        toast.error(errorMessage, {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 1})
      }
    })
    .catch((error)=>{
      toast.error(errorMessage, {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 1})
    })
  }

  useEffect(() => {
    dataChangeCheck()
  }, [silverMin, silverMax, goldMin, goldMax, platinumMin, platinumMax, activeTab])


  useEffect(() => {
    if(!accessToken){
      history.push({
        pathname : "/",
        state : {
          url : '/badge'
        }
       })
      toast.error("Please login to continue.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 1})
    }
    else if(accessToken){
      fetchBadgeRange()
    }
  }, [])
  return (
    tokenExpire ? 
    <Timeout>
    </Timeout>
    :  
    <>
      <main className="page_wrapper">
        <section className="dashboard_section d-flex">
          <Sidebar />
          <section className="dashboard_main_section">
            <Header title="Badge Management" screen_name = {screen_name} profilePic = {profilePic} accessToken = {accessToken}/>
            <section class="dasboard_main_area admin badge-management ">
            <div className="add-new-question text-end">
                <Link to={'./spam'} className="btn btn-xl p-3 px-5" >
                Spam Criteria
                </Link>
              </div>
              <Tabs defaultActiveKey="Silver" activeKey={activeTab} id="uncontrolled-tab-example" className="mb-3" onSelect={(k) => handleTabChange(k)}>
                <Tab eventKey="Silver" title="Silver">
                  <div className="tabs_pannel">
                    <div class="badge-manage-main tab-container ">
                      <form class="badge-manage-form" action="">
                        <div class="badge-from-row row">
                          <div class="col-md-4 badge-col">
                            <div class="bage-manage-field-wrapper d-flex align-items-center justify-content-between">
                              <div class="input-box">
                                <Form.Group controlId="exampleForm.ControlInput1">
                                  <Form.Control type="text" placeholder="Min." value = {silverMin} onChange = {(e) => handleMinChange(e, "silver")}/>
                                </Form.Group>
                                  
                              </div>
                              <div class="label-wrapper plus-symbol">
                                <label>Minimum Points</label>
                                {/* <span>   +</span> */}
                              </div>
                            </div> 
                            {silverMinErr === "" ? <></> : <div className="col-md-4 error-label">{silverMinErr}</div>}
                          </div>
                          
                          
                          <div class="col-md-4 badge-col">
                            <div class="bage-manage-field-wrapper d-flex align-items-center justify-content-between">
                              <div class="input-box">
                                <Form.Group controlId="exampleForm.ControlInput1">
                                  <Form.Control type="text" placeholder="Max." value = {silverMax} onChange = {(e) => handleMaxChange(e, "silver")} />
                                </Form.Group>
                              </div>
                              <div class="label-wrapper plus-symbol ">
                                <label>Maximum Points</label>
                                {/* <span>+</span> */}
                              </div>
                            </div>
                            {silverMaxErr === "" ? <></> : <div className="col-md-4 error-label">{silverMaxErr}</div>}
                          </div>
                          {/* <div class="col-md-3 badge-col">
                            <div class="bage-manage-field-wrapper d-flex align-items-center justify-content-between">
                              <div class="input-box">
                                <Form.Group controlId="exampleForm.ControlInput1">
                                  <Form.Control type="text" placeholder="2" />
                                </Form.Group>
                              </div>
                              <div class="label-wrapper plus-symbol">
                                <label>Spam</label>
                              </div>
                            </div>
                          </div> */}
                        </div>
                        <div class="submit-wrapper submit-main row mt-5">
                          <div class="text-left ">
                            <button
                              class="btn btn-xl text-center"
                              type="button"
                              disabled = {updateCheck}
                              onClick = {() => handleSave()}
                            >Save </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="Gold" title="Gold">
                  <div className="tabs_pannel" id="tab2">
                    <div class="badge-manage-main tab-container ">
                      <form class="badge-manage-form" action="">
                        <div class="badge-from-row row">
                          <div class="col-md-4 badge-col">
                            <div class="bage-manage-field-wrapper d-flex align-items-center justify-content-between">
                              <div class="input-box">
                                <Form.Group controlId="exampleForm.ControlInput1">
                                  <Form.Control type="text" placeholder="Min." value={goldMin} onChange = {(e) => handleMinChange(e, "gold")}/>
                                </Form.Group>
                              </div>
                              <div class="label-wrapper plus-symbol">
                                <label>Minimum Points</label>
                                {/* <span>+</span> */}
                              </div>
                            </div>
                            {goldMinErr === "" ? <></> : <div className="col-md-4 error-label">{goldMinErr}</div>}
                          </div>
                          <div class="col-md-4 badge-col">
                            <div class="bage-manage-field-wrapper d-flex align-items-center justify-content-between">
                              <div class="input-box">
                                <Form.Group controlId="exampleForm.ControlInput1">
                                  <Form.Control type="text" placeholder="Max." value = {goldMax} onChange = {(e) => handleMaxChange(e, "gold")} />
                                </Form.Group>
                              </div>
                              <div class="label-wrapper plus-symbol ">
                                <label>Maximum Points</label>
                                {/* <span>+</span> */}
                              </div>
                            </div>
                            {goldMaxErr === "" ? <></> : <div className="col-md-4 error-label">{goldMaxErr}</div>}
                          </div>
                          {/* <div class="col-md-3 badge-col">
                            <div class="bage-manage-field-wrapper d-flex align-items-center justify-content-between">
                              <div class="input-box">
                                <Form.Group controlId="exampleForm.ControlInput1">
                                  <Form.Control type="text" placeholder="2" />
                                </Form.Group>
                              </div>
                              <div class="label-wrapper plus-symbol">
                                <label>Spam</label>
                              </div>
                            </div>
                          </div> */}
                        </div>
                        <div class="submit-wrapper submit-main row mt-5">
                          <div class="text-left ">
                          <button
                              class="btn btn-xl text-center"
                              type="button"
                              disabled = {updateCheck}
                              onClick = {() => handleSave()}
                            >Save </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="Platinum" title="Platinum">
                  <div className="tabs_pannel" id="tab3">
                    <div class="badge-manage-main tab-container ">
                      <form class="badge-manage-form" action="">
                        <div class="badge-from-row row">
                          <div class="col-md-4 badge-col">
                            <div class="bage-manage-field-wrapper d-flex align-items-center justify-content-between">
                              <div class="input-box">
                                <Form.Group controlId="exampleForm.ControlInput1">
                                  <Form.Control type="text" placeholder="Min." value = {platinumMin} onChange = {(e) => handleMinChange(e, "platinum")} />
                                </Form.Group>
                              </div>
                              <div class="label-wrapper plus-symbol">
                                <label>Minimum Points</label>
                                {/* <span>+</span> */}
                              </div>
                            </div>
                            {platinumMinErr === "" ? <></> : <div className="col-md-4 error-label">{platinumMinErr}</div>}
                          </div>
                          {/* <div class="col-md-4 badge-col">
                            <div class="bage-manage-field-wrapper d-flex align-items-center justify-content-between">
                              <div class="input-box">
                                <Form.Group controlId="exampleForm.ControlInput1">
                                  <Form.Control type="text" placeholder="5" />
                                </Form.Group>
                              </div>
                              <div class="label-wrapper plus-symbol ">
                                <label>Maximum Points</label>
                                <span>+</span>
                              </div>
                            </div>
                          </div> */}
                          {/* <div class="col-md-3 badge-col">
                            <div class="bage-manage-field-wrapper d-flex align-items-center justify-content-between">
                              <div class="input-box">
                                <Form.Group controlId="exampleForm.ControlInput1">
                                  <Form.Control type="text" placeholder="2" />
                                </Form.Group>
                              </div>
                              <div class="label-wrapper plus-symbol">
                                <label>Spam</label>
                              </div>
                            </div>
                          </div> */}
                        </div>
                        <div class="submit-wrapper submit-main row mt-5">
                          <div class="text-left ">
                          <button
                              class="btn btn-xl text-center"
                              type="button"
                              disabled = {updateCheck}
                              onClick = {() => handleSave()}
                            >Save </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </section>
          </section>
        </section>
      </main>
    </>
  );
}
export default Badge;
