import React, {useState, useEffect} from 'react'
import Sidebar from "../../sidebar";
import Header from "../../header";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Form from "react-bootstrap/Form";
import apis from '../../../../utils/apis';
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import Timeout from '../../timeout';

toast.configure()

const LegalSetting = () =>{
    let newPolicy, newTerms, newAbout, newContact
    const emptyLineRegEX = /\S/
    const emailRegEx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const history = useHistory()
    const loginData = JSON.parse(localStorage.getItem("loginData"))
    let accessToken, screen_name, profilePic
    if(loginData){
      accessToken = loginData.accessToken
      screen_name = loginData.screenName
      profilePic = loginData.profileImage
    }
    const [tokenExpire, setTokenExpire] = useState(false)
    const [tabsData, setTabsData] = useState("")
    const [initialPolicy, setInitialPolicy] = useState("")
    const [initialAbout, setInitialAbout] = useState("")
    const [initialTerms, setInitialTerms] = useState("")
    const [initialContactUs, setInitialContactUs] = useState("")
    const [updateCheckPolicy, setUpdateCheckPolicy] = useState(true)
    const [updateCheckTerms, setUpdateCheckTerms] = useState(true)
    const [updateCheckAbout, setUpdateCheckAbout] = useState(true)
    const [updateCheckContactUs, setUpdateCheckContactUs] = useState(true)
    const [policyError, setPolicyError] = useState(false)
    const [termsError, setTermsError] = useState(false)
    const [aboutError, setAboutError] = useState(false)
    const [contactUsError, setContactUsError] = useState(false)


    const fetchTabsData = () => {
      const config = {
        headers : {
          authorization : `${accessToken}`
        }
      }

      apis.get('/admin/get-footer-content', config)
      .then((response) => {
        if(response.data.success === true){
          setTabsData(response.data.data)
          setInitialPolicy(response.data.data[2].footer_text)
          setInitialTerms(response.data.data[0].footer_text)
          setInitialAbout(response.data.data[1].footer_text)
          setInitialContactUs(response.data.data[3].footer_text)
        }
        else{
          toast.error("Could not fetch Legal Settings data. Please try again later.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
        }
      })
      .catch((error) => {
        if(error.response.data.success ===false && error.response.data.message === 'jwt expired'){
          localStorage.removeItem("loginData")
          setTokenExpire(true)
        }
        else{
          toast.error("Could not fetch Legal Settings data. Please try again later.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
        }
        
      })
    }

    // FOR POLICY

    const handlePolicyChange = () => {
      setInitialPolicy(newPolicy.value)
      if(newPolicy.value === "" || !emptyLineRegEX.test(newPolicy.value)){
        setPolicyError(true)
      }
      else{
        setPolicyError(false)
      }
    }

    const dataChangePolicy = () => {
      if((tabsData && tabsData[2].footer_text !== initialPolicy) && policyError === false){
        setUpdateCheckPolicy(false)
      }
      else{
        setUpdateCheckPolicy(true)
      }
    }

    const handlePolicyEdit = () => {
      const config = {
        headers : {
          authorization : `${accessToken}`
        }
      }
      
      const bodyData = {
        footer_text : newPolicy.value,
        footer_code : "policy"
      }

      setUpdateCheckPolicy(true)

      apis.post('/admin/add-footer-content', bodyData, config)
      .then((response) => {
        if(response.data.success === true){
          toast.success("Policy Updated Successfully.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
          fetchTabsData()
        }
        else{
          toast.error("Could not update Policy. Please try again later.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
          fetchTabsData()
        }
      })
      .catch((error) => {
        if(error.response.data.success ===false && error.response.data.message === 'jwt expired'){
          localStorage.removeItem("loginData")
          setTokenExpire(true)
        }
        else{
          toast.error("Could not update Policy. Please try again later.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
        }
      })
    }

    // FOR TERMS AND CONDITIONS

    const handleTermsChange = () => {
      setInitialTerms(newTerms.value)
      if(newTerms.value === "" || !emptyLineRegEX.test(newTerms.value)){
        setTermsError(true)
      }
      else{
        setTermsError(false)
      }
    }

    const dataChangeTerms = () => {
      if((tabsData && tabsData[0].footer_text !== initialTerms) && termsError === false){
        setUpdateCheckTerms(false)
      }
      else{
        setUpdateCheckTerms(true)
      }
    }

    const handleTermsEdit = () => {
      const config = {
        headers : {
          authorization : `${accessToken}`
        }
      }
      
      const bodyData = {
        footer_text : newTerms.value,
        footer_code : "term_condition"
      }

      setUpdateCheckTerms(true)

      apis.post('/admin/add-footer-content', bodyData, config)
      .then((response) => {
        if(response.data.success === true){
          toast.success("Terms and Conditions Updated Successfully.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
          fetchTabsData()
        }
        else{
          toast.error("Could not update Terms and Conditions. Please try again later.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
          fetchTabsData()
        }
      })
      .catch((error) => {
        if(error.response.data.success ===false && error.response.data.message === 'jwt expired'){
          localStorage.removeItem("loginData")
          setTokenExpire(true)
        }
        else{
          toast.error("Could not update Terms and Conditions. Please try again later.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
        }
      })

    }

    // FOR ABOUT US
    const handleAboutChange = () => {
      setInitialAbout(newAbout.value)
      if(newAbout.value === "" || !emptyLineRegEX.test(newAbout.value)){
        setAboutError(true)
      }
      else{
        setAboutError(false)
      }
    }

    const dataChangeAbout = () => {
      if((tabsData && tabsData[1].footer_text !== initialAbout) && aboutError === false){
        setUpdateCheckAbout(false)
      }
      else{
        setUpdateCheckAbout(true)
      }
    }

    const handleAboutEdit = () => {
      const config = {
        headers : {
          authorization : `${accessToken}`
        }
      }
      
      const bodyData = {
        footer_text : newAbout.value,
        footer_code : "about_us"
      }

      setUpdateCheckAbout(true)

      apis.post('/admin/add-footer-content', bodyData, config)
      .then((response) => {
        if(response.data.success === true){
          toast.success("About Us Updated Successfully.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
          fetchTabsData()
        }
        else{
          toast.error("Could not update About Us. Please try again later.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
          fetchTabsData()
        }
      })
      .catch((error) => {
        if(error.response.data.success ===false && error.response.data.message === 'jwt expired'){
          localStorage.removeItem("loginData")
          setTokenExpire(true)
        }
        else{
          toast.error("Could not update About Us. Please try again later.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
        }
      })

    }

    // FOR CONTACT US
    const handleContactUsChange = () => {
      setInitialContactUs(newContact.value)
      if(newContact.value === "" || !emailRegEx.test(newContact.value)){
        setContactUsError(true)
      }
      else{
        setContactUsError(false)
      }
    }

    const dataChangeContactUs = () => {
      if((tabsData && tabsData[3].footer_text !== initialContactUs) && contactUsError === false){
        setUpdateCheckContactUs(false)
      }
      else{
        setUpdateCheckContactUs(true)
      }
    }

    const handleContactUsEdit = () => {
      const config = {
        headers : {
          authorization : `${accessToken}`
        }
      }
      
      const bodyData = {
        footer_text : newContact.value,
        footer_code : "contact_us"
      }

      setUpdateCheckContactUs(true)

      apis.post('/admin/add-footer-content', bodyData, config)
      .then((response) => {
        if(response.data.success === true){
          toast.success("Contact Us Updated Successfully.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
          fetchTabsData()
        }
        else{
          toast.error("Could not update Contact Us. Please try again later.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
          fetchTabsData()
        }
      })
      .catch((error) => {
        if(error.response.data.success ===false && error.response.data.message === 'jwt expired'){
          localStorage.removeItem("loginData")
          setTokenExpire(true)
        }
        else{
          toast.error("Could not update About Us. Please try again later.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
        }
      })

    }

    useEffect(() => {
      if(!accessToken){
        history.push({
          pathname : "/",
          state : {
            url : '/legalsetting'
          }
        })  
        toast.error("Please login to continue.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER}) 
      }
      else if(accessToken){
        fetchTabsData()
      }
    }, [])

    useEffect(() => {
      dataChangePolicy()
    }, [initialPolicy])

    useEffect(() => {
      dataChangeTerms()
    }, [initialTerms])

    useEffect(() => {
      dataChangeAbout()
    }, [initialAbout])

    useEffect(() => {
      dataChangeContactUs()
    }, [initialContactUs])

    return(
        tokenExpire ?
        <Timeout></Timeout> 
        :
        <main className="page_wrapper">
        <section className="dashboard_section d-flex">
          <Sidebar />

          <section className="dashboard_main_section">
            <Header title="Legal Settings" screen_name = {screen_name} profilePic = {profilePic} accessToken = {accessToken}/>
            <section class="dasboard_main_area admin legalSetting">
           
              <Tabs defaultActiveKey="Policy" id="uncontrolled-tab-example" className="mb-3">
                <Tab eventKey="Policy" title="Policy">
                  <div className="tabs_pannel">
                    <div class=" tab-container ">
                        <div className="content-wrapper">
                          <textarea 
                          id = "legal-textarea"
                          value={initialPolicy}
                          ref = {(inputPolicy) => newPolicy = inputPolicy}
                          onChange = {() => handlePolicyChange()}
                          >
                          </textarea>
                          
                        </div>
                        {!policyError ? <></> : <p className="error-label">Policy can't be empty</p>}
                        <div className="submit-wrapper user-form-submit submit-main row mt-4">
                          <button className="btn-outline bg-white" onClick={() => history.goBack()}>Cancel</button>
                          <button className="btn btn-xl" disabled = {updateCheckPolicy} onClick = {() => handlePolicyEdit()}>Save</button>
                        </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="TermsandConditions" title="Terms and Conditions">
                  <div className="tabs_pannel">
                    <div class=" tab-container ">
                        <div className="content-wrapper">
                            <textarea id = "legal-textarea"
                            value={initialTerms}
                            ref = {(inputTerms) => newTerms = inputTerms}
                            onChange = {() => handleTermsChange()}
                            >
                            </textarea>
                        </div>
                        {!termsError ? <></> : <p className="error-label">Terms and Conditions can't be empty</p>}
                        <div className="submit-wrapper user-form-submit submit-main row mt-4">
                          <button className="btn-outline bg-white" onClick={() => history.goBack()}>Cancel</button>
                          <button className="btn btn-xl" disabled = {updateCheckTerms} onClick = {() => handleTermsEdit()}>Save</button>
                        </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="AboutUS" title="About Us">
                  <div className="tabs_pannel">
                    <div class=" tab-container ">
                        <div className="content-wrapper">
                          <textarea id = "legal-textarea"
                          value={initialAbout}
                          ref = {(inputAbout) => newAbout = inputAbout}
                          onChange = {() => handleAboutChange()}
                          >
                          </textarea>
                        </div>
                        {!aboutError ? <></> : <p className="error-label">About Us can't be empty</p>}
                        <div className="submit-wrapper user-form-submit submit-main row mt-4">
                          <button className="btn-outline bg-white" onClick={() => history.goBack()}>Cancel</button>
                          <button className="btn btn-xl" disabled = {updateCheckAbout} onClick = {() => handleAboutEdit()}>Save</button>
                        </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="ContactUS" title="Contact Us">
                  <div className="tabs_pannel">
                    <div class=" tab-container ">
                        <div className="legalForm">
                            <div>
                                <label>Email ID</label>
                            </div>
                            <div class="input-box">
                                    <Form.Group controlId="exampleForm.ControlInput1">
                                    <Form.Control 
                                    type="text" 
                                    placeholder="Enter E-Mail ID" 
                                    value = {initialContactUs}
                                    ref = {(contactinput) => newContact = contactinput}
                                    onChange = {() => handleContactUsChange()}/>
                                    {!contactUsError ? <></> : <p className="error-label">Please Enter Valid E-Mail.</p>}
                                    </Form.Group>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'> 
                                    <div className='buttonsWrapper'>
                                        <button class="btn btn-outline me-3">Cancel</button>
                                        <button class="btn btn-xl text-center h-53" disabled = {updateCheckContactUs} onClick = {() => handleContactUsEdit()}>Save Changes</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="SocialLinks" title="Social Links">
                  <div className="tabs_pannel">
                    <div class=" tab-container ">
                        <div className="legalForm">
                            <div>
                                <label>LinkedIn</label>
                            </div>
                            <div class="input-box">
                                    <Form.Group controlId="exampleForm.ControlInput1">
                                    <Form.Control type="text" placeholder="" />
                                    </Form.Group>
                            </div>
                            <div>
                                <label>Facebook</label>
                            </div>
                            <div class="input-box">
                                    <Form.Group controlId="exampleForm.ControlInput1">
                                    <Form.Control type="text" placeholder="" />
                                    </Form.Group>
                            </div>
                            <div>
                                <label>Twitter</label>
                            </div>
                            <div class="input-box">
                                    <Form.Group controlId="exampleForm.ControlInput1">
                                    <Form.Control type="text" placeholder="" />
                                    </Form.Group>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'> 
                                    <div className='buttonsWrapper'>
                                        <button class="btn btn-outline me-3">Cancel</button>
                                        <button class="btn btn-xl text-center h-53" >Save Changes</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </section>
          </section>
        </section>
      </main>
    )
}

export default LegalSetting