import Sidebar from "../../sidebar";
import Header from "../../header";
import Form from "react-bootstrap/Form";
import InputGroup from 'react-bootstrap/InputGroup';
import user from "../../../../assets/img/total-user-icon.svg";
import number from "../../../../assets/img/number-new-user-icon.svg";
import report from "../../../../assets/img/report-graph.svg";
import open from "../../../../assets/img/my-open-icon.svg";
import que from "../../../../assets/img/new-question.svg";
import apis from "../../../../utils/apis"
import {useState, useEffect} from 'react'
import Timeout from "../../timeout";
import { useHistory } from 'react-router-dom';
import { toast } from "react-toastify";
import BarGraph from "../../bargraph"

toast.configure()

function Report() {
  const history = useHistory()
  const loginData = JSON.parse(localStorage.getItem("loginData"))
  let accessToken, screen_name, profilePic
  if(loginData){
    accessToken = loginData.accessToken
    screen_name = loginData.screenName
    profilePic = loginData.profileImage
  }
  const [selectedReport, setSelectedReport] = useState("user")
  const [userReport, setUserReport] = useState("")
  const [userReportCard, setUserReportCard] = useState("")
  const [tokenExpire, setTokenExpire] = useState(false)
  const [page, setPage] = useState(1)
  const [rows, setRows] = useState(50)
  const [graphData, setGraphData] = useState({})
  const [questionReport, setQuestionReport] = useState("")
  let graphLabel = [], questionCount = []

  const handleItemCountChange = (e) => {
    setRows(e.target.value)
    setPage(1)
  }

  const handlePgIncrease = () => {
    if(page !== userReport.total_pages){
      let nextpg = page + 1
      setPage(page + 1)
      fetchUserReport(nextpg)
    }  
  }

  const handlePgDecrease = () => {
    if(page !== 1){
      let nextpg = page - 1
      setPage(page-1)
      fetchUserReport(nextpg)
    }
  }

  const fetchUserReportCard = () => {
    const config = {
      headers : {
        authorization : `${accessToken}`
      }
    }

    apis.get('admin/user-report', config)
    .then((response) => {
      if(response.data.success === true){
        setUserReportCard(response.data)
      }
      else{
        toast.error("Could not fetch User Report.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
      }
    })
    .catch((error) => {
      if(error.response.data.success ===false && error.response.data.message === 'jwt expired'){
        localStorage.removeItem("loginData")
        setTokenExpire(true)
      }
      else{
        toast.error("Could not fetch User Report.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
      }

    })

  }

  const fetchQuestionReport = () => {
    const config = {
      headers : {
        authorization : `${accessToken}`
      }
    }

    apis.get('/admin/question-report', config)
    .then((response)=> {
      if(response.data.success === true){

        for(let i = 0 ; i < response.data.graphData.length ; i++){
          graphLabel.push(response.data.graphData[i].month_year)
          questionCount.push(response.data.graphData[i].total_question)
        }

        graphLabel.reverse()
        questionCount.reverse()

        const state = {
          labels: graphLabel,
          datasets: [
            {
              label: 'Total Question',
              backgroundColor: 'rgba(75,192,192,1)',
              borderColor: 'rgba(0,0,0,1)',
              borderWidth: 2,
              data: questionCount
            }
          ]
        }
        setGraphData(state)
        setQuestionReport(response.data)

      }
      else{
        toast.error("Could not fetch Question Report.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
      }
    })
    .catch((error) => {
      if(error.response.data.success ===false && error.response.data.message === 'jwt expired'){
        localStorage.removeItem("loginData")
        setTokenExpire(true)
      }
      else{
        toast.error("Could not fetch Question Report.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
      }
    })
    
  }

  const fetchUserReport = (page) => {
    const config = {
      headers : {
        authorization : `${accessToken}`
      }
    }

    const bodyData = {
      page : page,
      numberOfItems : rows
    }

    apis.post('/admin/userlist', bodyData, config)
    .then((response) => {
      if(response.data.success === true){
        setUserReport(response.data)
      }
      else{
        toast.error("Could not fetch User Report.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
      }
    })
    .catch((error) => {
      if(error.response.data.success ===false && error.response.data.message === 'jwt expired'){
        localStorage.removeItem("loginData")
        setTokenExpire(true)
      }
      else{
        toast.error("Could not fetch User Report.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
      }
    })
  }

  const downloadUserReport = (type) => {
    
    let fileAttribute 
    if(type === "csv"){
      fileAttribute = "userreport.csv"
    }
    else{
      fileAttribute = "userreport.xlsx"
    }
    if(accessToken){
      console.log(accessToken)
      const bodyData = {
        format : type
      }
      const config = {
        headers: { 
          authorization: `${accessToken}`
        },
        responseType: 'arraybuffer'
      }
      apis.post('/admin/export-user-report',bodyData, config)
      .then((response)=> {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileAttribute);
        document.body.appendChild(link);
        link.click();
        toast.success("User Report Downloaded Successfully.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
      })
      .catch((error)=> {
        toast.error("Could not download User Report. Please try again later.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
      })
    }

  }

  const downloadQuestionReport = (type) => {
    let fileAttribute 
    if(type === "csv"){
      fileAttribute = "questionreport.csv"
    }
    else{
      fileAttribute = "questionreport.xlsx"
    }
    if(accessToken){
      console.log(accessToken)
      const bodyData = {
        format : type
      }
      const config = {
        headers: { 
          authorization: `${accessToken}`
        },
        responseType: 'arraybuffer'
      }
      apis.post('/admin/export-question-report',bodyData, config)
      .then((response)=> {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileAttribute);
        document.body.appendChild(link);
        link.click();
        toast.success("Question Report Downloaded Successfully.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
      })
      .catch((error)=> {
        toast.error("Could not download question report. Please try again later.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
      })
    }

  }
  const handleExportChange = (e) => {
    if(selectedReport === "user"){
      downloadUserReport(e.target.value)
    }
    else{
      downloadQuestionReport(e.target.value)
    }
  }

  useEffect(() => {
    if(!accessToken){
      history.push({
        pathname : "/",
        state : {
          url : '/report'
        }
       })
       toast.error("Please login to continue.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
    }
    else if(accessToken){
      fetchUserReport(page)
    }
  }, [rows])

  useEffect(() => {
    fetchQuestionReport()
    fetchUserReportCard()
  }, [])

  return (
    tokenExpire ? 
    <Timeout>
    </Timeout> :
    <>
      <main className="page_wrapper">
        <section className="dashboard_section d-flex">
          <Sidebar />
          <section className="dashboard_main_section">
            <Header title="Reports" screen_name = {screen_name} profilePic = {profilePic} accessToken = {accessToken}/>
            <section class="dasboard_main_area admin-dashboard reports-dashboard user-reports-section">
              <div class="report-dashboard-ctrls d-flex justify-content-between align-items-center">
                <div class="radio-btn-col">
                  <div class="points-available card-type-section d-flex flex-wrap">
                    <InputGroup>
                      <div className="mb-3 me-5 mx-3">
                        <Form.Check
                          defaultChecked = {true}
                          id="radio-1"
                          type= "radio"
                          name = "report"
                          label={`User Report`}
                          // checked={true}
                          value = {"user"}
                          onClick = {() => setSelectedReport("user")}
                        />
                      </div>
                      <div className="mb-3">
                        <Form.Check
                          id="radio-2"
                          type= "radio"
                          name = "report"
                          value={"question"}
                          label={`Question Report`}
                          onClick = {() => setSelectedReport("question")}
                        />
                      </div>
                    </InputGroup>
                    {/* <Form>
                      {[ "radio"].map((type) => (
                        <div key={`default-${type}`} className="mb-3 me-5 mx-3">
                          <Form.Check
                            type={type}
                            id={`default-${type}`}
                            label={`User Report `}
                          />
                        </div>
                      ))}
                    </Form>
                    <Form>
                      {[ "radio"].map((type) => (
                        <div key={``} className="mb-3">
                          <Form.Check
                            type={type}
                            id={`default-${type}`}
                            label={`Question Report `}
                          />
                        </div>
                      ))}
                    </Form> */}
                  </div>
                </div>
                <div class="btn-col">
                  {/* <Link to={'./graph'}
                    class="btn btn-xl export-btn"
                  >Export</Link> */}
                  <div className="select_container">
                      <div className="btn btn-xl export-btn">
                        <Form.Select
                          size="sm"
                          aria-label="Default select example"
                          value= ""
                          onChange = {(e) => handleExportChange(e)}
                          id = "report-export"
                        >
                          <option value="">Export </option>
                          <option value="csv">CSV</option>
                          <option value="excel">Excel</option>
                        </Form.Select>
                      </div>
                    </div>
                </div>
              </div>
              {selectedReport === "user" ? <>
              <div class="reports-container">
                <div class="dasboard_counter_main reports-main">
                  <div class="row counter-card-row">
                    <div class="col-lg-6  counter-cards">
                      <div class="inner-column">
                        <div class="counter-card-img card-inner-cols">
                        <img src={user} alt="" />
                        </div>
                        <div class="counter-card-content card-inner-cols">
                          <div class="card-head-main d-flex justify-content-between ">
                            <div class="counter-label">
                              <h4>Total Users</h4>
                            </div>
                          </div>
                          <div class="counter-val-main">
                            <p>{userReportCard.total_users}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6  counter-cards">
                      <div class="inner-column">
                        <div class="counter-card-img card-inner-cols">
                        <img src={number} alt="" />
                        </div>
                        <div class="counter-card-content card-inner-cols">
                          <div class="card-head-main d-flex justify-content-between ">
                            <div class="counter-label">
                              <h4>New Users</h4>
                            </div>
                            <div class="counter-filter text-end">
                              <p className='day-filter'>Last 3 days</p>
                            </div>
                          </div>
                          <div class="counter-val-main">
                            <p>{userReportCard.new_users}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="spam-history-section points-history-section">
                <div class="points-table-section banks-table-section select-banks-table">
                  <div class="points-table-container">
                    <div class="points-tb-wrapper table-responsive">
                      <table class="points-table">
                        <thead class="points-tb-header">
                          <tr>
                            <th>
                              <div class="input-box">
                                <input type="checkbox" name="" id="" />
                              </div>
                            </th>
                            <th>Name</th>
                            <th>Date Added</th>
                            <th>Type of User</th>
                            <th>
                            Last Login Time
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            userReport && userReport.data.length > 0 ? userReport.data.map((ele) => {
                              let loginTime = ele.loginTime
                              let badge_name = ele.badge_name
                              let createdAtdt = new Date(ele.createdAt)
                              let createdAtDateBreak = createdAtdt.toString().split(" ")
                              let createdAt = `${createdAtDateBreak[2]}-${createdAtDateBreak[1]}-${createdAtDateBreak[3]}`
                              
                              if(loginTime === null){
                                loginTime = "Not Logged in yet"
                              }
                              else if(loginTime != null){
                                let dt = new Date(loginTime)
                                let dateBreak = dt.toString().split(" ")
                                loginTime = `${dateBreak[2]}-${dateBreak[1]}-${dateBreak[3]} @ ${dateBreak[4]}`
                              }

                              if(badge_name === null){
                                badge_name = 'N/A'
                              }

                              return(
                                <tr key={ele.id}>
                                  <td>
                                    <div class="input-box">
                                      <input type="checkbox" name="" id="" />
                                    </div>
                                  </td>
                                  <td>{ele.full_name}</td>
                                  <td>{createdAt}</td>
                                  <td>{badge_name}</td>

                                  <td>
                                    {loginTime}
                                  </td>
                                </tr>
                              )


                            })
                          : <></>}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="table-pagination-wrapper">
                <div class="table-pagination-con d-flex align-items-center justify-content-lg-end justify-content-center">
                  <span class="pagination-label">Items per page:</span>
                  <select className="select-page-number" name="" id="" value={rows} onChange={(e) => handleItemCountChange(e)}>
                    <option value= {50}>50</option>
                    <option value= {100}>100</option>
                    <option value= {150}>150</option>
                    <option value= {200}>200</option>
                    <option value= {250}>250</option>
                    <option value= {300}>300</option>
                  </select>
                  <span className="pagination-label page-counter">{userReport.total_pages === 0 ? 0 : page} of {userReport.total_pages}</span>
                  <span className="pagination-controls">
                    <a className="prev" style={{cursor:'pointer'}} onClick={() => handlePgDecrease()}>
                      ‹
                    </a>
                    <a className="next" style={{cursor:'pointer'}} onClick={() => handlePgIncrease()}>
                      ›
                    </a>
                  </span>
                </div>
              </div></> : <><div class="">
                <div class="dasboard_counter_main reports-main">
                  <div class="row counter-card-row">
                    <div class="col-lg-4  counter-cards">
                      <div class="inner-column">
                        <div class="counter-card-img card-inner-cols">
                        <img src={user} alt="" />
                        </div>
                        <div class="counter-card-content card-inner-cols">
                          <div class="card-head-main d-flex justify-content-between ">
                            <div class="counter-label">
                              <h4>Open Question</h4>
                            </div>
                            <div class="counter-filter text-end">
                              <p className='day-filter'>Last 30 days</p>
                            </div>
                          </div>
                          <div class="counter-val-main">
                            <p>{questionReport.open_question_count}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4  counter-cards">
                      <div class="inner-column">
                        <div class="counter-card-img card-inner-cols">
                        <img src={open} alt="" />
                        </div>
                        <div class="counter-card-content card-inner-cols">
                          <div class="card-head-main d-flex justify-content-between ">
                            <div class="counter-label">
                              <h4>New Question</h4>
                            </div>
                            <div class="counter-filter text-end">
                              <p className='day-filter'>Last 3 days</p>
                            </div>
                            {/* <div class="counter-filter text-end">
                              <select name="" id="">
                                <option value="">Last 3 days</option>
                              </select>
                            </div> */}
                          </div>
                          <div class="counter-val-main">
                            <p>{questionReport.new_question_count}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4  counter-cards">
                      <div class="inner-column">
                        <div class="counter-card-img card-inner-cols">
                        <img src={que} alt="" />
                        </div>
                        <div class="counter-card-content card-inner-cols">
                          <div class="card-head-main d-flex justify-content-between ">
                            <div class="counter-label">
                              <h4>Closed Question</h4>
                            </div>
                            <div class="counter-filter text-end">
                              <p className='day-filter'>Last 3 days</p>
                            </div>
                            {/* <div class="counter-filter text-end">
                              <select name="" id="">
                                <option value="">Last 3 days</option>
                              </select>
                            </div> */}
                          </div>
                          <div class="counter-val-main">
                            <p>{questionReport.close_question_count}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="report-graph-area">
                  <div class="report-graph-container">
                    {/* <img class="img-fluid w-100" src={report} alt=""/> */}
                    <BarGraph graphData = {graphData}></BarGraph>
                  </div>
                </div>
              </div></>}
            </section>
          </section>
        </section>
      </main>
    </>
  );
}
export default Report;
