import React, { Component } from 'react';
// import Header from './header';
import Router from '../../router/router';
import '../../assets/css/style.css';
import '../../assets/css/admin.css';


export default class Layout extends Component {
  render() {
    return(
      <div className="instructions">
        {/* <Header/> */}
        <Router/>
      </div>
    )
  }
}