import React from "react";
import { Switch, Route } from "react-router-dom";
import Dashboard from "../components/layout/pages/dashboard";
import Sidebar from "../components/layout/sidebar";
import Header from "../components/layout/header";
import Login from "../components/layout/pages/login/login";
import Forgot from "../components/layout/pages/login/forgot";
import Reset from "../components/layout/pages/login/reset-password";
import Question from "../components/layout/pages/Question/question";
import FAQ from "../components/layout/pages/FAQ/faq";
import Account from "../components/layout/pages/Account/account";
import LegalSetting from "../components/layout/pages/LegalSetting/LegalSetting";
import Payment from "../components/layout/pages/Payment/payment";
import Badge from "../components/layout/pages/Badge/badge";
import Report from "../components/layout/pages/Report/report";
import Point from "../components/layout/pages/Point/point";
import User from "../components/layout/pages/user/user";
import Userprofile from "../components/layout/pages/user/users-profile";
import Transfer from "../components/layout/pages/Payment/transfer";
import Spam from "../components/layout/pages/Badge/spam";
import Graph from "../components/layout/pages/Report/graph";
import VerifyEmail from "../components/layout/pages/verifyEmail";
import ViewPoint from '../components/layout/pages/user/PointView';
import PointCard from '../components/layout/pages/user/PointCard';
import ViewSpam from '../components/layout/pages/user/SpamView';
import QuestionView from '../components/layout/pages/Question/QuestionView';
import FaqView from '../components/layout/pages/FAQ/FaqView';
import PaymentView from '../components/layout/pages/Payment/PaymentView';
import PointHistory from '../components/layout/pages/Point/PointHistory';
import PaymentSuccess from "../components/layout/pages/Payment/PaymentSuccess";
import PaymentFailed from "../components/layout/pages/Payment/PaymentFailed";
import NotificationsList from "../components/layout/pages/NotificationList";


const Router = () => (
  <main>
    <Switch>
      <Route exact path='/sidebar' component={Sidebar} />
      <Route exact path='/header' component={Header} />
      <Route exact path='/' component={Login} />
      <Route exact path='/forgot' component={Forgot} />
      <Route exact path='/reset-password/:id/:token' component={Reset} />
      <Route exact path='/dashboard' component={Dashboard} />
      <Route exact path='/user-management' component={User} />
      <Route exact path='/user-management/view-edit/:user_id/:activeTab' component={Userprofile} />
      <Route exact path='/question' component={Question} />
      <Route exact path='/point' component={Point} />
      <Route exact path='/point/point-history' component={PointHistory} />
      <Route exact path='/faq' component={FAQ} />
      <Route exact path='/account' component={Account} />
      <Route exact path='/legalsetting' component={LegalSetting} />
      <Route exact path='/payment' component={Payment} />
      <Route exact path='/transfer' component={Transfer} />
      <Route exact path='/badge' component={Badge} />
      <Route exact path='/spam' component={Spam} />
      <Route exact path='/report' component={Report} />
      <Route exact path='/graph' component={Graph} />
      <Route exact path='/verify-email/:id/:token' component={VerifyEmail} />
      <Route exact path='/user-management/view-point/:question_id' component={ViewPoint} />
      <Route exact path='/user-management/view-point-card' component={PointCard} />
      <Route exact path='/user-management/view-spam/:response_id' component={ViewSpam} />
      <Route exact path='/question/view-question/:question_id' component={QuestionView} />
      <Route exact path='/faq/view-faq/:faq_id' component={FaqView} />
      <Route exact path='/payment/view-payment/:paymentId' component={PaymentView} />
      <Route exact path='/payment-successful/:user_id/:amount/:points' component={PaymentSuccess} />
      <Route exact path='/payment-cancelled/:user_id' component={PaymentFailed} />
      <Route exact path='/notification-list' component = {NotificationsList}></Route>
    </Switch>
  </main>
);

export default Router;
