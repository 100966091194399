import React from 'react'
import Header from '../../header';
import Sidebar from "../../sidebar";
import Arrow from '../../../../assets/img/arrowPng.png'
import { useHistory } from 'react-router-dom';
import {useState, useEffect} from 'react'
import { useParams } from "react-router-dom";
import apis from '../../../../utils/apis'
import Timeout from '../../timeout';
import { toast } from "react-toastify";

toast.configure()


const PointView = () =>{
    const history = useHistory()
    const {question_id} = useParams()
    const loginData = JSON.parse(localStorage.getItem("loginData"))
    let accessToken, screen_name, profilePic
    if(loginData){
      accessToken = loginData.accessToken
      screen_name = loginData.screenName
      profilePic = loginData.profileImage
    }

    const [questionData, setQuestionData] = useState("")
    const [tokenExpire, setTokenExpire] = useState(false)

    const handleGoBack = () => {
        history.goBack()
    }

    const fetchPointQuestionData = () => {
        const config = {
            headers : {
                authorization : `${accessToken}`
            }
        }

        apis.get(`/admin/gained-spend-point-details/${question_id}`, config)
        .then((response) => {
            if(response.data.success === true){
                setQuestionData(response.data.data)
            }
            else{
                toast.error("Could not view details. Please try again later", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
            }
        })
        .catch((error)=>{
            if(error.response.data.success ===false && error.response.data.message === 'jwt expired'){
                localStorage.removeItem("loginData")
                setTokenExpire(true)
              }
              else{
                toast.error("Could not view details. Please try again later", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
              }
        })

    }

    useEffect(() => {
        if(!accessToken){
            history.push({
                pathname : "/",
                state : {
                url : '/user-management'
                }
            })
            toast.error("Please login to continue.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 1})
        }
        else if(accessToken){
            fetchPointQuestionData()
        }
        
    },[])

    return(
        tokenExpire ?
        <Timeout>
        </Timeout>
        :
        <main className="page_wrapper">
           <section className="dashboard_section d-flex">
               <Sidebar />
               <section className="dashboard_main_section">
                     <Header title="View Point Details" screen_name = {screen_name} profilePic = {profilePic} accessToken = {accessToken}/>
                        <section className="dasboard_main_area">
                        <div className='pointViewWrapper'>
                        <div class="heading" style={{cursor:'pointer'}} onClick={() => handleGoBack()}><h3 class="txt-3"> <img src={Arrow} alt="img" /> BACK </h3></div>  
                            <div className='row'>
                                <div className='col-md-2'>
                                        <label>question</label>
                                </div>
                                <div className='col-md-10'>
                                    <div className='pv-question'>
                                        <p>
                                            {questionData && questionData.question_text}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-2'>
                                        <label>Response</label>
                                </div>
                                <div className='col-md-10'>
                                    <div>
                                        <p>
                                            {questionData.response_text}                        
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-2'>
                                        <label>Asked By User</label>
                                </div>
                                <div className='col-md-10'>
                                    <div>
                                        <p>
                                        {questionData.screen_name}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-2'>
                                    <label>Status</label>
                                </div>
                                <div className='col-md-10'>
                                    <div>
                                        <p>
                                            {questionData && questionData.question_status === "open" ? "Open" : "Closed"}             
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </section>
                </section>
            </section>
        </main>
    )
}

export default PointView