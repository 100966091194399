import Sidebar from "../../sidebar";
import Header from "../../header";
import { useState, useEffect } from "react";
import apis from '../../../../utils/apis'
import { useHistory } from 'react-router-dom';
import { toast } from "react-toastify";
import Timeout from "../../timeout";

toast.configure()

function PointHistory() {
  const loginData = JSON.parse(localStorage.getItem("loginData"))
  let accessToken, screen_name, profilePic, adminId
  if(loginData){
    accessToken = loginData.accessToken
    screen_name = loginData.screenName
    profilePic = loginData.profileImage
    adminId = loginData.adminId
  }
  const history = useHistory()
  const [tokenExpire, setTokenExpire] = useState(false)
  const [pointsPage, setPointsPage] = useState(1)
  const [pointsRows, setPointsRows] = useState(50)
  const [pointsHistoryResult, setPointsHistoryResult] = useState("")

  const handlePointPageIncrease = () => {
    if(pointsPage !== pointsHistoryResult.page_count){
      let page = pointsPage + 1
      setPointsPage(pointsPage + 1)
      fetchPointsData(page)
    }
  }

  const handlePointPageDecrease = () => {
    if(pointsPage !== 1){
      let page = pointsPage - 1
      setPointsPage(pointsPage - 1)
      fetchPointsData(page)
    }
  }

  const handlePointsRowsChange = (e) => {
    setPointsRows(e.target.value)
    setPointsPage(1)
  }

  const fetchPointsData = (page) => {
    const config = {
      headers : {
        authorization : `${accessToken}`
      },
      params : {
        user_id : adminId,
        page : page,
        size : pointsRows,
        type : "gained"
      }
    }

    apis.get('/admin/gained-spend-point-list/', config)
    .then((response)=> {
      if(response.data.success === true){
        setPointsHistoryResult(response.data)
        console.log(response.data)
      }
      else{
        toast.error("Could not fetch Points History.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 18})
      }
      
    })
    .catch((error)=> {
      if(error.response.data.success ===false && error.response.data.message === 'jwt expired'){
        localStorage.removeItem("loginData")
        setTokenExpire(true)
      }
      else{
        toast.error("Could not fetch Points History.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 18})
      }
    })
  }

  useEffect(() => {
    if(!accessToken){
      history.push({
        pathname : "/",
        state : {
          url : '/user-management'
        }
       })
       toast.error("Please login to continue.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER, toastId : 18})
    }
    else if(accessToken){
      fetchPointsData(pointsPage)
    }
  }, [pointsRows])
  return (
    tokenExpire ?
    <Timeout>
    </Timeout>
    :
    <>
      <main className="page_wrapper">
        <section className="dashboard_section d-flex">
          <Sidebar />

          <section className="dashboard_main_section">
            <Header title="Admin Points History" screen_name = {screen_name} profilePic = {profilePic} accessToken = {accessToken}/>
            <section className="dasboard_main_area list-experts-area">
              <div className="points-table-section">
                <div className="points-table-container">
                  <div className="points-tb-wrapper">
                    <table className="points-table">
                      <thead className="points-tb-header">
                        {pointsHistoryResult && pointsHistoryResult.data.length > 0 ?
                          <tr>
                            <th>Question</th>
                            <th>Date & Time</th>
                            <th>Points</th>
                            <th><div>Username</div></th>
                          </tr> : <tr><th></th></tr>
                        }             
                      </thead>
                      <tbody>
                        {pointsHistoryResult && pointsHistoryResult.data.length > 0 ? pointsHistoryResult.data.map((ele)=> {
                          let createdAtdt = new Date(ele.createdAt)
                          let createdAtDateBreak = createdAtdt.toString().split(" ")
                          let createdAt = `${createdAtDateBreak[2]}-${createdAtDateBreak[1]}-${createdAtDateBreak[3]} @ ${createdAtDateBreak[4]}`
                          return(
                            <tr key={ele.id}>
                              <td id="points-history-admin-question">{ele.question_text}</td>
                              <td id="points-history-admin-date">{createdAt}</td>
                              <td id="points-history-admin-point">{ele.point} {ele.point_name}</td>
                              <td>  
                                {ele.question_owner_username}
                              </td>
                            </tr>
                          )
                        }) : <tr>
                          <td>No Data To Show.</td>
                          </tr>}  
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="table-pagination-wrapper">
                <div className="table-pagination-con d-flex align-items-center justify-content-lg-end justify-content-center">
                  <span className="pagination-label">Items per page:</span>
                  <select className="select-page-number" name="" id="" onChange={(e) => handlePointsRowsChange(e)}>
                    <option value= {50} selected>50</option>
                    <option value= {100}>100</option>
                    <option value= {150}>150</option>
                  </select>
                  <span className="pagination-label page-counter">{pointsHistoryResult.page_count === 0 ? 0 : pointsPage} of {pointsHistoryResult.page_count}</span>
                  <span className="pagination-controls">
                    <a className="prev" style={{cursor:'pointer'}} onClick={() => handlePointPageDecrease()}>
                      ‹
                    </a>
                    <a className="next" style={{cursor:'pointer'}} onClick={() => handlePointPageIncrease()}>
                      ›
                    </a>
                  </span>
                </div>
              </div>
            </section>
          </section>
        </section>
      </main>
    </>
  );
}
export default PointHistory;
